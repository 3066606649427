define("ember-cli-ss-enums/utils/enum-data/ballot-layouts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Narrow Scroll',
    value: '1',
    src: '/Images/ballot_narrow_scroll.svg'
  }, {
    id: 2,
    name: 'Wide Scroll',
    value: '2',
    src: '/Images/ballot_wide_scroll.svg'
  }, {
    id: 3,
    name: 'Category Drawers',
    value: '3',
    src: '/Images/ballot_category_drawers.svg'
  }, {
    id: 4,
    name: 'Category Pages',
    value: '4',
    src: '/Images/ballot_category_pages.svg'
  }];
  _exports.default = _default;
});