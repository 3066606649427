define("ember-material-components-web/components/mdc-layout-grid", ["exports", "@ember/object/computed", "@ember/component", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-layout-grid"], function (_exports, _computed, _component, _globalEventHandlers, _mdcLayoutGrid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Attributes
    /**
     * @type {Boolean}
     */
    'fixed-column-width': false,
    /**
     * Must be `left` or `right` if used
     * @type {String}
     */
    align: null,
    //endregion

    //region Ember Hooks
    classNames: Object.freeze(['mdc-layout-grid']),
    classNameBindings: Object.freeze(['fixed-column-width:mdc-layout-grid--fixed-column-width', 'isAlignLeft:mdc-layout-grid--align-left', 'isAlignRight:mdc-layout-grid--align-right']),
    attributeBindings: Object.freeze([..._globalEventHandlers.default]),
    layout: _mdcLayoutGrid.default,
    //endregion

    //region Computed Properties
    isAlignLeft: (0, _computed.equal)('align', 'left'),
    isAlignRight: (0, _computed.equal)('align', 'right')
    //endregion
  });
  _exports.default = _default;
});