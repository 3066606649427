define("ember-material-components-web/components/mdc-drawer/content", ["exports", "@ember/component", "ember-material-components-web/templates/components/mdc-drawer/content"], function (_exports, _component, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region attributes / bindings
    temporary: false,
    permanent: false,
    persistent: false,
    //endregion
    //region Ember Hooks
    layout: _content.default,
    classNameBindings: Object.freeze(['temporary:mdc-temporary-drawer__content', 'permanent:mdc-permanent-drawer__content', 'persistent:mdc-persistent-drawer__content'])
    //endregion
  });
  _exports.default = _default;
});