define("ember-cli-ss-enums/utils/enum-data/token-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This isn't actually an enum table on the back-end. Instead, Tokens have a Category string, which we use as a
  // reference to the other information here.
  var _default = [{
    category: 'From & Subject',
    icon: 'tasks'
  }, {
    category: 'Colors',
    icon: 'palette'
  }, {
    category: 'Header & Body',
    icon: 'list-alt'
  }, {
    category: 'Items',
    icon: 'picture'
  }, {
    category: 'Menu',
    icon: 'align-justify'
  }, {
    category: 'Social & Footer',
    icon: 'share-this'
  }, {
    category: 'Aesthetics',
    icon: 'palette'
  }, {
    category: 'Text',
    icon: 'align-left'
  }, {
    category: 'Sharing',
    icon: 'share-this'
  }, {
    category: 'Thank You Message',
    icon: 'heart'
  }, {
    category: 'Gallery Layout',
    icon: 'palette'
  }];
  _exports.default = _default;
});