define("ember-material-components-web/utils/style-computed", ["exports", "@ember/template", "@ember/object"], function (_exports, _template, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = prop => (0, _object.computed)(`${prop}`, function () {
    const obj = (0, _object.get)(this, prop);
    return (0, _template.htmlSafe)(Object.keys(obj).reduce((acc, key) => {
      if (key !== '@each') {
        // avoid deprecation
        return `${acc} ${key}: ${(0, _object.get)(this, `${prop}.${key}`)};`;
      }
      return acc;
    }, ''));
  });
  _exports.default = _default;
});