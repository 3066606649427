define("ember-material-components-web/components/mdc-list", ["exports", "@ember/component", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-list"], function (_exports, _component, _globalEventHandlers, _mdcList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Attribute
    /**
     * Make the list more visually compact
     * @type {Boolean}
     */
    dense: false,
    /*
     * Make the list confrom to the two-line list spec
     * @type {Boolean}
     * @see {@link https://material.io/guidelines/components/lists.html#lists-actions}
     */
    'two-line': false,
    /**
     * Styles the detail elements as what the spec calls "avatars" -- large, circular details
     * @type {Boolean}
     */
    'avatar-list': false,
    //endregion

    //region Ember Hooks
    layout: _mdcList.default,
    tagName: 'ul',
    classNameBindings: Object.freeze(['dense:mdc-list--dense', 'two-line:mdc-list--two-line', 'avatar-list:mdc-list--avatar-list']),
    classNames: Object.freeze(['mdc-list']),
    attributeBindings: Object.freeze([..._globalEventHandlers.default])
    //endregion
  });
  _exports.default = _default;
});