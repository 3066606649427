define("ember-cli-ss-enums/utils/enum-data/body-source-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Template',
    slug: 'template'
  }, {
    id: 2,
    name: 'Scrape Url',
    slug: 'scrape'
  }, {
    id: 3,
    name: 'Custom',
    slug: 'html'
  }];
  _exports.default = _default;
});