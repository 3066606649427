define("ember-material-components-web/templates/components/mdc-drawer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isPermanent}}
    {{yield (hash
              toolbar-spacer=(component "mdc-drawer/toolbar-spacer" permanent=true temporary=false persistent=false)
              content=(component "mdc-drawer/content" permanent=true temporary=false persistent=false)
            )}}
  
  {{else}}
    <div class="mdc-{{if this.temporary "temporary" "persistent"}}-drawer__drawer">
      {{yield (hash
                toolbar-spacer=(component "mdc-drawer/toolbar-spacer" permanent=false temporary=this.temporary persistent=this.persistent)
                content=(component "mdc-drawer/content" permanent=false temporary=this.temporary persistent=this.persistent)
              )}}
    </div>
  {{/if}}
  */
  {
    "id": "+1yL9Sv1",
    "block": "[[[41,[30,0,[\"isPermanent\"]],[[[1,\"  \"],[18,1,[[28,[37,2],null,[[\"toolbar-spacer\",\"content\"],[[50,\"mdc-drawer/toolbar-spacer\",0,null,[[\"permanent\",\"temporary\",\"persistent\"],[true,false,false]]],[50,\"mdc-drawer/content\",0,null,[[\"permanent\",\"temporary\",\"persistent\"],[true,false,false]]]]]]]],[1,\"\\n\\n\"]],[]],[[[1,\"  \"],[10,0],[15,0,[29,[\"mdc-\",[52,[30,0,[\"temporary\"]],\"temporary\",\"persistent\"],\"-drawer__drawer\"]]],[12],[1,\"\\n    \"],[18,1,[[28,[37,2],null,[[\"toolbar-spacer\",\"content\"],[[50,\"mdc-drawer/toolbar-spacer\",0,null,[[\"permanent\",\"temporary\",\"persistent\"],[false,[30,0,[\"temporary\"]],[30,0,[\"persistent\"]]]]],[50,\"mdc-drawer/content\",0,null,[[\"permanent\",\"temporary\",\"persistent\"],[false,[30,0,[\"temporary\"]],[30,0,[\"persistent\"]]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-drawer.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});