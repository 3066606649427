define("ember-material-components-web/components/mdc-elevation", ["exports", "@ember/component", "@ember/object", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-elevation"], function (_exports, _component, _object, _globalEventHandlers, _mdcElevation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Attributes
    /*
     * A number between 0 and 24
     * @type {Number}
     */
    z: 0,
    //endregion

    //region Ember Hooks
    layout: _mdcElevation.default,
    classNameBindings: Object.freeze(['elevationClass']),
    classNames: Object.freeze(['mdc-elevation-transition']),
    attributeBindings: Object.freeze([..._globalEventHandlers.default]),
    //endregion

    //region Computed Properties
    elevationClass: (0, _object.computed)('z', function () {
      return `mdc-elevation--z${(0, _object.get)(this, 'z')}`;
    })
    //endregion
  });
  _exports.default = _default;
});