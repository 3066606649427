define("ember-material-components-web/templates/components/mdc-card/actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    action=(component "mdc-button" class="mdc-card__action")
  )}}
  
  */
  {
    "id": "RsfMFNbq",
    "block": "[[[18,1,[[28,[37,1],null,[[\"action\"],[[50,\"mdc-button\",0,null,[[\"class\"],[\"mdc-card__action\"]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-card/actions.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});