define("ember-material-components-web/components/mdc-card/media", ["exports", "@ember/component", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-card/media"], function (_exports, _component, _globalEventHandlers, _media) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Ember Hooks
    layout: _media.default,
    classNames: Object.freeze(['mdc-card__media']),
    attributeBindings: Object.freeze([..._globalEventHandlers.default])
    //endregion
  });
  _exports.default = _default;
});