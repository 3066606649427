define("@ss/helpers/helpers/set", ["exports", "@ember/component/helper", "@ember/debug", "@ember/object"], function (_exports, _helper, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function set(positional) {
    const [target, path, maybeValue] = positional;
    (false && !(typeof path === 'string' && path.length > 0 || typeof path === 'symbol' || typeof path === 'number') && (0, _debug.assert)('you must pass a path to {{set}}. You can pass a path statically, as in `{{set this "foo"}}`, or with the path dynamically, as in `{{set this this.greetingPath "Hello"}}`', typeof path === 'string' && path.length > 0 || typeof path === 'symbol' || typeof path === 'number'));
    return positional.length == 3 ? () => {
      if (target && target.isDestroyed) return;
      (0, _object.set)(target, path, maybeValue);
    } : value => {
      if (target && target.isDestroyed) return;
      let nextValue = value;
      if (value instanceof Event && value.target) {
        nextValue = value.target.type == 'number' && typeof value.target.valueAsNumber != 'undefined' ? value.target.valueAsNumber : value.target.value;
      }
      (0, _object.set)(target, path, nextValue);
    };
  }
  var _default = (0, _helper.helper)(set);
  _exports.default = _default;
});