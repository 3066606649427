define("ember-material-components-web/mixins/mdc-component", ["exports", "@ember/string", "@ember/runloop", "@ember/array", "@ember/object/mixin", "@ember/object", "@material/ripple", "ember-material-components-web/utils/get-element-property", "ember-material-components-web/utils/mdc-ripple-adapter", "ember-material-components-web/utils/style-computed"], function (_exports, _string, _runloop, _array, _mixin, _object, _ripple, _getElementProperty, _mdcRippleAdapter, _styleComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeClass = _exports.default = _exports.addClass = _exports.MDCComponent = void 0;
  /* eslint-disable ember/no-new-mixins */

  const addClass = (className, component) => {
    (0, _object.get)(component, 'mdcClasses').addObject(className);
  };
  _exports.addClass = addClass;
  const removeClass = (className, component) => {
    (0, _object.get)(component, 'mdcClasses').removeObject(className);
  };

  /**
   * @typedef {Ember.Mixin} MDCComponent
   */
  _exports.removeClass = removeClass;
  const MDCComponent = _mixin.default.create({
    //region Ember Hooks
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'mdcClasses', (0, _array.A)([]));
      (0, _object.set)(this, 'mdcInteractionHandlers', (0, _array.A)([]));
      (0, _object.set)(this, 'mdcStyles', {});
    },
    didInsertElement() {
      this._super(...arguments);
      // We don't want to init the foundation until the next run loop, because
      // many components rely on child components registering themselves, which
      // tend to happen in their own didInsertElement hooks that run _after_ the
      // parent's didInsertElement.
      (0, _runloop.scheduleOnce)('afterRender', this, this._initFoundation);
    },
    willDestroyElement() {
      this._super(...arguments);
      const foundation = (0, _object.get)(this, 'foundation');
      const rippleFoundation = (0, _object.get)(this, 'rippleFoundation');
      if (foundation) {
        foundation.destroy();
      }
      if (rippleFoundation) {
        rippleFoundation.destroy();
      }
    },
    //endregion

    //region Properties
    /**
     * This only works for components that are _not_ tagless, and requires that
     * `mdcClassNames` is in `classNameBindings` and `style` is in `attributeBindings`.
     * @type {Boolean}
     */
    ripple: false,
    /**
     * @type {Function}
     * @returns {Object}
     */
    rippleOptions: () => ({}),
    /**
     * @type {MDCFoundation}
     */
    foundation: null,
    /**
     * @type {String[]}
     */
    mdcClasses: null,
    /**
     * @type {Array<String,Function>[]}
     */
    mdcInteractionHandlers: null,
    /**
     * Key value pairs for CSS styles
     * @type {Object}
     */
    mdcStyles: null,
    //endregion

    //region Computed Properties
    /**
     * If the MDC Component is _not_ tagless, it should have this in its `classNameBindings`.
     * @returns {String}
     */
    mdcClassNames: (0, _object.computed)('mdcClasses.[]', function () {
      return (0, _object.get)(this, 'mdcClasses').join(' ');
    }),
    /**
     * If the MDC Component is _not_ tagless, it should have this in its `attributeBindings`.
     * @returns {String}
     */
    style: (0, _styleComputed.default)('mdcStyles'),
    //endregion

    //region Methods
    /**
     * Use if you want to take actions that must be done after the foundation has been created.
     * @param {Object}
     */
    afterFoundationCreation() {},
    /**
     * Syncs the Ember Component properties with the MDC Foundation properties
     * Assumes that if there is not an isProp method on the foundation then there must be a setProp method.
     * @param {String} prop - A property name that exists on the Foundation
     *                        (as prop and setProp) and on the component
     */
    sync(prop) {
      const foundation = (0, _object.get)(this, 'foundation');
      if (!foundation) {
        return;
      }
      const value = (0, _object.get)(this, prop);
      const Prop = (0, _string.capitalize)(prop);
      if (!foundation[`is${Prop}`] || foundation[`is${Prop}`]() !== value) {
        foundation[`set${Prop}`](value);
      }
    },
    setStyleFor(key, property, value) {
      (0, _runloop.next)(() => {
        if ((0, _object.get)(this, 'isDestroyed')) {
          return;
        }
        (0, _object.set)(this, `${key}.${property}`, value);
        // Setting properties on the object doesn't cause computed properties to recompute
        // (and we can't put every possible CSS property in the dependent keys),
        // so we'll just trigger the change notification manually.
        this.notifyPropertyChange(key);
      });
    },
    _attachMdcInteractionHandlers() {
      (0, _object.get)(this, 'mdcInteractionHandlers').forEach(_ref => {
        let [type, handler] = _ref;
        return (0, _getElementProperty.default)(this, 'addEventListener', () => null)(type, handler);
      });
    },
    _detachMdcInteractionHandlers() {
      (0, _object.get)(this, 'mdcInteractionHandlers').forEach(_ref2 => {
        let [type, handler] = _ref2;
        return (0, _getElementProperty.default)(this, 'removeEventListener', () => null)(type, handler);
      });
    },
    registerMdcInteractionHandler(type, handler) {
      (0, _runloop.next)(() => {
        this._detachMdcInteractionHandlers();
        (0, _object.get)(this, 'mdcInteractionHandlers').addObject([type, handler]);
        this._attachMdcInteractionHandlers();
      });
    },
    deregisterMdcInteractionHandler(type, handler) {
      (0, _runloop.next)(() => {
        this._detachMdcInteractionHandlers();
        (0, _object.get)(this, 'mdcInteractionHandlers').removeObject([type, handler]);
        this._attachMdcInteractionHandlers();
      });
    },
    _initFoundation() {
      this._attachMdcInteractionHandlers();
      if ((0, _object.get)(this, 'createFoundation') && !(0, _object.get)(this, 'isDestroyed')) {
        const foundation = this.createFoundation();
        (0, _object.set)(this, 'foundation', foundation);
        foundation.init();
        this.afterFoundationCreation(foundation);
      }
      if ((0, _object.get)(this, 'ripple')) {
        const rippleFoundation = new _ripple.MDCRippleFoundation((0, _mdcRippleAdapter.createRippleAdapter)(this, this.rippleOptions()));
        (0, _object.set)(this, 'rippleFoundation', rippleFoundation);
        rippleFoundation.init();
      }
    }
    //endregion
  });
  _exports.MDCComponent = MDCComponent;
  var _default = MDCComponent;
  _exports.default = _default;
});