define("ember-cli-ss-enums/utils/enum-data/checklist-step-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Dates and Winners',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.dates-prizes'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.dates-prizes'
    },
    iconName: {
      votingBallot: 'date_range',
      nominationAndVotingBallot: 'date_range',
      votingBracket: 'date_range',
      eventSignup: 'date_range',
      survey: 'date_range',
      quizPersonality: 'date_range',
      quizTrivia: 'date_range',
      pollStandard: 'date_range'
    },
    iconClass: {
      default: 'ssIcon-pencil',
      sweepstakesSimple: 'ssIcon-ss-sweeps',
      sweepstakesCodeword: 'ssIcon-ss-codeword-sweeps',
      uGCSweepstakesStandard: 'ssIcon-ss-photo-sweeps',
      photoVotingStandard: 'ssIcon-ss-ugc-voting',
      photoGallery: 'ssIcon-ss-community-gallery',
      uGCSweepstakesVideo: 'ssIcon-video-sweeps',
      videoVotingStandard: 'ssIcon-video-voting',
      votingBallot: 'ssIcon-trophy',
      nominationAndVotingBallot: 'ssIcon-trophy'
    },
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.forms.index',
      uGCSweepstakes: 'organizations.organization.organization-promotions.organization-promotion.setup.entries',
      uGCGallery: 'organizations.organization.organization-promotions.organization-promotion.setup.sweepstakes',
      uGCVoting: 'organizations.organization.organization-promotions.organization-promotion.setup.sweepstakes',
      ballot: 'organizations.organization.organization-promotions.organization-promotion.setup.ads',
      votingBracket: 'organizations.organization.organization-promotions.organization-promotion.setup.ads-bracket',
      quiz: 'organizations.organization.organization-promotions.organization-promotion.setup.quiz',
      poll: 'organizations.organization.organization-promotions.organization-promotion.setup.quiz.questions-new'
    },
    displayOrder: 3
  }, {
    id: 2,
    name: 'Entry Form',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.forms', 'organizations.organization.organization-promotions.organization-promotion.setup.forms.form'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.forms'
    },
    iconName: {
      sweepstakesSimple: 'assignment',
      sweepstakesCodeword: 'assignment',
      uGCSweepstakesStandard: 'assignment',
      uGCSweepstakesVideo: 'assignment',
      photoVotingStandard: 'assignment',
      videoVotingStandard: 'assignment',
      quizPersonality: 'assignment',
      quizTrivia: 'assignment',
      votingBallot: 'assignment',
      nominationAndVotingBallot: 'assignment',
      photoGallery: 'assignment',
      pollStandard: 'assignment',
      votingBracket: 'assignment',
      eventSignup: 'assignment',
      paymentForm: 'assignment'
    },
    iconClass: {
      default: 'ssIcon-align-justify',
      survey: 'ssIcon-survey'
    },
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing',
      quiz: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.quiz',
      sweepstakes: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.sweepstakes',
      survey: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.survey',
      uGCSweepstakes: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.ugc-sweepstakes',
      uGCVoting: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.ugc-voting',
      uGCGallery: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.ugc-gallery'
    },
    displayOrder: 8
  }, {
    id: 3,
    name: 'Thanks and Sharing',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing', 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.quiz', 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.sweepstakes', 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.survey', 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.ugc-voting', 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.ugc-gallery', 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.ugc-sweepstakes'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.index',
      quiz: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.quiz',
      sweepstakes: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.sweepstakes',
      survey: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.survey',
      uGCVoting: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.ugc-voting',
      uGCGallery: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.ugc-gallery',
      uGCSweepstakes: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing.ugc-sweepstakes'
    },
    iconClass: 'ssIcon-rocket',
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.design'
    }
  }, {
    id: 4,
    name: 'Design',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.design', 'organizations.organization.organization-promotions.organization-promotion.setup.designs.index', 'organizations.organization.organization-promotions.organization-promotion.setup.designs.design'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.designs'
    },
    iconName: {
      sweepstakesSimple: 'palette',
      sweepstakesCodeword: 'palette',
      uGCSweepstakesStandard: 'palette',
      uGCSweepstakesVideo: 'palette',
      photoVotingStandard: 'palette',
      videoVotingStandard: 'palette',
      quizPersonality: 'palette',
      quizTrivia: 'palette',
      votingBallot: 'palette',
      nominationAndVotingBallot: 'palette',
      survey: 'palette',
      photoGallery: 'palette',
      pollStandard: 'palette',
      votingBracket: 'palette',
      eventSignup: 'palette',
      paymentForm: 'palette'
    },
    iconClass: 'ssIcon-picture',
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.messages.index'
    },
    displayOrder: 10
  }, {
    id: 5,
    name: 'Promotion Messages',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.emails'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.emails'
    },
    iconName: 'email',
    iconClass: 'ssIcon-ss-single-email',
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.legal'
    },
    displayOrder: 11
  }, {
    id: 6,
    name: 'Rules and Legal',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.legal', 'organizations.organization.organization-promotions.organization-promotion.setup.pages.page'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.legal'
    },
    iconName: {
      sweepstakesSimple: 'account_balance',
      sweepstakesCodeword: 'account_balance',
      uGCSweepstakesStandard: 'account_balance',
      uGCSweepstakesVideo: 'account_balance',
      photoVotingStandard: 'account_balance',
      videoVotingStandard: 'account_balance',
      quizPersonality: 'account_balance',
      quizTrivia: 'account_balance',
      votingBallot: 'account_balance',
      nominationAndVotingBallot: 'account_balance',
      survey: 'account_balance',
      photoGallery: 'account_balance',
      pollStandard: 'account_balance',
      votingBracket: 'account_balance',
      eventSignup: 'account_balance',
      paymentForm: 'account_balance'
    },
    iconClass: 'ssIcon-legal',
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.publish'
    },
    displayOrder: 12
  }, {
    id: 7,
    name: 'Publish',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.publish'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.publish'
    },
    iconName: {
      sweepstakesSimple: 'launch',
      sweepstakesCodeword: 'launch',
      uGCSweepstakesStandard: 'launch',
      uGCSweepstakesVideo: 'launch',
      photoVotingStandard: 'launch',
      videoVotingStandard: 'launch',
      quizPersonality: 'launch',
      quizTrivia: 'launch',
      votingBallot: 'launch',
      nominationAndVotingBallot: 'launch',
      survey: 'launch',
      photoGallery: 'launch',
      pollStandard: 'launch',
      votingBracket: 'launch',
      eventSignup: 'launch',
      paymentForm: 'launch'
    },
    iconClass: 'ssIcon-play',
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.dashboard'
    },
    displayOrder: 14
  }, {
    id: 8,
    name: 'Quiz',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.quiz', 'organizations.organization.organization-promotions.organization-promotion.setup.quiz.index', 'organizations.organization.organization-promotions.organization-promotion.setup.quiz.outcomes.new', 'organizations.organization.organization-promotions.organization-promotion.setup.quiz.questions.new', 'organizations.organization.organization-promotions.organization-promotion.setup.quiz.outcomes.outcome', 'organizations.organization.organization-promotions.organization-promotion.setup.quiz.questions.question'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.quiz',
      poll: 'organizations.organization.organization-promotions.organization-promotion.setup.quiz.questions-new'
    },
    iconClass: {
      quizPersonality: 'ssIcon-ss-quiz-personality',
      quizTrivia: 'ssIcon-ss-quiz-trivia',
      pollStandard: 'ssIcon-ss-poll'
    },
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.sweepstakes'
    },
    displayOrder: 1
  }, {
    id: 9,
    name: 'Sweepstakes in Promotion',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.sweepstakes'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.sweepstakes'
    },
    iconClass: {
      default: 'ssIcon-ss-sweeps'
    },
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.forms.index',
      uGCGallery: 'organizations.organization.organization-promotions.organization-promotion.setup.entries',
      uGCSweepstakes: 'organizations.organization.organization-promotions.organization-promotion.setup.entries',
      uGCVoting: 'organizations.organization.organization-promotions.organization-promotion.setup.entries'
    },
    displayOrder: 7
  }, {
    id: 10,
    name: 'Recipients',
    relatedRoutes: ['organizations.organization.message-campaigns.message-campaign.setup.recipients'],
    destination: {
      default: 'organizations.organization.message-campaigns.message-campaign.setup.recipients'
    },
    iconName: 'people',
    iconClass: {
      default: 'ssIcon-group'
    },
    nextStep: {
      default: 'organizations.organization.message-campaigns.message-campaign.setup.schedule'
    },
    displayOrder: 16
  }, {
    id: 11,
    name: 'Schedule',
    relatedRoutes: ['organizations.organization.message-campaigns.message-campaign.setup.schedule'],
    destination: {
      default: 'organizations.organization.message-campaigns.message-campaign.setup.schedule'
    },
    iconName: 'date_range',
    iconClass: {
      default: 'ssIcon-calendar'
    },
    nextStep: {
      default: 'organizations.organization.message-campaigns.message-campaign.setup.tags'
    },
    displayOrder: 17
  }, {
    id: 12,
    name: 'Messaging Message',
    relatedRoutes: ['organizations.organization.message-campaigns.message-campaign.setup.message-versions', 'organizations.organization.message-campaigns.message-campaign.setup.message-versions.message-version', 'organizations.organization.message-campaigns.message-campaign.setup.message-versions.new', 'organizations.organization.message-campaigns.message-campaign.setup.messages'],
    destination: {
      default: 'organizations.organization.message-campaigns.message-campaign.setup.message-versions'
    },
    iconName: 'email',
    iconClass: {
      default: 'ssIcon-ss-single-email'
    },
    nextStep: {
      default: 'organizations.organization.message-campaigns.message-campaign.setup.recipients'
    },
    displayOrder: 15
  }, {
    id: 13,
    name: 'Messaging Confirmation',
    relatedRoutes: ['organizations.organization.message-campaigns.message-campaign.setup.confirmation'],
    destination: {
      default: 'organizations.organization.message-campaigns.message-campaign.setup.confirmation'
    },
    iconName: 'playlist_add_check',
    iconClass: {
      default: 'ssIcon-flag-checkered'
    },
    nextStep: {
      default: 'organizations.organization.message-campaigns.message-campaign.dashboard'
    },
    displayOrder: 18
  }, {
    id: 14,
    name: 'Ballot',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.ballot'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.ballot'
    },
    iconClass: {
      default: 'ssIcon-ss-ballot',
      votingBallot: 'ssIcon-ss-seeded-ballot',
      nominationAndVotingBallot: 'ssIcon-ss-nomination-ballot'
    },
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.dates-prizes'
    },
    displayOrder: 2
  }, {
    id: 15,
    name: 'Member Import',
    relatedRoutes: ['organizations.organization.data.audiences.audience.setup.import'],
    destination: {
      default: 'organizations.organization.data.audiences.audience.setup.import'
    },
    iconName: 'people',
    iconClass: {
      default: 'ssIcon-ss-ballot'
    },
    nextStep: {
      default: 'organizations.organization.data.audiences.audience.setup.widget'
    },
    displayOrder: 19
  }, {
    id: 16,
    name: 'Signup Widget',
    relatedRoutes: ['organizations.organization.data.audiences.audience.setup.widget'],
    destination: {
      default: 'organizations.organization.data.audiences.audience.setup.widget'
    },
    iconName: 'playlist_add_check',
    iconClass: {
      default: 'ssIcon-ss-ballot'
    },
    nextStep: {
      default: 'organizations.organization.data.audiences.audience.setup.email'
    },
    displayOrder: 20
  }, {
    id: 17,
    name: 'Welcome Email',
    relatedRoutes: ['organizations.organization.data.audiences.audience.setup.email'],
    destination: {
      default: 'organizations.organization.data.audiences.audience.setup.email'
    },
    iconName: 'email',
    iconClass: {
      default: 'ssIcon-ss-single-email'
    },
    nextStep: {
      default: 'organizations.organization.data.audiences.audience.setup.integration'
    },
    displayOrder: 21
  }, {
    id: 18,
    name: 'Bracket',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.bracket'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.bracket'
    },
    iconClass: {
      default: 'ssIcon-ss-bracket'
    },
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.dates-prizes'
    },
    displayOrder: 1
  }, {
    id: 19,
    name: 'Payment',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.payment'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.payment'
    },
    iconClass: {
      default: 'ssIcon-credit-card'
    },
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.forms.index'
    },
    displayOrder: 1
  }, {
    id: 20,
    name: 'Contact Form',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.forms', 'organizations.organization.organization-promotions.organization-promotion.setup.forms.form', 'organizations.organization.organization-promotions.organization-promotion.setup.forms.contact'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.forms'
    },
    iconName: {
      paymentForm: 'assignment'
    },
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.thanks-sharing'
    },
    displayOrder: 2
  }, {
    id: 21,
    name: 'Integration',
    relatedRoutes: ['organizations.organization.data.audiences.audience.setup.integration'],
    destination: {
      default: 'organizations.organization.data.audiences.audience.setup.integration'
    },
    iconName: 'sync_alt',
    iconClass: {
      default: 'ssIcon-audience-integration'
    },
    nextStep: {
      default: 'organizations.organization.data.dashboard'
    },
    displayOrder: 22
  }, {
    id: 22,
    name: 'Entries',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.entries'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.entries'
    },
    nextStep: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.forms.index'
    },
    iconName: 'person_add',
    displayOrder: 3
  }, {
    id: 23,
    name: 'Tags',
    relatedRoutes: ['organizations.organization.message-campaigns.message-campaign.setup.tags', 'organizations.organization.organization-promotions.organization-promotion.setup.tags'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.tags',
      singleEmail: 'organizations.organization.message-campaigns.message-campaign.setup.tags',
      dripCampaign: 'organizations.organization.message-campaigns.message-campaign.setup.tags',
      newsletter: 'organizations.organization.message-campaigns.message-campaign.setup.tags',
      birthday: 'organizations.organization.message-campaigns.message-campaign.setup.tags',
      weddingAnniversary: 'organizations.organization.message-campaigns.message-campaign.setup.tags'
    },
    iconName: 'sell',
    displayOrder: 3
  }, {
    id: 24,
    name: 'Ads',
    relatedRoutes: ['organizations.organization.organization-promotions.organization-promotion.setup.ads', 'organizations.organization.organization-promotions.organization-promotion.setup.ads-bracket'],
    destination: {
      default: 'organizations.organization.organization-promotions.organization-promotion.setup.ads',
      votingBracket: 'organizations.organization.organization-promotions.organization-promotion.setup.ads-bracket'
    },
    nextStep: {
      votingBracket: 'organizations.organization.organization-promotions.organization-promotion.setup.sweepstakes'
    },
    iconName: 'branding_watermark',
    displayOrder: 2
  }];
  _exports.default = _default;
});