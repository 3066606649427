define("ember-cli-ss-enums/utils/enum-data/page-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Welcome'
  }, {
    id: 2,
    name: 'Contest'
  }, {
    id: 3,
    name: 'PrivacyPolicy'
  }, {
    id: 4,
    name: 'LeaderBoard'
  }, {
    id: 5,
    name: 'Rules'
  }, {
    id: 6,
    name: 'Help'
  }, {
    id: 7,
    name: 'Feedback'
  }, {
    id: 8,
    name: 'Registration'
  }, {
    id: 9,
    name: 'Winners'
  }, {
    id: 10,
    name: 'Gallery'
  }, {
    id: 11,
    name: 'Groups'
  }, {
    id: 12,
    name: 'Votes'
  }, {
    id: 13,
    name: 'Dynamic'
  }, {
    id: 14,
    name: 'Custom'
  }, {
    id: 15,
    name: 'MessageBoard'
  }, {
    id: 16,
    name: 'Standings'
  }, {
    id: 17,
    name: 'VIP'
  }, {
    id: 18,
    name: 'TermsOfService'
  }, {
    id: 19,
    name: 'MasterPage'
  }, {
    id: 20,
    name: 'ThankYou'
  }, {
    id: 21,
    name: 'FacebookLike'
  }];
  _exports.default = _default;
});