define("ember-cli-ss-enums/utils/enum-data/taggable-entity-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    categoryName: 'Contests',
    categoryClass: 'TaggingContests'
  }, {
    id: 2,
    categoryName: 'Interactive Content',
    categoryClass: 'TaggingInteractiveContent'
  }, {
    id: 3,
    categoryName: 'Events',
    categoryClass: 'TaggingEvents'
  }, {
    id: 4,
    categoryName: 'Ecommerce',
    categoryClass: 'TaggingEcommerce'
  }, {
    id: 6,
    categoryName: 'Email',
    categoryClass: 'TaggingEmail'
  }];
  _exports.default = _default;
});