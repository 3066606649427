define("ember-material-components-web/components/mdc-list/divider", ["exports", "@ember/component", "ember-material-components-web/templates/components/mdc-list/divider"], function (_exports, _component, _divider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Attributes
    /**
     * @type {Boolean}
     */
    inset: false,
    //endregion

    //region Ember Hooks
    layout: _divider.default,
    tagName: 'li',
    classNames: Object.freeze(['mdc-list-divider']),
    classNameBindings: Object.freeze(['inset:mdc-list-divider--inset']),
    attributeBindings: Object.freeze(['role']),
    //endregion

    //region Properties
    role: 'separator'
    //endregion
  });
  _exports.default = _default;
});