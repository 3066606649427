define("ember-cli-ss-enums/utils/enum-data/cancellation-reason-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Bad Start'
  }, {
    id: 2,
    name: 'Chain Discount'
  }, {
    id: 3,
    name: 'Competitor'
  }, {
    id: 4,
    name: 'Not Using'
  }, {
    id: 5,
    name: 'One Time Fee'
  }, {
    id: 6,
    name: 'Other'
  }, {
    id: 7,
    name: 'ROI'
  }, {
    id: 8,
    name: 'Turnover'
  }, {
    id: 9,
    name: 'Upgrade'
  }, {
    id: 10,
    name: 'Bugs'
  }, {
    id: 11,
    name: 'Mistake'
  }];
  _exports.default = _default;
});