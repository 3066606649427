define("ember-cli-ss-enums/utils/enum-data/organization-attribute-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 4,
    name: 'HasBackfilledInteractions'
  }, {
    id: 5,
    name: 'HasOldSweeps'
  }, {
    id: 6,
    name: 'HasNewSweeps'
  }, {
    id: 7,
    name: 'HasRegistrationIntegration'
  }, {
    id: 8,
    name: 'HasUGCSweeps'
  }, {
    id: 10,
    name: 'HasFormHelpText'
  }, {
    id: 11,
    name: 'isEblastApiGuid'
  }, {
    id: 12,
    name: 'hasEBlastEmail'
  }, {
    id: 13,
    name: 'hasEBlastSMS'
  }, {
    id: 14,
    name: 'hasMessagingEmail'
  }, {
    id: 15,
    name: 'hasMessagingSMS'
  }, {
    id: 28,
    name: 'hasMessagingBirthday'
  }, {
    id: 29,
    name: 'hasMessagingDrip'
  }, {
    id: 31,
    name: 'HasMessagingIndex'
  }, {
    id: 37,
    name: 'HasEblast'
  }, {
    id: 75,
    name: 'HasAdestraIntegration'
  }];
  _exports.default = _default;
});