define("ember-material-components-web/components/mdc-button", ["exports", "@ember/component", "ember-material-components-web/mixins/mdc-component", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-button"], function (_exports, _component, _mdcComponent, _globalEventHandlers, _mdcButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  var _default = _component.default.extend(_mdcComponent.default, {
    //region Attributes
    /**
     * @type {Boolean}
     */
    secondary: false,
    /**
     * @type {Boolean}
     */
    raised: false,
    /**
     * @type {Boolean}
     */
    unelevated: false,
    /**
     * @type {Boolean}
     */
    compact: false,
    /**
     * @type {Boolean}
     */
    dense: false,
    /**
     * @type {Boolean}
     */
    stroked: false,
    /**
     * @type {Boolean}
     */
    disabled: false,
    /**
     * @type {?String}
     */
    type: null,
    //endregion

    //region Ember Hooks
    layout: _mdcButton.default,
    tagName: 'button',
    classNames: 'mdc-button',
    classNameBindings: Object.freeze(['secondary:mdc-button--accent', 'raised:mdc-button--raised', 'unelevated:mdc-button--unelevated', 'compact:mdc-button--compact', 'dense:mdc-button--dense', 'stroked:mdc-button--stroked', 'mdcClassNames']),
    attributeBindings: Object.freeze(['disabled', 'type', 'style', ..._globalEventHandlers.default]),
    //endregion

    //region Properties
    ripple: true
    //endregion
  });
  _exports.default = _default;
});