define("ember-material-components-web/components/mdc-tab-bar-scroll-frame", ["exports", "ember-material-components-web/components/mdc-tab-bar", "ember-material-components-web/templates/components/mdc-tab-bar"], function (_exports, _mdcTabBar, _mdcTabBar2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _mdcTabBar.default.extend({
    layout: _mdcTabBar2.default,
    style: null
  });
  _exports.default = _default;
});