define("ember-material-components-web/components/mdc-textfield/label", ["exports", "@ember/component", "ember-material-components-web/templates/components/mdc-textfield/label"], function (_exports, _component, _label) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Ember Hooks
    layout: _label.default,
    tagName: 'span',
    classNames: Object.freeze(['mdc-textfield__label']),
    classNameBindings: Object.freeze(['class-names'])
    //endregion
  });
  _exports.default = _default;
});