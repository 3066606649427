define("ember-material-components-web/components/mdc-radio", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "ember-material-components-web/templates/components/mdc-radio", "ember-material-components-web/utils/get-element-property", "ember-material-components-web/mixins/mdc-component", "@material/radio", "ember-material-components-web/mixins/supports-bubbles-false"], function (_exports, _component, _object, _runloop, _mdcRadio, _getElementProperty, _mdcComponent, _radio, _supportsBubblesFalse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  var _default = _component.default.extend(_mdcComponent.MDCComponent, _supportsBubblesFalse.default, {
    //region Attributes
    /**
     * This property is considered read-only by the component, and will not be
     * updated by user action. Please see `onchange` to handle user actions.
     * @type {Boolean}
     */
    checked: false,
    /**
     * @type {Boolean}
     */
    disabled: false,
    /**
     * This will be called when the user indicates they want to change the value
     * of the checkbox. If you want to simulate two-way binding, you can use the
     * checkbox like this:
     *
     * {{mdc-radio checked=(eq radioValue "one") onchange=(action "setRadioValue" "one")}}
     *
     * @type {Function}
     * @param {Boolean} checked
     */
    onchange: x => x,
    /**
     * @type {?String}
     */
    name: null,
    /**
     * @type {?String}
     */
    'input-id': null,
    //endregion

    //region Ember Hooks
    classNames: Object.freeze(['mdc-radio']),
    classNameBindings: Object.freeze(['mdcClassNames']),
    attributeBindings: Object.freeze(['style']),
    layout: _mdcRadio.default,
    didRender() {
      this._super(...arguments);
      (0, _runloop.scheduleOnce)('afterRender', this, this._syncRadioState);
    },
    //endregion

    //region Properties
    ripple: true,
    rippleOptions() {
      return {
        isUnbounded: () => true,
        isSurfaceActive: () => false,
        computeBoundingRect: () => {
          const size = 40;
          const {
            left,
            top
          } = (0, _getElementProperty.default)(this, 'getBoundingClientRect', () => ({
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: 0,
            height: 0
          }))();
          return {
            top,
            left,
            bottom: left + size,
            right: left + size,
            width: size,
            height: size
          };
        }
      };
    },
    //endregion

    //region Methods
    _attachMdcInteractionHandlers() {
      const input = (0, _getElementProperty.default)(this, 'querySelector', () => ({
        addEventListener() {}
      }))('input');
      (0, _object.get)(this, 'mdcInteractionHandlers').forEach(_ref => {
        let [type, handler] = _ref;
        return input.addEventListener(type, handler);
      });
    },
    _detachMdcInteractionHandlers() {
      const input = (0, _getElementProperty.default)(this, 'querySelector', () => ({
        removeEventListener() {}
      }))('input');
      (0, _object.get)(this, 'mdcInteractionHandlers').forEach(_ref2 => {
        let [type, handler] = _ref2;
        return input.removeEventListener(type, handler);
      });
    },
    /**
     * @returns {MDCRadioFoundation}
     */
    createFoundation() {
      return new _radio.MDCRadioFoundation({
        addClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').addObject(className)),
        removeClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').removeObject(className)),
        getNativeControl: () => this.element.querySelector('input')
      });
    },
    _syncRadioState() {
      this.sync('checked');
      this.sync('disabled');
    },
    //endregion

    //region Actions
    actions: {
      inputChanged(ev) {
        const checked = ev.target.checked;
        (0, _object.get)(this, 'onchange')(checked);
      }
    }
    //endregion
  });
  _exports.default = _default;
});