define("ember-cli-ss-enums/utils/enum-data/gender-filter-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: 'Not Specified'
  }, {
    id: 1,
    name: 'Male'
  }, {
    id: 2,
    name: 'Female'
  }, {
    id: 3,
    name: 'Prefer Not To Say'
  },
  // this value exists in the database but is hidden until expanded gender options are implemented
  //  {
  //    id: 4,
  //    name: 'Other'
  //  },
  {
    id: 5,
    name: 'Non-binary'
  }];
  _exports.default = _default;
});