define("ember-cli-ss-enums/utils/enum-data/entity-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 8,
    name: 'Entry',
    displayOrder: 10
  }, {
    id: 14,
    name: 'Field',
    displayOrder: 15
  }, {
    id: 18,
    name: 'Game Group',
    displayOrder: 8
  }, {
    id: 25,
    name: 'Matchup',
    displayOrder: 9
  }, {
    id: 26,
    name: 'Matchup Entry',
    displayOrder: 16
  }, {
    id: 33,
    name: 'Organization',
    displayOrder: 1
  }, {
    id: 38,
    name: 'OrganizationPromotion',
    displayOrder: 2
  }, {
    id: 47,
    name: 'Promotion',
    displayOrder: 3
  }, {
    id: 60,
    name: 'Form',
    displayOrder: 3
  }, {
    id: 138,
    name: 'Outcome',
    displayOrder: 7
  }, {
    id: 140,
    name: 'Question',
    displayOrder: 5 // Used to determine display order for taggableEntities. Better place for this?
  }, {
    id: 141,
    name: 'Question Option',
    displayOrder: 6
  }, {
    id: 143,
    name: 'Interest Tag'
  }, {
    id: 155,
    name: 'Commerce Product',
    displayOrder: 11
  }, {
    id: 169,
    name: 'Message Campaign',
    displayOrder: 12
  }, {
    id: 172,
    name: 'Messaging Message',
    displayOrder: 13
  }, {
    id: 184,
    name: 'Organization Link',
    displayOrder: 14
  }];
  _exports.default = _default;
});