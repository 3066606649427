define("ember-cli-ss-enums/utils/enum-data/billing-frequency-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: 'One-Time',
    timeFrame: 'Once',
    frequency: null
  }, {
    id: 1,
    name: 'Weekly',
    timeFrame: 'per Week',
    frequency: 52
  }, {
    id: 2,
    name: 'Monthly',
    timeFrame: 'per Month',
    frequency: 12
  }, {
    id: 3,
    name: 'Quarterly',
    timeFrame: 'per Quarter',
    frequency: 4
  }, {
    id: 4,
    name: 'Semi-Annually',
    timeFrame: 'every Six Months',
    frequency: 2
  }, {
    id: 5,
    name: 'Annually',
    timeFrame: 'per Year',
    frequency: 1
  }];
  _exports.default = _default;
});