define("ember-cli-ss-enums/utils/enum-data/comparison-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Equal'
  }, {
    id: 2,
    name: 'NotEqual'
  }, {
    id: 3,
    name: 'Between'
  }, {
    id: 4,
    name: 'LessThan'
  }, {
    id: 5,
    name: 'LessThanOrEqualTo'
  }, {
    id: 6,
    name: 'GreaterThan'
  }, {
    id: 7,
    name: 'GreaterThanOrEqualTo'
  }, {
    id: 8,
    name: 'WithinRange'
  }];
  _exports.default = _default;
});