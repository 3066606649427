define("ember-material-components-web/components/mdc-grid-list", ["exports", "@ember/component", "@ember/object", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-grid-list"], function (_exports, _component, _object, _globalEventHandlers, _mdcGridList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Ember Hooks
    layout: _mdcGridList.default,
    classNames: Object.freeze(['mdc-grid-list']),
    classNameBindings: Object.freeze(['tile-gutter-1:mdc-grid-list--tile-gutter-1', 'header-caption:mdc-grid-list--header-caption', 'twoline-caption:mdc-grid-list--twoline-caption', 'tileAspectRatioClass', 'iconAlignStartClass', 'iconAlignEndClass']),
    attributeBindings: Object.freeze([..._globalEventHandlers.default]),
    //endregion

    //region Attributes
    /**
     * This specifies whether to set the tile padding at 1px instead of 4px.
     * @type {Boolean}
     */
    'tile-gutter-1': false,
    /**
     * This changes the footer caption to be a header caption.
     * @type {Boolean}
     */
    'header-caption': false,
    /**
     * When enabled this specifies an additional line of support text.
     * @type {Boolean}
     */
    'twoline-caption': false,
    /**
     * Changes tile aspect ratio.  Possible values are `1x1`, `16x9`, `2x3`, `3x2`, `4x3`, and `3x4`.
     * @type {String}
     */
    'tile-aspect': null,
    /**
     * Aligns icons inside secondary content components. Possible values are `start` or `end`.
     * @type {String}
     */
    'icon-align': null,
    //endregion

    //region Computed Properties
    tileAspectRatioClass: (0, _object.computed)('tile-aspect', function () {
      const aspect = (0, _object.get)(this, 'tile-aspect');
      return aspect ? `mdc-grid-list--tile-aspect-${aspect}` : null;
    }),
    iconAlignStartClass: (0, _object.computed)('icon-align', function () {
      return (0, _object.get)(this, 'icon-align') === 'start' ? 'mdc-grid-list--with-icon-align-start' : null;
    }),
    iconAlignEndClass: (0, _object.computed)('icon-align', function () {
      return (0, _object.get)(this, 'icon-align') === 'end' ? 'mdc-grid-list--with-icon-align-end' : null;
    })
    //endregion
  });
  _exports.default = _default;
});