define("ember-material-components-web/components/mdc-grid-list/tiles/tile/secondary/icon", ["exports", "@ember/component", "ember-material-components-web/templates/components/mdc-grid-list/tiles/tile/secondary/icon"], function (_exports, _component, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Ember Hooks
    layout: _icon.default,
    /**
     * This is currently a tagless component, meant only to yield the correct CSS class.
     * @type {String}
     */
    tagName: ''
    //endregion
  });
  _exports.default = _default;
});