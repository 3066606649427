define("ember-material-components-web/components/mdc-tab-bar/tab/icon-text", ["exports", "@ember/component", "ember-material-components-web/templates/components/mdc-tab-bar/tab/icon-text"], function (_exports, _component, _iconText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Ember Hooks
    layout: _iconText.default,
    classNames: Object.freeze(['mdc-tab__icon-text'])
    //endregion
  });
  _exports.default = _default;
});