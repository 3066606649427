define("ember-material-components-web/components/mdc-menu", ["exports", "@ember/array", "@ember/component", "@ember/runloop", "@ember/object", "ember-material-components-web/templates/components/mdc-menu", "ember-material-components-web/mixins/mdc-component", "ember-material-components-web/utils/style-computed", "@material/menu"], function (_exports, _array, _component, _runloop, _object, _mdcMenu, _mdcComponent, _styleComputed, _menu) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */

  const {
    strings
  } = _menu.MDCSimpleMenuFoundation;
  const TRANSFORM_PROPERTY = _menu.util.getTransformPropertyName(window);

  /**
   * @typedef {Ember.Component} MDCMenuComponent
   */
  var _default = _component.default.extend(_mdcComponent.MDCComponent, {
    //region Attributes
    /**
     * @type {Boolean}
     */
    open: false,
    /**
     * If you are using this component within an instance of `mdc-menu-anchor`,
     * that will yield its component instance. Pass it to here as `anchor`.
     * @type {?Ember.Component}
     */
    anchor: null,
    /**
     * Pass an action to call when the menu is canceled without selection.
     * @type {?Function}
     */
    cancel: x => x,
    /**
     * Enabling this is generally a bad idea for accessibility, so think hard about using this attribute.
     * @type {Boolean}
     */
    'disable-focus': false,
    //endregion

    //region Ember Hooks
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'items', (0, _array.A)([]));
      (0, _object.set)(this, 'itemStyles', (0, _array.A)([]));
    },
    didInsertElement() {
      this._super(...arguments);
      this.updateOpenness();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this.updateOpenness();
    },
    layout: _mdcMenu.default,
    classNames: Object.freeze(['mdc-simple-menu']),
    classNameBindings: Object.freeze(['mdcClassNames', 'open:mdc-simple-menu--open']),
    attributeBindings: Object.freeze(['style', 'tabindex']),
    //endregion

    //region Properties
    /**
     * @type {MDCMenuItemComponent[]}
     */
    items: null,
    /**
     * @private
     * @type {HTMLElement}
     */
    previousFocus: null,
    //endregion

    //region Computed Properties
    itemStyle: (0, _styleComputed.default)('itemStyles'),
    //endregion

    //region Methods
    /**
     * @public
     * @param {MDCMenuItemComponent} item
     */
    registerItem(item) {
      (0, _object.get)(this, 'items').addObject(item);
    },
    /**
     * @public
     * @param {MDCMenuItemComponent} item
     */
    unregisterItem(item) {
      (0, _object.get)(this, 'items').removeObject(item);
    },
    updateOpenness() {
      const foundation = (0, _object.get)(this, 'foundation');
      if (!foundation) {
        return;
      }
      const open = (0, _object.get)(this, 'open');
      if (foundation.isOpen() && !open) {
        foundation.close();
      }
      if (!foundation.isOpen() && open) {
        foundation.open();
      }
    },
    itemAt(index) {
      return (0, _object.get)(this, 'items').objectAt(index);
    },
    createFoundation() {
      return new _menu.MDCSimpleMenuFoundation({
        addClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').addObject(className)),
        removeClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').removeObject(className)),
        hasClass: className => (0, _object.get)(this, 'element.classList').contains(className),
        hasNecessaryDom: () => !!(0, _object.get)(this, 'element') && !!this.element.querySelectorAll(strings.ITEMS_SELECTOR).length,
        getInnerDimensions: () => {
          let item = this.element.querySelector(strings.ITEMS_SELECTOR);
          if (!item) {
            return {
              width: 0,
              height: 0
            };
          }
          let {
            height,
            width
          } = item.getBoundingClientRect();
          return {
            width,
            height
          };
        },
        hasAnchor: () => (0, _object.get)(this, 'anchor'),
        getAnchorDimensions: () => (0, _object.get)(this, 'anchor').getAnchorDimensions(),
        getWindowDimensions: () => ({
          width: window.innerWidth,
          height: window.innerHeight
        }),
        setScale: (x, y) => (0, _runloop.run)(() => this.setStyleFor('mdcStyles', TRANSFORM_PROPERTY, `scale(${x}, ${y}`)),
        setInnerScale: (x, y) => (0, _runloop.run)(() => this.setStyleFor('itemStyles', TRANSFORM_PROPERTY, `scale(${x}, ${y}`)),
        getNumberOfItems: () => (0, _object.get)(this, 'items.length'),
        registerInteractionHandler: (type, handler) => this.registerMdcInteractionHandler(type, handler),
        deregisterInteractionHandler: (type, handler) => this.deregisterMdcInteractionHandler(type, handler),
        registerBodyClickHandler: handler => document.body.addEventListener('click', handler),
        deregisterBodyClickHandler: handler => document.body.removeEventListener('click', handler),
        getYParamsForItemAtIndex: index => !(0, _object.get)(this, 'isDestroyed') && this.itemAt(index).getYParams(),
        setTransitionDelayForItemAtIndex: (index, value) => !(0, _object.get)(this, 'isDestroyed') && this.itemAt(index).setTransitionDelay(value),
        getIndexForEventTarget: target => (0, _object.get)(this, 'items').mapBy('element').indexOf(target),
        notifySelected: _ref => {
          let {
            index
          } = _ref;
          return !(0, _object.get)(this, 'isDestroyed') && this.itemAt(index).notifySelected(index);
        },
        notifyCancel: () => (0, _object.get)(this, 'cancel')(false),
        // False is provided as a convenience for the {{mut}} helper
        saveFocus: () => (0, _object.set)(this, 'previousFocus', document.activeElement),
        restoreFocus: () => (0, _object.get)(this, 'previousFocus') && (0, _object.get)(this, 'previousFocus').focus(),
        isFocused: () => document.activeElement === (0, _object.get)(this, 'element'),
        focus: () => (0, _runloop.run)(() => !(0, _object.get)(this, 'disable-focus') && (0, _object.get)(this, 'element').focus()),
        getFocusedItemIndex: () => (0, _object.get)(this, 'items').mapBy('element').indexOf(document.activeElement),
        focusItemAtIndex: index => !(0, _object.get)(this, 'isDestroyed') && !(0, _object.get)(this, 'disable-focus') && (0, _object.get)(this.itemAt(index), 'element').focus(),
        isRtl: () => window.getComputedStyle((0, _object.get)(this, 'element')).getPropertyValue('direction') === 'rtl',
        setTransformOrigin: value => (0, _runloop.run)(() => this.setStyleFor('mdcStyles', `${TRANSFORM_PROPERTY}-origin`, value)),
        setPosition: _ref2 => {
          let {
            top,
            right,
            bottom,
            left
          } = _ref2;
          (0, _runloop.run)(() => {
            this.setStyleFor('mdcStyles', 'top', top || null);
            this.setStyleFor('mdcStyles', 'right', right || null);
            this.setStyleFor('mdcStyles', 'bottom', bottom || null);
            this.setStyleFor('mdcStyles', 'left', left || null);
          });
        },
        getAccurateTime: () => window.performance.now()
      });
    }
    //endregion
  });
  _exports.default = _default;
});