define("ember-cli-ss-enums/utils/enum-data/upickem-redirects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    page: 'ManageUsers'
  }, {
    id: 2,
    page: 'Profile'
  }, {
    id: 3,
    page: 'ManageContest'
  }, {
    id: 4,
    page: 'DefaultBranding'
  }, {
    id: 5,
    page: 'RegistrationIntegration'
  }, {
    id: 6,
    page: 'CreatePhotoContest'
  }, {
    id: 7,
    page: 'CreateTwoPhotoContest'
  }, {
    id: 8,
    page: 'CreateVideoContest'
  }, {
    id: 9,
    page: 'CreateMP3Contest'
  }, {
    id: 10,
    page: 'CreateEssayContest'
  }, {
    id: 11,
    page: 'CreateEmbeddedMediaContest'
  }, {
    id: 12,
    page: 'CreateOldSweepstakesContest'
  }, {
    id: 13,
    page: 'CreateOldFacebookSweepstakesContest'
  }, {
    id: 14,
    page: 'CreateMultipleChoiceContest'
  }, {
    id: 15,
    page: 'CreateBracketContest'
  }, {
    id: 16,
    page: 'CreateRealityContest'
  }, {
    id: 17,
    page: 'CreateVotersChoice'
  }, {
    id: 18,
    page: 'CopyContest'
  }, {
    id: 19,
    page: 'EblastAccess'
  }, {
    id: 20,
    page: 'CopyPromotionPreset'
  }, {
    id: 21,
    page: 'PromotionsUniversity'
  }, {
    id: 22,
    page: 'EBlastOptOutPage'
  }, {
    id: 23,
    page: 'EBlastMessageTemplates'
  }, {
    id: 24,
    page: 'EBlastMessageSetup'
  }, {
    id: 25,
    page: 'EBlastMessageUsage'
  }, {
    id: 26,
    page: 'EBlastFromEmailAddresses'
  }, {
    id: 27,
    page: 'EBlastWidgets'
  }, {
    id: 28,
    page: 'EBlastImages'
  }, {
    id: 29,
    page: 'EBlastManageContacts'
  }, {
    id: 30,
    page: 'DealsSummary'
  }, {
    id: 31,
    page: 'EBlastCreateEmail'
  }, {
    id: 32,
    page: 'EBlastCreateSMS'
  }, {
    id: 33,
    page: 'EBlastManageMessage'
  }];
  _exports.default = _default;
});