define("ember-material-components-web/templates/components/mdc-toolbar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    row=(component "mdc-toolbar/row" title-style=this.titleStyle first-row-style=this.firstRowStyle register-row=(action "registerRow") deregister-row=(action "deregisterRow") first-row=this.firstRow)
  )}}
  
  */
  {
    "id": "1eaFroRJ",
    "block": "[[[18,1,[[28,[37,1],null,[[\"row\"],[[50,\"mdc-toolbar/row\",0,null,[[\"title-style\",\"first-row-style\",\"register-row\",\"deregister-row\",\"first-row\"],[[30,0,[\"titleStyle\"]],[30,0,[\"firstRowStyle\"]],[28,[37,3],[[30,0],\"registerRow\"],null],[28,[37,3],[[30,0],\"deregisterRow\"],null],[30,0,[\"firstRow\"]]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-toolbar.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});