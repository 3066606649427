define("ember-material-components-web/templates/components/mdc-tab-bar-scroller", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mdc-tab-bar-scroller__indicator mdc-tab-bar-scroller__indicator--back {{this.backIndicatorClassNames}}">
    <a class="mdc-tab-bar-scroller__indicator__inner material-icons" href="#" aria-label="scroll back button">
      navigate_before
    </a>
  </div>
  <div class="mdc-tab-bar-scroller__scroll-frame">
    {{yield (hash
      tab-bar=(component
            "mdc-tab-bar-scroll-frame"
            class="mdc-tab-bar-scroller__scroll-frame__tabs"
            register-tab-bar=(action "registerTabBar")
            deregister-tab-bar=(action "deregisterTabBar")
            scroll-active-tab-into-view=(action "scrollActiveTabIntoView")
            style=this.scrollFrameStyles
      )
    )}}
  </div>
  <div class="mdc-tab-bar-scroller__indicator mdc-tab-bar-scroller__indicator--forward {{this.forwardIndicatorClassNames}}">
    <a class="mdc-tab-bar-scroller__indicator__inner material-icons" href="#" aria-label="scroll forward button">
      navigate_next
    </a>
  </div>
  
  */
  {
    "id": "y7ZGMMRC",
    "block": "[[[10,0],[15,0,[29,[\"mdc-tab-bar-scroller__indicator mdc-tab-bar-scroller__indicator--back \",[30,0,[\"backIndicatorClassNames\"]]]]],[12],[1,\"\\n  \"],[10,3],[14,0,\"mdc-tab-bar-scroller__indicator__inner material-icons\"],[14,6,\"#\"],[14,\"aria-label\",\"scroll back button\"],[12],[1,\"\\n    navigate_before\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"mdc-tab-bar-scroller__scroll-frame\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"tab-bar\"],[[50,\"mdc-tab-bar-scroll-frame\",0,null,[[\"class\",\"register-tab-bar\",\"deregister-tab-bar\",\"scroll-active-tab-into-view\",\"style\"],[\"mdc-tab-bar-scroller__scroll-frame__tabs\",[28,[37,3],[[30,0],\"registerTabBar\"],null],[28,[37,3],[[30,0],\"deregisterTabBar\"],null],[28,[37,3],[[30,0],\"scrollActiveTabIntoView\"],null],[30,0,[\"scrollFrameStyles\"]]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[15,0,[29,[\"mdc-tab-bar-scroller__indicator mdc-tab-bar-scroller__indicator--forward \",[30,0,[\"forwardIndicatorClassNames\"]]]]],[12],[1,\"\\n  \"],[10,3],[14,0,\"mdc-tab-bar-scroller__indicator__inner material-icons\"],[14,6,\"#\"],[14,\"aria-label\",\"scroll forward button\"],[12],[1,\"\\n    navigate_next\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"action\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-tab-bar-scroller.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});