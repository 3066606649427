define("ember-cli-ss-enums/utils/enum-data/token-content-type-key-sub-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // This lists the available permutations of the second parts of token keys.  For example, "linkableImage.altText".
  // These records do not exist as a table-based enum, and therefore do not have ids.
  var _default = [{
    // HtmlText
    tokenContentTypeId: 1,
    keys: ['value']
  }, {
    // PlainText
    tokenContentTypeId: 2,
    keys: ['value']
  }, {
    // LinkableText
    tokenContentTypeId: 3,
    keys: ['value', 'linkUrl']
  }, {
    // Image
    tokenContentTypeId: 4,
    keys: ['srcUrl', 'altText']
  }, {
    // LinkableImage
    tokenContentTypeId: 5,
    keys: ['srcUrl', 'altText', 'linkUrl']
  }, {
    // Code
    tokenContentTypeId: 6,
    keys: ['value']
  }, {
    // Color
    tokenContentTypeId: 7,
    keys: ['value']
  }, {
    // IconImage
    tokenContentTypeId: 8,
    keys: ['srcUrl', 'altText']
  }, {
    // Item
    tokenContentTypeId: 15,
    keys: ['linkUrl', 'itemTitle', 'value', 'itemImageExternalSrcUrl', 'altText', 'itemImageLinkUrl', 'itemButtonLinkUrl', 'itemButtonText']
  }, {
    // RawHtml
    tokenContentTypeId: 19,
    keys: ['value']
  }, {
    // GalleryLayout
    tokenContentTypeId: 20,
    keys: ['value']
  }];
  _exports.default = _default;
});