define("ember-cli-ss-enums/utils/enum-data/promotion-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'VotersChoice',
    isTimeSensitive: false,
    publicName: 'Voter\'s Choice'
  }, {
    id: 2,
    name: 'Offers',
    isTimeSensitive: false,
    publicName: 'Offers'
  }, {
    id: 3,
    name: 'UGCVoting',
    isTimeSensitive: true,
    publicName: 'Photo & Video Contests'
  }, {
    id: 4,
    name: 'Pickem',
    isTimeSensitive: true,
    publicName: 'Pickem'
  }, {
    id: 5,
    name: 'Sweepstakes',
    isTimeSensitive: true,
    publicName: 'Sweeps & Codeword Sweeps',
    creationId: 1
  }, {
    id: 6,
    name: 'Quiz',
    isTimeSensitive: false,
    publicName: 'Quizzes',
    creationId: 4
  }, {
    id: 7,
    name: 'Survey',
    isTimeSensitive: false,
    publicName: 'Surveys',
    creationId: 7
  }, {
    id: 8,
    name: 'UGCSweepstakes',
    isTimeSensitive: true,
    publicName: 'Photo & Video Sweeps',
    creationId: 6
  }, {
    id: 10,
    name: 'UGCGallery',
    isTimeSensitive: true,
    publicName: 'Community Gallery',
    creationId: 10
  }, {
    id: 11,
    name: 'Ballot',
    isTimeSensitive: true,
    publicName: 'Ballots',
    creationId: 13
  }, {
    id: 12,
    name: 'Poll',
    isTimeSensitive: false,
    publicName: 'Polls',
    creationId: 15
  }, {
    id: 13,
    name: 'LegacyBracket',
    isTimeSensitive: false,
    publicName: 'LegacyBracket'
  }, {
    id: 14,
    name: 'VotingBracket',
    isTimeSensitive: true,
    publicName: 'Brackets',
    creationId: 16
  }, {
    id: 15,
    name: 'EventSignup',
    isTimeSensitive: true,
    publicName: 'Event Sign-ups',
    creationId: 17
  }, {
    id: 16,
    name: 'PaymentForm',
    isTimeSensitive: true,
    publicName: 'Payment Forms',
    creationId: 18
  }];
  _exports.default = _default;
});