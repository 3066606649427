define("ember-cli-ss-enums/utils/enum-data/interaction-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 10,
    name: 'Click'
  }, {
    id: 70,
    name: 'Impression'
  }, {
    id: 80,
    name: 'Reach'
  }];
  _exports.default = _default;
});