define("ember-cli-ss-enums/utils/enum-data/audience-integration-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: 'Custom Request'
  }, {
    id: 1,
    name: 'Campaign Monitor'
  }, {
    id: 2,
    name: 'Constant Contact'
  }, {
    id: 3,
    name: 'HubSpot'
  }, {
    id: 4,
    name: 'Mailchimp'
  }, {
    id: 5,
    name: 'PostUp'
  }, {
    id: 6,
    name: 'Sailthru'
  }, {
    id: 7,
    name: 'SalesForce'
  }, {
    id: 8,
    name: 'WhatCounts'
  }, {
    id: 9,
    name: 'BlueConic'
  }, {
    id: 10,
    name: 'CDS'
  }, {
    id: 11,
    name: 'Circulation'
  }, {
    id: 12,
    name: 'ConstantContactImport'
  }, {
    id: 13,
    name: 'DailyDealBuilder'
  }, {
    id: 14,
    name: 'Eventful'
  }, {
    id: 15,
    name: 'FtpUnsubscribe'
  }, {
    id: 16,
    name: 'Gems'
  }, {
    id: 17,
    name: 'GrahamMediaApi'
  }, {
    id: 18,
    name: 'HipCricket'
  }, {
    id: 19,
    name: 'Marketo'
  }, {
    id: 20,
    name: 'Marketron'
  }, {
    id: 21,
    name: 'Newsfore'
  }, {
    id: 22,
    name: 'PromoSuite'
  }, {
    id: 23,
    name: 'TapOnIt'
  }, {
    id: 24,
    name: 'UserImport'
  }, {
    id: 25,
    name: 'Vibes'
  }, {
    id: 26,
    name: 'Harmony'
  }, {
    id: 27,
    name: 'Cox'
  }, {
    id: 28,
    name: 'Adestra'
  }];
  _exports.default = _default;
});