define("ember-material-components-web/templates/components/mdc-linear-progress", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mdc-linear-progress__buffering-dots"></div>
  <div class="mdc-linear-progress__buffer" style={{this.bufferStyles}}></div>
  <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar" style={{this.primaryBarStyles}}>
    <span class="mdc-linear-progress__bar-inner"></span>
  </div>
  <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
    <span class="mdc-linear-progress__bar-inner"></span>
  </div>
  
  */
  {
    "id": "GM1CkjRC",
    "block": "[[[10,0],[14,0,\"mdc-linear-progress__buffering-dots\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"mdc-linear-progress__buffer\"],[15,5,[30,0,[\"bufferStyles\"]]],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"mdc-linear-progress__bar mdc-linear-progress__primary-bar\"],[15,5,[30,0,[\"primaryBarStyles\"]]],[12],[1,\"\\n  \"],[10,1],[14,0,\"mdc-linear-progress__bar-inner\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"mdc-linear-progress__bar mdc-linear-progress__secondary-bar\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"mdc-linear-progress__bar-inner\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-linear-progress.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});