define("ember-material-components-web/templates/components/mdc-layout-grid/inner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    cell=(component "mdc-layout-grid/inner/cell")
  )}}
  
  */
  {
    "id": "KFYFrX0C",
    "block": "[[[18,1,[[28,[37,1],null,[[\"cell\"],[[50,\"mdc-layout-grid/inner/cell\",0,null,null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-layout-grid/inner.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});