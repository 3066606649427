define("ember-material-components-web/components/mdc-card/actions", ["exports", "@ember/component", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-card/actions"], function (_exports, _component, _globalEventHandlers, _actions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Attributes
    /**
     * @type {Boolean}
     */
    vertical: false,
    //endregion

    //region Ember Hooks
    layout: _actions.default,
    tagName: 'section',
    classNames: Object.freeze(['mdc-card__actions']),
    classNameBindings: Object.freeze(['vertical:mdc-card__actions--vertical']),
    attributeBindings: Object.freeze([..._globalEventHandlers.default])
    //endregion
  });
  _exports.default = _default;
});