define("ember-cli-ss-enums/utils/enum-data/logic-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'ManuallyEnteredQualifying'
  }, {
    id: 2,
    name: 'ManuallyEnteredPlacing'
  }, {
    id: 3,
    name: 'CorrectlyPickedWinner'
  }, {
    id: 4,
    name: 'ClosestScoreMatchupPick'
  }, {
    id: 5,
    name: 'VoteSubmitted'
  }, {
    id: 6,
    name: 'EnteredContest'
  }, {
    id: 7,
    name: 'ReferredUserEntry'
  }, {
    id: 8,
    name: 'ReferredUniqueUsersRegistration'
  }, {
    id: 9,
    name: 'OptedInToField'
  }, {
    id: 10,
    name: 'SubmittedFieldValue'
  }, {
    id: 11,
    name: 'WatchedAVideo'
  }];
  _exports.default = _default;
});