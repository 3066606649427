define("ember-material-components-web/components/mdc-fab", ["exports", "@ember/component", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/mixins/mdc-component", "ember-material-components-web/templates/components/mdc-fab"], function (_exports, _component, _globalEventHandlers, _mdcComponent, _mdcFab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  var _default = _component.default.extend(_mdcComponent.default, {
    //region Attributes
    /**
     * @type {Boolean}
     */
    mini: false,
    /**
     * @type {Boolean}
     */
    plain: false,
    /**
     * @type {Boolean}
     */
    disabled: false,
    /**
     * @type {?String}
     */
    'aria-label': null,
    //endregion

    //region Ember Hooks
    layout: _mdcFab.default,
    tagName: 'button',
    classNames: Object.freeze(['mdc-fab']),
    attributeBindings: Object.freeze(['aria-label', 'disabled', 'type', 'style', ..._globalEventHandlers.default]),
    classNameBindings: Object.freeze(['mini:mdc-fab--mini', 'plain:mdc-fab--plain', 'mdcClassNames']),
    //endregion

    //region Properties
    ripple: true
    //endregion
  });
  _exports.default = _default;
});