define("ember-cli-ss-enums/utils/enum-data/field-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'SelectSingle',
    defaultFieldOptionTypeId: 1,
    iconKey: 'arrow_drop_down_circle'
  }, {
    id: 2,
    name: 'RadioButtons',
    defaultFieldOptionTypeId: 1,
    iconKey: 'list'
  }, {
    id: 3,
    name: 'Textbox',
    defaultFieldOptionTypeId: 0,
    iconKey: 'edit'
  }, {
    id: 4,
    name: 'Textarea',
    defaultFieldOptionTypeId: 0,
    iconKey: 'edit_note'
  }, {
    id: 5,
    name: 'SelectMultiple',
    defaultFieldOptionTypeId: 1,
    iconKey: 'list'
  }, {
    id: 6,
    name: 'Checkboxes',
    defaultFieldOptionTypeId: 1,
    iconKey: 'playlist_add_check'
  }, {
    id: 7,
    name: 'CustomDateInput',
    defaultFieldOptionTypeId: 0,
    iconKey: 'date_range'
  }, {
    id: 8,
    name: 'DisplayText',
    defaultFieldOptionTypeId: 0,
    iconKey: 'subject'
  }, {
    id: 9,
    name: 'DisplayNumber',
    defaultFieldOptionTypeId: 0
  }, {
    id: 10,
    name: 'DisplayMoney',
    defaultFieldOptionTypeId: 0
  }, {
    id: 11,
    name: 'DisplayDate',
    defaultFieldOptionTypeId: 0
  }, {
    id: 12,
    name: 'DisplayBoolean',
    defaultFieldOptionTypeId: 0
  }, {
    id: 13,
    name: 'ImageSelect',
    defaultFieldOptionTypeId: 0
  }, {
    id: 14,
    name: 'VideoSelect',
    defaultFieldOptionTypeId: 0
  }, {
    id: 15,
    name: 'FileSelect',
    defaultFieldOptionTypeId: 0
  }, {
    id: 16,
    name: 'DynamicTextboxes',
    defaultFieldOptionTypeId: 0
  }, {
    id: 17,
    name: 'GroupedFields',
    defaultFieldOptionTypeId: 0,
    iconKey: 'device_hub'
  }, {
    id: 18,
    name: 'Password',
    defaultFieldOptionTypeId: 0,
    iconKey: 'lock'
  }, {
    id: 19,
    name: 'FacebookLikeApi',
    defaultFieldOptionTypeId: 0,
    iconKey: 'thumb_up'
  }, {
    id: 20,
    name: 'TwitterFollowApi',
    defaultFieldOptionTypeId: 0
  }, {
    id: 21,
    name: 'SingleCheckbox',
    defaultFieldOptionTypeId: 0,
    iconKey: 'check_box'
  }, {
    id: 22,
    name: 'NumberInput',
    defaultFieldOptionTypeId: 0,
    iconKey: 'format_list_numbered_rtl'
  }, {
    id: 23,
    name: 'EmailTextbox',
    defaultFieldOptionTypeId: 0,
    iconKey: 'mail'
  }, {
    id: 24,
    name: 'Codeword',
    defaultFieldOptionTypeId: 0
  }, {
    id: 26,
    name: 'TwitterTweetApi',
    defaultFieldOptionTypeId: 0
  }, {
    id: 28,
    name: 'UrlTextbox',
    defaultFieldOptionTypeId: 0
  }, {
    id: 29,
    name: 'Optin',
    defaultFieldOptionTypeId: 0,
    iconKey: 'check'
  }, {
    id: 30,
    name: 'Captcha',
    defaultFieldOptionTypeId: 0
  }, {
    id: 32,
    name: 'GooglePlace',
    defaultFieldOptionTypeId: 0
  }, {
    id: 33,
    name: 'ExtraChances',
    defaultFieldOptionTypeId: 0,
    iconKey: 'share'
  }, {
    id: 34,
    name: 'WatchVideo',
    defaultFieldOptionTypeId: 0,
    iconKey: 'videocam'
  }, {
    id: 36,
    name: 'WebLink',
    defaultFieldOptionTypeId: 1,
    iconKey: 'link'
  }, {
    id: 37,
    name: 'MobileApp',
    defaultFieldOptionTypeId: 1,
    iconKey: 'phone_iphone'
  }, {
    id: 38,
    name: 'SmartSpeakerSkill',
    defaultFieldOptionTypeId: 1,
    iconKey: 'keyboard_voice'
  }, {
    id: 39,
    name: 'Instagram',
    defaultFieldOptionTypeId: 1
  }, {
    id: 40,
    name: 'FacebookLink',
    defaultFieldOptionTypeId: 1
  }, {
    id: 41,
    name: 'CodewordForExtraChances',
    defaultFieldOptionTypeId: 1,
    iconKey: 'lock'
  }, {
    id: 42,
    name: 'XLink',
    defaultFieldOptionTypeId: 1
  }];
  _exports.default = _default;
});