define("ember-material-components-web/components/mdc-icon-toggle", ["exports", "@ember/runloop", "@ember/component", "@ember/object", "ember-material-components-web/templates/components/mdc-icon-toggle", "ember-material-components-web/mixins/mdc-component", "ember-material-components-web/utils/get-element-property", "@material/icon-toggle"], function (_exports, _runloop, _component, _object, _mdcIconToggle, _mdcComponent, _getElementProperty, _iconToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */

  const {
    strings: {
      DATA_TOGGLE_ON,
      DATA_TOGGLE_OFF,
      ARIA_PRESSED,
      ARIA_DISABLED,
      ARIA_LABEL
    }
  } = _iconToggle.MDCIconToggleFoundation;
  var _default = _component.default.extend(_mdcComponent.MDCComponent, {
    //region Attributes
    /**
     * This property is considered read-only by the component, and will not be
     * updated by user action. Please see `onchange` to handle user actions.
     * @type {Boolean}
     */
    pressed: false,
    /**
     * @type {Boolean}
     */
    disabled: false,
    /**
     * This will be called when the user indicates they want to change the value
     * of the toggle. If you want to simulate two-way binding, you can use the
     * checkbox like this:
     *
     * {{mdc-icon-toggle pressed=isThingOn onchange=(action (mut isThingOn))}}
     *
     * @type {Function}
     * @param {Boolean} isOn
     */
    onchange: x => x,
    /**
     * @type {?String}
     */
    label: null,
    //endregion

    //region Ember Hooks
    tagName: 'i',
    classNames: Object.freeze(['mdc-icon-toggle']),
    layout: _mdcIconToggle.default,
    didRender() {
      this._super(...arguments);
      (0, _runloop.scheduleOnce)('afterRender', this, this._syncToggleState);
    },
    attributeBindings: Object.freeze([DATA_TOGGLE_ON, DATA_TOGGLE_OFF, ARIA_PRESSED, ARIA_DISABLED, ARIA_LABEL, 'tabindex', 'style']),
    classNameBindings: Object.freeze(['mdcClassNames', 'aria-disabled:mdc-icon-toggle--disabled']),
    //endregion

    //region Properties
    ripple: true,
    rippleOptions() {
      return {
        isUnbounded: () => true,
        isSurfaceActive: () => (0, _object.get)(this, 'foundation').isKeyboardActivated(),
        computeBoundingRect: () => {
          const size = 48; // In case the icon font hasn't loaded yet
          const {
            left,
            top
          } = (0, _getElementProperty.default)(this, 'getBoundingClientRect', () => ({
            top: 0,
            left: 0
          }))();
          return {
            top,
            left,
            bottom: left + size,
            right: left + size,
            width: size,
            height: size
          };
        }
      };
    },
    //endregion

    //region Methods
    createFoundation() {
      const component = this;
      return new _iconToggle.MDCIconToggleFoundation({
        addClass(className) {
          (0, _mdcComponent.addClass)(className, component);
        },
        removeClass(className) {
          (0, _mdcComponent.removeClass)(className, component);
        },
        registerInteractionHandler(type, handler) {
          component.on(type, handler);
        },
        deregisterInteractionHandler(type, handler) {
          component.off(type, handler);
        },
        setText(text) {
          (0, _object.set)(component, 'text', text);
        },
        getTabIndex() {
          return (0, _object.get)(component, 'tabindex');
        },
        setTabIndex(tabIndex) {
          (0, _object.set)(component, 'tabindex', tabIndex);
        },
        getAttr(name) {
          // Note that this only actually works for attribute names that are in
          // `attributeBindings`, but currently the foundation class exposes
          // every name it might use so that we can make that happen.
          return (0, _object.get)(component, name);
        },
        setAttr(name, value) {
          (0, _object.set)(component, name, value);
        },
        rmAttr(name) {
          (0, _object.set)(component, name, null);
        },
        notifyChange(evtData) {
          (0, _object.get)(component, 'onchange')(evtData.isOn);
        }
      });
    },
    syncPressed() {
      const foundation = (0, _object.get)(this, 'foundation');
      if (!foundation) {
        return;
      }
      const pressed = !!(0, _object.get)(this, 'pressed');
      if (foundation && foundation.isOn() !== pressed) {
        foundation.toggle(pressed);
      }
    },
    _syncToggleState() {
      this.sync('disabled');
      this.syncPressed();
    }
    //endregion
  });
  _exports.default = _default;
});