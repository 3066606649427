define("ember-cli-ss-enums/utils/enum-data/sender-account-validation-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: 'Newly Created'
  }, {
    id: 1,
    name: 'Failed'
  }, {
    id: 2,
    name: 'TXT Record OK'
  }, {
    id: 3,
    name: 'TXT Record Good'
  }, {
    id: 4,
    name: 'Failed Feedback'
  }, {
    id: 100,
    name: 'Starting Retest'
  }, {
    id: 101,
    name: 'Failed TXT Lookup'
  }, {
    id: 102,
    name: 'Passed TXT Lookup'
  }, {
    id: 103,
    name: 'Feedback Test Email Sent'
  }, {
    id: 104,
    name: 'Failed Feedback'
  }, {
    id: 1000,
    name: 'Valid And Verified'
  }];
  _exports.default = _default;
});