define("ember-cli-ss-enums/utils/enum-data/language", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    code: 'en-US',
    name: 'English (United States)'
  }, {
    id: 2,
    code: 'es-MX',
    name: 'Spanish (Mexico)'
  }, {
    id: 3,
    code: 'fr-CA',
    name: 'French (Canada)'
  }];
  _exports.default = _default;
});