define("ember-cli-ss-enums/utils/enum-data/promotion-sub-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'BallotVotersChoice',
    multiTierTaggingEnabled: false
  }, {
    id: 2,
    name: 'SweepstakesSimple',
    publicName: 'Sweepstakes',
    multiTierTaggingEnabled: false
  }, {
    id: 3,
    name: 'SweepstakesCodeword',
    publicName: 'Codeword Sweeps',
    multiTierTaggingEnabled: false
  }, {
    id: 4,
    name: 'QuizPersonality',
    publicName: 'Personality Quiz',
    multiTierTaggingEnabled: true
  }, {
    id: 5,
    name: 'QuizTrivia',
    publicName: 'Trivia Quiz',
    multiTierTaggingEnabled: true
  }, {
    id: 6,
    name: 'BallotBracket',
    multiTierTaggingEnabled: false
  }, {
    id: 7,
    name: 'BallotBracketUpFront',
    multiTierTaggingEnabled: false
  }, {
    id: 8,
    name: 'PickemStandard',
    multiTierTaggingEnabled: false
  }, {
    id: 9,
    name: 'PickemRank',
    multiTierTaggingEnabled: false
  }, {
    id: 10,
    name: 'PickemWithKnockout',
    multiTierTaggingEnabled: false
  }, {
    id: 11,
    name: 'PickemKnockout',
    multiTierTaggingEnabled: false
  }, {
    id: 12,
    name: 'PickemBracket',
    multiTierTaggingEnabled: false
  }, {
    id: 13,
    name: 'PickemBracketUpFront',
    multiTierTaggingEnabled: false
  }, {
    id: 14,
    name: 'PickemEliminate',
    multiTierTaggingEnabled: false
  }, {
    id: 15,
    name: 'QuizUpickem',
    multiTierTaggingEnabled: false
  }, {
    id: 16,
    name: 'UGCStandard',
    multiTierTaggingEnabled: false
  }, {
    id: 17,
    name: 'UGCBracket',
    multiTierTaggingEnabled: false
  }, {
    id: 18,
    name: 'Survey',
    publicName: 'Survey',
    multiTierTaggingEnabled: false
  }, {
    id: 21,
    name: 'UGCSweepstakesStandard',
    publicName: 'Photo Sweeps',
    multiTierTaggingEnabled: false
  }, {
    id: 22,
    name: 'OffersEcommerce',
    multiTierTaggingEnabled: false
  }, {
    id: 23,
    name: 'PhotoVotingStandard',
    publicName: 'Photo Contest',
    multiTierTaggingEnabled: true
  }, {
    id: 24,
    name: 'PhotoGallery',
    publicName: 'Community Gallery',
    multiTierTaggingEnabled: true
  }, {
    id: 25,
    name: 'UGCSweepstakesVideo',
    publicName: 'Video Sweeps',
    multiTierTaggingEnabled: false
  }, {
    id: 26,
    name: 'VideoVotingStandard',
    publicName: 'Video Contest',
    multiTierTaggingEnabled: true
  }, {
    id: 27,
    name: 'VotingBallot',
    publicName: 'Voting Ballot',
    multiTierTaggingEnabled: true
  }, {
    id: 28,
    name: 'NominationAndVotingBallot',
    publicName: 'Nomination & Voting Ballot',
    multiTierTaggingEnabled: true
  }, {
    id: 29,
    name: 'PollStandard',
    publicName: 'Poll',
    multiTierTaggingEnabled: true
  }, {
    id: 30,
    name: 'VotingBracket',
    publicName: 'Voting Bracket',
    multiTierTaggingEnabled: false
  }, {
    id: 31,
    name: 'EventSignup',
    publicName: 'Event Sign-Up',
    multiTierTaggingEnabled: false
  }, {
    id: 32,
    name: 'PaymentForm',
    publicName: 'Payment Form',
    multiTierTaggingEnabled: false
  }];
  _exports.default = _default;
});