define("ember-material-components-web/templates/components/mdc-list/item/start-detail", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield "mdc-list-item__start-detail"}}
  
  */
  {
    "id": "EguS9CyD",
    "block": "[[[18,1,[\"mdc-list-item__start-detail\"]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-list/item/start-detail.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});