define("ember-cli-ss-enums/utils/enum-data/sales-packages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 100,
    name: 'Second Street Engagement Platform'
  }, {
    id: 101,
    name: 'Contests, Interactive Content, and Email'
  }, {
    id: 102,
    name: 'Contests, Interactive Content, and Audience Insights'
  }, {
    id: 103,
    name: 'Contests, Email, and Audience Insights'
  }, {
    id: 104,
    name: 'Interactive Content, Email, and Audience Insights'
  }, {
    id: 105,
    name: 'Contests and Interactive Content'
  }, {
    id: 106,
    name: 'Interactive Content and Email'
  }, {
    id: 107,
    name: 'Email and Audience Insights'
  }, {
    id: 108,
    name: 'Contests and Email'
  }, {
    id: 109,
    name: 'Contests and Audience Insights'
  }, {
    id: 110,
    name: 'Interactive Content and Audience Insights'
  }, {
    id: 111,
    name: 'Contests'
  }, {
    id: 112,
    name: 'Interactive Content'
  }, {
    id: 113,
    name: 'Email'
  }, {
    id: 114,
    name: 'Audience Insights'
  }, {
    id: 120,
    name: 'Events'
  }, {
    id: 121,
    name: 'Ecommerce'
  }, {
    id: 122,
    name: 'Ecommerce and Events'
  }, {
    id: 150,
    name: 'Pro Football'
  }, {
    id: 151,
    name: 'Basketball'
  }, {
    id: 152,
    name: 'Auto Racing'
  }, {
    id: 153,
    name: 'Golf'
  }, {
    id: 154,
    name: 'College Bowls'
  }, {
    id: 160,
    name: 'Offers'
  }, {
    id: 161,
    name: 'Custom Contest'
  }, {
    id: 162,
    name: 'Custom Services'
  }, {
    id: 163,
    name: 'myCapture Publisher'
  }, {
    id: 165,
    name: 'Email - By Audience Size'
  }, {
    id: 166,
    name: 'Blogger Email and Audience Insights'
  }];
  _exports.default = _default;
});