define("ember-material-components-web/templates/components/mdc-elevation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield}}
  
  */
  {
    "id": "jxFcUuih",
    "block": "[[[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-elevation.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});