define("ember-cli-ss-enums/utils/enum-data/permission-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'View'
  }, {
    id: 5,
    name: 'Administer'
  }];
  _exports.default = _default;
});