define("ember-cli-ss-enums/utils/enum-data/ugc-sweeps-entry-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: "An unlimited number of times",
    entryIntervalTypeId: 2,
    entriesAllowedNumber: 999
  }, {
    id: 2,
    name: "Once per Round",
    entryIntervalTypeId: 1,
    entriesAllowedNumber: 1
  }, {
    id: 3,
    name: "Once per Hour",
    entryIntervalTypeId: 2,
    entriesAllowedNumber: 1
  }, {
    id: 4,
    name: "Once per Day",
    entryIntervalTypeId: 3,
    entriesAllowedNumber: 1
  }, {
    id: 5,
    name: "Once per Week",
    entryIntervalTypeId: 4,
    entriesAllowedNumber: 1
  }];
  _exports.default = _default;
});