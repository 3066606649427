define("ember-material-components-web/components/mdc-checkbox", ["exports", "@ember/array", "@ember/component", "@ember/object", "@ember/runloop", "ember-material-components-web/templates/components/mdc-checkbox", "ember-material-components-web/mixins/mdc-component", "ember-material-components-web/utils/get-element-property", "@material/checkbox", "ember-material-components-web/mixins/supports-bubbles-false"], function (_exports, _array, _component, _object, _runloop, _mdcCheckbox, _mdcComponent, _getElementProperty, _checkbox, _supportsBubblesFalse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */

  const {
    ANIM_END_EVENT_NAME
  } = _checkbox.MDCCheckboxFoundation.strings;
  var _default = _component.default.extend(_mdcComponent.MDCComponent, _supportsBubblesFalse.default, {
    //region Attributes
    /**
     * This property is considered read-only by the component, and will not be
     * updated by user action. Please see `onchange` to handle user actions.
     * @type {Boolean}
     */
    checked: false,
    /**
     * This property is considered read-only by the component, and will not be
     * updated by user action.
     * @type {Boolean}
     */
    indeterminate: false,
    /**
     * @type {Boolean}
     */
    disabled: false,
    /**
     * This will be called when the user indicates they want to change the value
     * of the checkbox. If you want to simulate two-way binding, you can use the
     * checkbox like this:
     *
     * {{mdc-checkbox checked=isChecked onchange=(action (mut isChecked))}}
     *
     * @type {Function}
     * @param {Boolean} checked
     */
    onchange: x => x,
    /**
     * @type {?String}
     */
    name: null,
    /**
     * @type {?String}
     */
    'input-id': null,
    //endregion

    //region Ember Hooks
    classNames: Object.freeze(['mdc-checkbox']),
    classNameBindings: Object.freeze(['mdcClassNames']),
    attributeBindings: Object.freeze(['style']),
    layout: _mdcCheckbox.default,
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'changeHandlers', (0, _array.A)([]));
    },
    didRender() {
      this._super(...arguments);
      (0, _runloop.scheduleOnce)('afterRender', this, this._syncCheckboxState);
    },
    //endregion

    //region Properties
    /**
     * @type {EventListener[]}
     */
    changeHandlers: null,
    ripple: true,
    //endregion

    //region Methods
    /**
     * @returns {MDCCheckboxFoundation}
     */
    createFoundation() {
      return new _checkbox.MDCCheckboxFoundation({
        addClass: className => (0, _runloop.next)(() => (0, _mdcComponent.addClass)(className, this)),
        removeClass: className => (0, _runloop.next)(() => (0, _mdcComponent.removeClass)(className, this)),
        registerAnimationEndHandler: handler => (0, _getElementProperty.default)(this, 'addEventListener', () => null)(ANIM_END_EVENT_NAME, handler),
        deregisterAnimationEndHandler: handler => (0, _getElementProperty.default)(this, 'removeEventListener', () => null)(ANIM_END_EVENT_NAME, handler),
        registerChangeHandler: handler => (0, _runloop.run)(() => (0, _object.get)(this, 'changeHandlers').addObject(handler)),
        deregisterChangeHandler: handler => (0, _runloop.run)(() => (0, _object.get)(this, 'changeHandlers').removeObject(handler)),
        getNativeControl: () => this.element.querySelector('input'),
        forceLayout: () => undefined,
        isAttachedToDOM: () => !!(0, _object.get)(this, 'element')
      });
    },
    _syncCheckboxState() {
      this.sync('checked');
      this.sync('indeterminate');
      this.sync('disabled');
    },
    //endregion

    //region Actions
    actions: {
      inputChanged(ev) {
        const checked = ev.target.checked;
        (0, _object.get)(this, 'changeHandlers').forEach(handler => handler(ev));
        (0, _object.get)(this, 'onchange')(checked);
      }
    }
    //endregion
  });
  _exports.default = _default;
});