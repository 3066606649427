define("ember-cli-ss-enums/utils/enum-data/domain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: 'Default'
  }];
  _exports.default = _default;
});