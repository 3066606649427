define("ember-material-components-web/mixins/mdc-tab-component", ["exports", "@ember/runloop", "@ember/object/mixin", "@ember/object", "@material/tabs", "ember-material-components-web/mixins/mdc-component", "ember-material-components-web/templates/components/mdc-tab-bar/tab"], function (_exports, _runloop, _mixin, _object, _tabs, _mdcComponent, _tab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  /* eslint-disable ember/no-new-mixins */
  var _default = _mixin.default.create(_mdcComponent.MDCComponent, {
    //region Ember Hooks
    layout: _tab.default,
    classNames: Object.freeze(['mdc-tab']),
    classNameBindings: Object.freeze(['has-icon-and-text:mdc-tab--with-icon-and-text', 'mdcClassNames']),
    attributeBindings: Object.freeze(['style']),
    didInsertElement() {
      this._super(...arguments);
      (0, _object.get)(this, 'register-tab')(this);
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _object.get)(this, 'deregister-tab')(this);
    },
    didReceiveAttrs() {
      this._super(...arguments);
      const active = (0, _object.get)(this, 'active');
      const foundation = (0, _object.get)(this, 'foundation');
      if (foundation) {
        foundation.setActive(active);
      }
      if (active) {
        (0, _object.get)(this, 'switch-to-tab')(this);
      }
    },
    //endregion

    //region Properties
    ripple: true,
    //endregion

    //region Computed Properties
    /**
     * @returns {Boolean}
     */
    get preventDefaultOnClick() {
      const foundation = (0, _object.get)(this, 'foundation');
      if (foundation) {
        return foundation.preventsDefaultOnClick();
      }
      return undefined;
    },
    set preventDefaultOnClick(value) {
      (0, _object.get)(this, 'foundation').setPreventDefaultOnClick(value);
    },
    /**
     * @returns {Number}
     */
    get computedWidth() {
      const foundation = (0, _object.get)(this, 'foundation');
      if (foundation) {
        return foundation.getComputedWidth();
      }
      return undefined;
    },
    /**
     * @returns {Number}
     */
    get computedLeft() {
      const foundation = (0, _object.get)(this, 'foundation');
      if (foundation) {
        return foundation.getComputedLeft();
      }
      return undefined;
    },
    //endregion

    //region Methods
    createFoundation() {
      return new _tabs.MDCTabFoundation({
        addClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').addObject(className)),
        removeClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').removeObject(className)),
        registerInteractionHandler: (type, handler) => this.registerMdcInteractionHandler(type, handler),
        deregisterInteractionHandler: (type, handler) => this.deregisterMdcInteractionHandler(type, handler),
        getOffsetWidth: () => (0, _object.get)(this, 'element').offsetWidth,
        getOffsetLeft: () => (0, _object.get)(this, 'element').offsetLeft,
        notifySelected: () => (0, _object.get)(this, 'tab-selected')({
          tab: this
        }, true)
      });
    },
    measureSelf() {
      const foundation = (0, _object.get)(this, 'foundation');
      if (foundation) {
        return foundation.measureSelf();
      }
    }
    //endregion
  });
  _exports.default = _default;
});