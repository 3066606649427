define("ember-material-components-web/utils/mdc-ripple-adapter", ["exports", "@ember/object", "@ember/runloop", "ember-material-components-web/utils/get-element-property", "@material/ripple"], function (_exports, _object, _runloop, _getElementProperty, _ripple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.createRippleAdapter = void 0;
  const MATCHES = _ripple.util.getMatchesProperty(HTMLElement.prototype);

  /**
   * @param {Ember.Component} component - The component must mix in {@link MDCComponent}
   * @param {Object} overrides
   * @returns {Object}
   */
  const createRippleAdapter = (component, overrides) => Object.assign({
    browserSupportsCssVars: () => _ripple.util.supportsCssVariables(window),
    isUnbounded: () => false,
    isSurfaceActive: () => (0, _object.get)(component, 'element')[MATCHES](':active'),
    isSurfaceDisabled: () => (0, _object.get)(component, 'disabled'),
    addClass: className => (0, _runloop.run)(() => (0, _object.get)(component, 'mdcClasses').addObject(className)),
    removeClass: className => (0, _runloop.run)(() => (0, _object.get)(component, 'mdcClasses').removeObject(className)),
    registerInteractionHandler: (evtType, handler) => component.registerMdcInteractionHandler(evtType, handler),
    deregisterInteractionHandler: (evtType, handler) => component.deregisterMdcInteractionHandler(evtType, handler),
    registerResizeHandler: handler => window.addEventListener('resize', handler),
    deregisterResizeHandler: handler => window.removeEventListener('resize', handler),
    updateCssVariable: (varName, value) => (0, _runloop.run)(() => component.setStyleFor('mdcStyles', varName, value)),
    computeBoundingRect: () => (0, _getElementProperty.default)(component, 'getBoundingClientRect', () => ({
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: 0,
      height: 0
    }))(),
    getWindowPageOffset: () => ({
      x: window.pageXOffset,
      y: window.pageYOffset
    })
  }, overrides);
  _exports.createRippleAdapter = createRippleAdapter;
  var _default = createRippleAdapter;
  _exports.default = _default;
});