define("ember-material-components-web/mixins/supports-bubbles-false", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins */
  var _default = _mixin.default.create({
    //region attributes
    bubbles: true,
    //endregion

    //region actions
    actions: {
      clicked(ev) {
        if (!(0, _object.get)(this, 'bubbles')) {
          ev.stopPropagation();
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});