define("ember-material-components-web/components/mdc-tab-bar/tab", ["exports", "@ember/component", "ember-material-components-web/mixins/mdc-tab-component"], function (_exports, _component, _mdcTabComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  var _default = _component.default.extend(_mdcTabComponent.default, {
    //region Attributes
    /**
     * This property is considered read-only by the component, and will not be
     * updated by user action. Please see `become-active` to handle user actions.
     * @type {Boolean}
     */
    active: false,
    /**
     * This will be called when the user indicates they want to change the active tab.
     * If you want to simulate two-way binding, you can use the tab like this:
     *
     * {{#tab-bar.tab active=isSecondTabActive become-active=(action (mut isSecondTabActive))}}
     *
     * @type {Function}
     * @param {Boolean} isActive
     */
    'become-active': x => x,
    //endregion

    //region Ember Hooks
    classNameBindings: Object.freeze(['active:mdc-tab--active'])
    //endregion
  });
  _exports.default = _default;
});