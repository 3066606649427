define("ember-material-components-web/components/mdc-drawer", ["exports", "@ember/error", "@ember/array", "@ember/component", "@ember/runloop", "@ember/object", "ember-material-components-web/mixins/mdc-component", "ember-material-components-web/utils/get-element-property", "@material/drawer", "ember-material-components-web/templates/components/mdc-drawer"], function (_exports, _error, _array, _component, _runloop, _object, _mdcComponent, _getElementProperty, _drawer, _mdcDrawer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  var _default = _component.default.extend(_mdcComponent.MDCComponent, {
    //region Ember Hooks
    layout: _mdcDrawer.default,
    classNames: Object.freeze(['mdc-drawer']),
    classNameBindings: Object.freeze(['mdcClassNames', 'isPermanent:mdc-permanent-drawer']),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'changeHandlers', (0, _array.A)([]));
    },
    didInsertElement() {
      this._super(...arguments);
      this.updateOpenness();
    },
    didUpdateAttrs() {
      this._super(...arguments);
      this.updateOpenness();
    },
    //endregion

    //region Attributes
    /**
     * @type {Boolean}
     */
    permanent: true,
    /**
     * @type {Boolean}
     */
    persistent: false,
    /**
     * @type {Boolean}
     */
    temporary: false,
    /**
     * @type {Boolean}
     */
    open: false,
    onopen: x => x,
    onclose: x => x,
    //endregion

    //region ComputedProperties
    isPermanent: (0, _object.computed)('permanent', 'persistent', 'temporary', function () {
      const {
        permanent,
        persistent,
        temporary
      } = (0, _object.getProperties)(this, 'permanent', 'persistent', 'temporary');
      if (permanent && (temporary || persistent)) {
        throw new _error.default('Cannot be permanent and temporary or persistent');
      }
      return permanent;
    }),
    //endregion

    //region Methods
    updateOpenness() {
      const foundation = (0, _object.get)(this, 'foundation');
      if (!foundation) {
        return;
      }
      const open = (0, _object.get)(this, 'open');
      if (foundation.isOpen() && !open) {
        foundation.close();
      }
      if (!foundation.isOpen() && open) {
        foundation.open();
      }
    },
    /**
     * @returns {MDCPersistentDrawerFoundation|MDCTemporaryDrawerFoundation|Object}
     */
    createFoundation() {
      const {
        isPermanent,
        persistent
      } = (0, _object.getProperties)(this, 'isPermanent', 'persistent');
      if (isPermanent) {
        return {
          init() {},
          destroy() {}
        };
      }
      const Foundation = persistent ? _drawer.MDCPersistentDrawerFoundation : _drawer.MDCTemporaryDrawerFoundation;
      (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').addObject(Foundation.cssClasses.ROOT));
      const {
        FOCUSABLE_ELEMENTS,
        DRAWER_SELECTOR
      } = Foundation.strings;
      const adapter = {
        addClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').addObject(className)),
        removeClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').removeObject(className)),
        hasClass: className => (0, _object.get)(this, 'mdcClasses').includes(className),
        registerInteractionHandler: (type, handler) => this.registerMdcInteractionHandler(type, handler),
        deregisterInteractionHandler: (type, handler) => this.deregisterMdcInteractionHandler(type, handler),
        registerDrawerInteractionHandler: (type, handler) => this.registerMdcInteractionHandler(type, handler),
        deregisterDrawerInteractionHandler: (type, handler) => this.deregisterMdcInteractionHandler(type, handler),
        registerTransitionEndHandler: handler => this.registerMdcInteractionHandler('transitionend', handler),
        deregisterTransitionEndHandler: handler => this.deregisterMdcInteractionHandler('transitionend', handler),
        registerDocumentKeydownHandler: handler => (0, _runloop.run)(() => window.document.addEventListener('keydown', handler)),
        deregisterDocumentKeydownHandler: handler => (0, _runloop.run)(() => window.document.removeEventListener('keydown', handler)),
        getDrawerWidth: () => {
          const {
            width
          } = (0, _getElementProperty.default)(this, 'getBoundingClientRect', () => ({
            width: 0
          }))();
          return width;
        },
        setTranslateX: value => (0, _runloop.run)(() => this.setStyleFor('mdcStyles', 'translateX', `${value}px`)),
        saveElementTabState: el => (0, _object.set)(this, 'previousTabState', el.tabIndex),
        restoreElementTabState: el => el.tabIndex = (0, _object.get)(this, 'previousTabState'),
        makeElementUntabbable: el => el.tabIndex = -1,
        notifyOpen: () => (0, _object.set)(this, 'open', true),
        notifyClose: () => (0, _object.set)(this, 'open', false),
        isRtl: () => (0, _getElementProperty.default)(this, 'direction') === 'rtl',
        getFocusableElements: () => this.element.querySelectorAll(FOCUSABLE_ELEMENTS),
        hasNecessaryDom: () => Boolean(this.element),
        isDrawer: el => (0, _object.get)(this, 'element').querySelector(DRAWER_SELECTOR) === el
      };
      return new Foundation(adapter);
    }
    //endregion
  });
  _exports.default = _default;
});