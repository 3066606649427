define("ember-material-components-web/components/mdc-textfield/helptext", ["exports", "@ember/component", "@ember/object", "ember-material-components-web/templates/components/mdc-textfield/helptext", "@material/textfield"], function (_exports, _component, _object, _helptext, _textfield) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    strings: {
      ROLE,
      ARIA_HIDDEN
    }
  } = _textfield.MDCTextfieldFoundation;
  const mdcAttrs = [ROLE, ARIA_HIDDEN];
  const MDCTextfieldHelptextComponent = _component.default.extend({
    //region Attributes
    /**
     * @type {String[][]}
     */
    'attribute-pairs': null,
    //endregion

    //region Ember Hooks
    layout: _helptext.default,
    tagName: 'p',
    classNames: Object.freeze(['mdc-textfield-helptext']),
    classNameBindings: Object.freeze(['class-names']),
    attributeBindings: mdcAttrs
    //endregion
  });

  mdcAttrs.forEach(attr => {
    MDCTextfieldHelptextComponent.reopen({
      [attr]: (0, _object.computed)('attribute-pairs.[]', function () {
        const found = (0, _object.get)(this, 'attribute-pairs').find(x => x[0] === attr);
        if (!found) {
          return null;
        }
        return found[1];
      })
    });
  });
  var _default = MDCTextfieldHelptextComponent;
  _exports.default = _default;
});