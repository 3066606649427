define("ember-material-components-web/components/mdc-tab-bar-scroller", ["exports", "@ember/array", "@ember/component", "@ember/runloop", "@ember/object", "@material/tabs", "@material/animation", "ember-material-components-web/mixins/mdc-component", "ember-material-components-web/templates/components/mdc-tab-bar-scroller", "ember-material-components-web/utils/get-element-property", "ember-material-components-web/utils/get-component-property", "ember-material-components-web/utils/style-computed"], function (_exports, _array, _component, _runloop, _object, _tabs, _animation, _mdcComponent, _mdcTabBarScroller, _getElementProperty, _getComponentProperty, _styleComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */

  const {
    cssClasses,
    strings
  } = _tabs.MDCTabBarScrollerFoundation;
  var _default = _component.default.extend(_mdcComponent.MDCComponent, {
    //region Attributes
    /**
     * Pass as true for white text on a darker background
     * @type {Boolean}
     */
    dark: false,
    //endregion

    //region Ember Hooks
    classNames: Object.freeze(['mdc-tab-bar-scroller']),
    classNameBindings: Object.freeze(['dark:mdc-theme--dark']),
    layout: _mdcTabBarScroller.default,
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'backIndicatorClasses', (0, _array.A)([]));
      (0, _object.set)(this, 'forwardIndicatorClasses', (0, _array.A)([]));
      (0, _object.set)(this, 'mdcScrollFrameStyles', {});
    },
    didInsertElement() {
      this._super(...arguments);
      (0, _object.set)(this, 'scrollFrameElement', (0, _getElementProperty.default)(this, 'querySelector', () => null)(strings.FRAME_SELECTOR));
      (0, _object.set)(this, 'tabBarElement', (0, _getElementProperty.default)(this, 'querySelector', () => null)(strings.TABS_SELECTOR));
      (0, _object.set)(this, 'forwardIndicatorElement', (0, _getElementProperty.default)(this, 'querySelector', () => null)(strings.INDICATOR_FORWARD_SELECTOR));
      (0, _object.set)(this, 'backIndicatorElement', (0, _getElementProperty.default)(this, 'querySelector', () => null)(strings.INDICATOR_BACK_SELECTOR));
    },
    //endregion

    //region Properties
    /**
     * @type {Object}
     */
    CLASS_NAMES: cssClasses,
    /**
     * @property {HTMLElement}
     */
    scrollFrameElement: null,
    /**
     * @property {HTMLElement}
     */
    tabBarElement: null,
    /**
     * @property {HTMLElement}
     */
    forwardIndicatorElement: null,
    /**
     * @property {HTMLElement}
     */
    backIndicatorElement: null,
    /**
     * @type {String[]}
     */
    backIndicatorClasses: null,
    /**
     * @type {String[]}
     */
    forwardIndicatorClasses: null,
    /**
     * @type {Ember.Component}
     */
    'tab-bar': null,
    /**
     * Key value pairs for CSS styles
     * @type {Object}
     */
    mdcScrollFrameStyles: null,
    //endregion

    //region Methods
    createFoundation() {
      return new _tabs.MDCTabBarScrollerFoundation({
        addClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').addObject(className)),
        removeClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').removeObject(className)),
        eventTargetHasClass: (target, className) => target.classList.contains(className),
        addClassToForwardIndicator: className => (0, _runloop.run)(() => (0, _object.get)(this, 'forwardIndicatorClasses').addObject(className)),
        removeClassFromForwardIndicator: className => (0, _runloop.run)(() => (0, _object.get)(this, 'forwardIndicatorClasses').removeObject(className)),
        addClassToBackIndicator: className => (0, _runloop.run)(() => (0, _object.get)(this, 'backIndicatorClasses').addObject(className)),
        removeClassFromBackIndicator: className => (0, _runloop.run)(() => (0, _object.get)(this, 'backIndicatorClasses').removeObject(className)),
        isRTL: () => !!this && !(0, _object.get)(this, 'isDestroyed') && !!(0, _object.get)(this, 'element') && getComputedStyle((0, _object.get)(this, 'element')).getPropertyValue('direction') === 'rtl',
        registerBackIndicatorClickHandler: handler => (0, _object.get)(this, 'backIndicatorElement').addEventListener('click', handler),
        deregisterBackIndicatorClickHandler: handler => (0, _object.get)(this, 'backIndicatorElement').removeEventListener('click', handler),
        registerForwardIndicatorClickHandler: handler => (0, _object.get)(this, 'forwardIndicatorElement').addEventListener('click', handler),
        deregisterForwardIndicatorClickHandler: handler => (0, _object.get)(this, 'forwardIndicatorElement').removeEventListener('click', handler),
        registerCapturedInteractionHandler: (evt, handler) => (0, _object.get)(this, 'element').addEventListener(evt, handler, true),
        deregisterCapturedInteractionHandler: (evt, handler) => (0, _object.get)(this, 'element').removeEventListener(evt, handler, true),
        registerWindowResizeHandler: handler => window.addEventListener('resize', handler),
        deregisterWindowResizeHandler: handler => window.removeEventListener('resize', handler),
        getNumberOfTabs: () => (0, _object.get)(this, 'tab-bar.tabs.length'),
        getComputedWidthForTabAtIndex: index => (0, _getComponentProperty.default)((0, _object.get)(this, 'tab-bar').tabAt(index), 'computedWidth', 0),
        getComputedLeftForTabAtIndex: index => (0, _getComponentProperty.default)((0, _object.get)(this, 'tab-bar').tabAt(index), 'computedLeft', 0),
        getOffsetWidthForScrollFrame: () => (0, _object.get)(this, 'scrollFrameElement').offsetWidth,
        getScrollLeftForScrollFrame: () => (0, _object.get)(this, 'scrollFrameElement').scrollLeft,
        setScrollLeftForScrollFrame: scrollLeftAmount => (0, _object.get)(this, 'scrollFrameElement').scrollLeft = scrollLeftAmount,
        getOffsetWidthForTabBar: () => (0, _object.get)(this, 'tabBarElement').offsetWidth,
        setTransformStyleForTabBar: value => {
          (0, _runloop.run)(() => this.setStyleFor('mdcScrollFrameStyles', (0, _animation.getCorrectPropertyName)(window, 'transform'), value));
        },
        getOffsetLeftForEventTarget: target => target.offsetLeft,
        getOffsetWidthForEventTarget: target => target.offsetWidth
      });
    },
    //endregion

    //region Computed Properties
    scrollFrameStyles: (0, _styleComputed.default)('mdcScrollFrameStyles'),
    /**
     * @returns {String}
     */
    backIndicatorClassNames: (0, _object.computed)('backIndicatorClasses.[]', function () {
      return (0, _object.get)(this, 'backIndicatorClasses').join(' ');
    }),
    /**
     * @returns {String}
     */
    forwardIndicatorClassNames: (0, _object.computed)('forwardIndicatorClasses.[]', function () {
      return (0, _object.get)(this, 'forwardIndicatorClasses').join(' ');
    }),
    //endregion

    //region Actions
    actions: {
      registerTabBar(tabBar) {
        (0, _object.set)(this, 'tab-bar', tabBar);
      },
      deregisterTabBar() {
        (0, _object.set)(this, 'tab-bar', null);
      },
      scrollActiveTabIntoView(index) {
        // TODO: Need to submit an issue asking Google to make a scroll method that only triggers if tab is hidden, must do this until then

        const {
          left: scrollerLeftBoundary,
          right: scrollerRightBoundary
        } = this.element.querySelector('.mdc-tab-bar-scroller__scroll-frame').getBoundingClientRect();
        const {
          left: tabLeftBoundary,
          right: tabRightBoundary
        } = (0, _object.get)(this, 'tab-bar').tabAt(index).element.getBoundingClientRect();
        if (tabRightBoundary > scrollerRightBoundary || tabLeftBoundary < scrollerLeftBoundary) {
          (0, _object.get)(this, 'foundation').scrollToTabAtIndex(index);
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});