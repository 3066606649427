define("ember-material-components-web/components/mdc-switch", ["exports", "@ember/component", "@ember/object", "ember-material-components-web/templates/components/mdc-switch", "ember-material-components-web/mixins/supports-bubbles-false"], function (_exports, _component, _object, _mdcSwitch, _supportsBubblesFalse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  var _default = _component.default.extend(_supportsBubblesFalse.default, {
    //region Attributes
    /**
     * This property is considered read-only by the component, and will not be
     * updated by user action. Please see `onchange` to handle user actions.
     * @type {Boolean}
     */
    checked: false,
    /**
     * @type {Boolean}
     */
    disabled: false,
    /**
     * This will be called when the user indicates they want to change the value
     * of the switch. If you want to simulate two-way binding, you can use the
     * switch like this:
     *
     * {{mdc-switch checked=(eq switchValue "one") onchange=(action "setSwitchValue" "one")}}
     *
     * @type {Function}
     * @param {Boolean} checked
     */
    onchange: x => x,
    /**
     * @type {?String}
     */
    'input-id': null,
    //endregion

    //region Ember Hooks
    layout: _mdcSwitch.default,
    classNames: 'mdc-switch',
    classNameBindings: Object.freeze(['disabled:mdc-switch--disabled']),
    attributeBindings: Object.freeze(['disabled']),
    //endregion

    //region Actions
    actions: {
      inputChanged(ev) {
        const checked = ev.target.checked;
        (0, _object.get)(this, 'onchange')(checked);
      }
    }
    //endregion
  });
  _exports.default = _default;
});