define("ember-material-components-web/templates/components/mdc-tab-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    tab=(component
      (ensure-safe-component (if this.links (component "mdc-tab-bar/tab-link") (component "mdc-tab-bar/tab")))
      has-icon-and-text=this.isIconsWithText
      tab-selected=(action "tabSelected")
      register-tab=(action "registerTab")
      deregister-tab=(action "deregisterTab")
      switch-to-tab=(action "switchToTab")
      scroll-into-view=(action "scrollTabIntoView")
    )
  )}}
  {{mdc-tab-bar/indicator class=this.additional-indicator-classes style=this.indicatorStyle}}
  
  */
  {
    "id": "6iBCwPfn",
    "block": "[[[18,1,[[28,[37,1],null,[[\"tab\"],[[50,[28,[37,3],[[52,[30,0,[\"links\"]],[50,\"mdc-tab-bar/tab-link\",0,null,null],[50,\"mdc-tab-bar/tab\",0,null,null]]],null],0,null,[[\"has-icon-and-text\",\"tab-selected\",\"register-tab\",\"deregister-tab\",\"switch-to-tab\",\"scroll-into-view\"],[[30,0,[\"isIconsWithText\"]],[28,[37,5],[[30,0],\"tabSelected\"],null],[28,[37,5],[[30,0],\"registerTab\"],null],[28,[37,5],[[30,0],\"deregisterTab\"],null],[28,[37,5],[[30,0],\"switchToTab\"],null],[28,[37,5],[[30,0],\"scrollTabIntoView\"],null]]]]]]]]],[1,\"\\n\"],[1,[28,[35,6],null,[[\"class\",\"style\"],[[30,0,[\"additional-indicator-classes\"]],[30,0,[\"indicatorStyle\"]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"ensure-safe-component\",\"if\",\"action\",\"mdc-tab-bar/indicator\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-tab-bar.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});