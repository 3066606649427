define("ember-cli-ss-enums/services/enums", ["exports", "@ember/string", "@ember/utils", "@ember/service", "@ember/object", "ember-cli-ss-enums/utils/enum-data", "lodash/isMatch"], function (_exports, _string, _utils, _service, _object, _enumData, _isMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
   * Find an enumerated value based on its properties, and return another of its properties.
   * @param {String} enumName - The name of the enum to look up (e.g. 'PAGE_TYPE')
   * @param {Object} obj - The property/value pairs to look up (e.g. { name: 'Rules' })
   * @param {String|Object} [extract='id'] - The value to extract from the enum. Defaults to 'id'. If you want the entire object, pass an empty object.
   * @returns {String|Object?} - Returns null if it could not be found, otherwise returns the value (or enum object if requested).
   */
  const findEnumWhere = function (enumName, obj) {
    let extract = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "id";
    let enumValue = null;
    const enumArray = _enumData.default[(0, _string.underscore)(enumName).toUpperCase()];
    if (enumArray) {
      const found = enumArray.find(item => (0, _isMatch.default)(item, obj));
      if (found) {
        enumValue = (0, _utils.typeOf)(extract) === "object" ? found : found[extract];
      }
    }
    return enumValue;
  };
  class Enums extends _service.default {
    constructor() {
      super(...arguments);
      /**
       * The data that enumerates the enumerated enumerables enums
       */
      _defineProperty(this, "data", _enumData.default);
    }
    findWhere() {
      return findEnumWhere(...arguments);
    }
    static findWhere() {
      return findEnumWhere(...arguments);
    }
    /**
     * Enumerated Computed Property
     * This generates a function that creates a computed property based on Promotion.ENUMS.
     * NOTE: You must inject the enums service into any object that uses the enums computed.
     * @method enumObserverFactory
     * @memberOf Promotion
     * @param {String} property - The enum property to use
     * @param {String} idName - The model property that holds the enum ID
     * @param {String} [modelProperty] - The model property that holds the value. If omitted, it is guessed based on
     *                                   idName by replacing "Id" with the Pascal-ized version of property.
     * @param {String} [whichEnum] - Explicitly indicate which Enum to use. Instead of determining
     *                               it based on other param values
     *
     * @example
     * // Promotion.ENUMS = {
     * //     BLAH_TYPE: [
     * //         { id: 1, name: 'Hello'},
     * //         { id: 2, name: 'World'}
     * //     ]
     * // };
     * // Promotion.Model = DS.Model.extend({
     * //     blahTypeId: DS.attr('number'),
     * //     blahTypeName: enumObserverFactory('name', 'blahTypeId')
     * // });
     * // var m = new Promotion.Model({ blahTypeId: 2 });
     * // m.get('blahTypeName') === _(Promotion.ENUMS.BLAH_TYPE).findWhere({ id: m.get('blahTypeId') }).name //=> true
     * @returns {Function} - A function that can be used as a computed property in an Ember model or controller
     */
    static computed(property, idName, modelProperty, whichEnum) {
      if (modelProperty === null || modelProperty === undefined) {
        // property = blah; idName = somethingTypeId; => somethingTypeBlah
        modelProperty = idName.replace(/Id$/, property.charAt(0).toUpperCase() + property.slice(1));
      }
      if (!whichEnum) {
        whichEnum = (0, _string.underscore)(idName).toUpperCase().replace(/_ID$/, ""); // somethingTypeId => SOMETHING_TYPE
      }

      return (0, _object.computed)(idName, {
        get() {
          return findEnumWhere(whichEnum, {
            id: this[idName]
          }, property);
        },
        set(_, value) {
          const findWhereObj = {};
          findWhereObj[property] = value;
          (0, _object.set)(this, modelProperty, findEnumWhere(whichEnum, findWhereObj));
          return value;
        }
      });
    }
  }
  _exports.default = Enums;
});