define("ember-material-components-web/components/mdc-menu-anchor", ["exports", "@ember/component", "@ember/object", "ember-material-components-web/templates/components/mdc-menu-anchor"], function (_exports, _component, _object, _mdcMenuAnchor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Ember Hooks
    layout: _mdcMenuAnchor.default,
    classNames: Object.freeze(['mdc-menu-anchor']),
    //endregion

    //region Methods
    /**
     * @public
     * @returns {DOMRect|null}
     */
    getAnchorDimensions() {
      const el = (0, _object.get)(this, 'element');
      if (!el) {
        return null;
      }
      return el.getBoundingClientRect();
    }
    //endregion
  });
  _exports.default = _default;
});