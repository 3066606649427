define("ember-material-components-web/components/mdc-form-field", ["exports", "@ember/component", "ember-material-components-web/templates/components/mdc-form-field"], function (_exports, _component, _mdcFormField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Attributes
    /**
     * @type {Boolean}
     */
    'align-end': false,
    //endregion
    //region Ember hooks
    layout: _mdcFormField.default,
    classNames: Object.freeze(['mdc-form-field']),
    classNameBindings: Object.freeze(['align-end:mdc-form-field--align-end'])
    //endregion
  });
  _exports.default = _default;
});