define("ember-cli-ss-enums/utils/enum-data/template-element-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Dips',
    editableByUser: false
  }, {
    id: 2,
    name: 'Background Image',
    editableByUser: true,
    usesMediaItemId: true,
    usesFileUpload: true,
    sortOrder: 1
  }, {
    id: 3,
    name: 'Color Selection',
    colorPicker: true,
    editableByUser: true,
    sortOrder: 7
  }, {
    id: 4,
    name: 'Call to Action Text',
    html: true,
    editableByUser: true,
    sortOrder: 3
  }, {
    id: 5,
    name: 'Prize and Details Text',
    html: true,
    editableByUser: true,
    sortOrder: 4
  }, {
    id: 6,
    name: 'Header Text',
    html: true,
    editableByUser: true,
    sortOrder: 2
  }, {
    id: 7,
    name: 'Sponsor Logo',
    editableByUser: true,
    usesMediaItemId: true,
    usesFileUpload: true,
    sortOrder: 5
  }, {
    id: 8,
    name: 'Sponsor Homepage',
    editableByUser: true,
    sortOrder: 6
  }, {
    id: 9,
    name: 'Application',
    html: true,
    editableByUser: false
  }, {
    id: 10,
    name: 'Additional CSS',
    plainTextArea: true,
    editableByUser: true,
    sortOrder: 8
  }, {
    id: 13,
    name: 'Audience Count',
    sortOrder: 1,
    editableByUser: true,
    usesJson: true
  }, {
    id: 14,
    name: 'Tags Chart',
    sortOrder: 2,
    editableByUser: true,
    usesJson: true
  }, {
    id: 15,
    name: 'Location Chart',
    sortOrder: 3,
    editableByUser: true,
    usesJson: true
  }, {
    id: 16,
    name: 'Age Chart',
    sortOrder: 4,
    editableByUser: true,
    usesJson: true
  }, {
    id: 17,
    name: 'Gender Chart',
    sortOrder: 5,
    editableByUser: true,
    usesJson: true
  }, {
    id: 18,
    name: 'Sales Message Header',
    sortOrder: 1,
    editableByUser: true,
    html: true
  }, {
    id: 19,
    name: 'Sales Message Body',
    sortOrder: 2,
    html: true,
    editableByUser: true
  }, {
    id: 20,
    name: 'Contact Name',
    sortOrder: 4,
    editableByUser: true
  }, {
    id: 21,
    name: 'Contact Title',
    sortOrder: 5,
    editableByUser: true
  }, {
    id: 22,
    name: 'Contact Phone',
    sortOrder: 7,
    editableByUser: true
  }, {
    id: 23,
    name: 'Contact Image',
    sortOrder: 3,
    editableByUser: true,
    usesMediaItemId: true,
    usesFileUpload: true,
    helpText: 'Recommended File Type: Transparent PNG (.jpg, .jpeg, .png and .gif accepted)'
  }, {
    id: 27,
    name: 'Contact Email',
    sortOrder: 6,
    editableByUser: true
  }, {
    id: 24,
    name: 'Main Image',
    sortOrder: 1,
    editableByUser: true,
    usesMediaItemId: true,
    usesFileUpload: true,
    helpText: 'Recommended Minimum Image Resolution: 750px by 320px (.jpg, .jpeg, .png and .gif accepted)'
  }, {
    id: 26,
    name: 'Main Logo',
    sortOrder: 2,
    editableByUser: true,
    usesMediaItemId: true,
    usesFileUpload: true,
    helpText: 'Recommended File Type: Transparent PNG (.jpg, .jpeg, .png and .gif accepted)'
  }, {
    id: 25,
    name: 'Color',
    sortOrder: 3,
    editableByUser: true,
    colorPicker: true,
    alwaysEnabled: true
  }];
  _exports.default = _default;
});