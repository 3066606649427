define("ember-cli-ss-enums/utils/enum-data/checklist-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Sweepstakes',
    promotionTypeId: 5
  }, {
    id: 2,
    name: 'Personality Quiz',
    promotionTypeId: 6
  }, {
    id: 3,
    name: 'UGC Sweepstakes',
    promotionTypeId: 8
  }, {
    id: 4,
    name: 'Survey',
    promotionTypeId: 7
  }, {
    id: 9,
    name: 'Photo Voting',
    promotionTypeId: 3
  }, {
    id: 10,
    name: 'Photo Gallery',
    promotionTypeId: 10
  }, {
    id: 12,
    name: 'Ballot',
    promotionTypeId: 11
  }, {
    id: 13,
    name: 'Poll',
    promotionTypeId: 12
  }, {
    id: 17,
    name: 'Voting Bracket',
    promotionTypeId: 14
  }, {
    id: 18,
    name: 'Event Signup',
    promotionTypeId: 15
  }];
  _exports.default = _default;
});