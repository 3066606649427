define("ember-material-components-web/templates/components/mdc-toolbar/row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    section=(component "mdc-toolbar/section" title-style=this.title-style)
  )}}
  
  */
  {
    "id": "ClhyocaF",
    "block": "[[[18,1,[[28,[37,1],null,[[\"section\"],[[50,\"mdc-toolbar/section\",0,null,[[\"title-style\"],[[30,0,[\"title-style\"]]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-toolbar/row.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});