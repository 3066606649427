define("ember-cli-ss-enums/utils/enum-data/field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 37,
    name: 'Gender',
    fieldTypeId: 1
  }, {
    id: 39,
    name: 'Email',
    fieldTypeId: 23
  }, {
    id: 57,
    name: 'Phone',
    fieldTypeId: 3
  }, {
    id: 26,
    name: 'PhotoTitle',
    fieldTypeId: 3
  }, {
    id: 27,
    name: 'PhotoCaption',
    fieldTypeId: 4
  }, {
    id: 43,
    name: 'PostalCode',
    fieldTypeId: 3
  }, {
    id: 58,
    name: 'State',
    fieldTypeId: 1
  }, {
    id: 132,
    name: 'MediaReleaseConsent',
    fieldTypeId: 21
  }, {
    id: 24,
    name: 'PhotoEntryUpload',
    fieldTypeId: 13
  }, {
    id: 133,
    name: 'VideoEntryUpload',
    fieldTypeId: 14
  }];
  _exports.default = _default;
});