define("ember-cli-ss-enums/utils/enum-data/entry-interval-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: 'None Specified'
  }, {
    id: 1,
    name: 'Round' // Same as "Contest Duration" on server-side
  }, {
    id: 2,
    name: 'Hour'
  }, {
    id: 3,
    name: 'Day'
  }, {
    id: 4,
    name: 'Week'
  }];
  _exports.default = _default;
});