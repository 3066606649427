define("ember-cli-ss-enums/utils/enum-data/dns-verification-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: 'None'
  }, {
    id: 1,
    name: 'Pending'
  }, {
    id: 2,
    name: 'Failed'
  }, {
    id: 3,
    name: 'Verified'
  }];
  _exports.default = _default;
});