define("ember-cli-ss-enums/utils/enum-data/value-data-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'String'
  }, {
    id: 2,
    name: 'Integer'
  }, {
    id: 3,
    name: 'Long'
  }, {
    id: 4,
    name: 'Decimal'
  }, {
    id: 5,
    name: 'DateTime'
  }, {
    id: 6,
    name: 'Boolean'
  }, {
    id: 7,
    name: 'Complex'
  }];
  _exports.default = _default;
});