define("ember-material-components-web/components/mdc-card", ["exports", "@ember/component", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-card"], function (_exports, _component, _globalEventHandlers, _mdcCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Attributes
    /**
     * @type {Boolean}
     */
    'horizontal-block': false,
    //endregion

    //region Ember Hooks
    layout: _mdcCard.default,
    classNames: Object.freeze(['mdc-card']),
    classNameBindings: Object.freeze(['horizontal-block:mdc-card__horizontal-block']),
    attributeBindings: Object.freeze([..._globalEventHandlers.default])
    //endregion
  });
  _exports.default = _default;
});