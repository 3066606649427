define("ember-cli-ss-enums/utils/enum-data/source-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: 'NoneSpecified'
  }, {
    id: 1,
    name: 'UserFields'
  }, {
    id: 2,
    name: 'Outcomes'
  }, {
    id: 3,
    name: 'Picks'
  }, {
    id: 4,
    name: 'TopTags'
  }, {
    id: 5,
    name: 'Tags'
  }, {
    id: 6,
    name: 'SubmissionFields'
  }, {
    id: 7,
    name: 'MessageVersionTests'
  }, {
    id: 8,
    name: 'MessageVersionLinks'
  }, {
    id: 9,
    name: 'OptOuts'
  }, {
    id: 10,
    name: 'BounceBacks'
  }, {
    id: 11,
    name: 'OpenedTimeSpans'
  }, {
    id: 12,
    name: 'Domains'
  }, {
    id: 13,
    name: 'BrowserDeviceCategories'
  }];
  _exports.default = _default;
});