define("ember-material-components-web/utils/get-element-property", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Safely attempts to read a property from the component's DOM element,
   * or returns the fallback if the element or component aren't around.
   * @param {Ember.Component} component
   * @param {String} property
   * @param {*} [fallback]
   * @returns {*}
   */
  var _default = function _default(component, property) {
    let fallback = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    if (!component || (0, _object.get)(component, 'isDestroyed') || !(0, _object.get)(component, 'element')) {
      return fallback;
    }
    const element = (0, _object.get)(component, 'element');
    const value = (0, _object.get)(element, property);
    if (typeof value === 'function') {
      return value.bind(element);
    }
    return value;
  };
  _exports.default = _default;
});