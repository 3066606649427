define("ember-cli-ss-enums/utils/enum-data/messaging-statistics-level-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'MessageCampaign'
  }, {
    id: 2,
    name: 'Message'
  }, {
    id: 3,
    name: 'MessageVersion'
  }, {
    id: 4,
    name: 'Schedule'
  }, {
    id: 5,
    name: 'ScheduleInstance'
  }, {
    id: 6,
    name: 'MessageVersionHistory'
  }];
  _exports.default = _default;
});