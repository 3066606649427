define("ember-cli-ss-enums/utils/enum-data/report-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'RegisteredUsers',
    public: 'People'
  }, {
    id: 2,
    name: 'SweepstakesEntries',
    public: 'Entries'
  }, {
    id: 3,
    name: 'QuizEntryPicks',
    public: 'Submissions'
  },
  // Public property not needed for Audience Members
  // because their charts don't use report-data-table.js
  {
    id: 4,
    name: 'AudienceContacts'
  }, {
    id: 5,
    name: 'SurveyResponses',
    public: 'Responses'
  }, {
    id: 6,
    name: 'SubscribedUsers',
    public: 'Opt-ins'
  }, {
    id: 7,
    name: 'Winners',
    public: 'Winners'
  }, {
    id: 8,
    name: 'Votes',
    public: 'Votes'
  }, {
    id: 9,
    name: 'Entrants',
    public: 'Entrants'
  },
  // Public property not needed for Email and Optin Members
  // because their charts don't use report-data-table.js
  {
    id: 10,
    name: 'EmailLinkClicks'
  }, {
    id: 11,
    name: 'EmailOpens'
  }, {
    id: 12,
    name: 'EmailUnsubscribes'
  }, {
    id: 13,
    name: 'EmailDeliverability'
  }, {
    id: 14,
    name: 'MessagingStatisticsEmails'
  }, {
    id: 15,
    name: 'MessagingStatisticsOrganizations'
  }, {
    id: 16,
    name: 'OptinMembers'
  }, {
    id: 17,
    name: 'Signups',
    public: 'Sign-ups'
  }, {
    id: 18,
    name: 'WinningEntrants',
    public: 'Winners'
  }, {
    id: 19,
    name: 'PeopleFraud',
    public: 'People Fraud'
  }, {
    id: 20,
    name: 'EntryFraud',
    public: 'Entry Fraud'
  }, {
    id: 21,
    name: 'UserProfile',
    public: 'User Profile'
  }, {
    id: 22,
    name: 'Recipient'
  }, {
    id: 23,
    name: 'BallotSchema',
    public: 'Ballot Schema'
  }, {
    id: 24,
    name: 'NominationsPerCategory',
    public: 'Nominations Per Category'
  }, {
    id: 25,
    name: 'VotesPerCategory',
    public: 'Votes Per Category'
  }, {
    id: 26,
    name: 'OrganizationPerformance'
  }, {
    id: 27,
    name: 'PromotionPerformance'
  }, {
    id: 28,
    name: 'OrganizationPromotionPerformance'
  }, {
    id: 29,
    name: 'MessagingStatisticsDaily'
  }, {
    id: 30,
    name: 'WrapperImport'
  }, {
    id: 31,
    name: 'PromotionCopies',
    public: 'Promotion Copies'
  }, {
    id: 32,
    name: 'EntriesAllCopies',
    public: 'Entries'
  }, {
    id: 33,
    name: 'RegisteredUsersAllCopies',
    public: 'People'
  }];
  _exports.default = _default;
});