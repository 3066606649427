define("ember-cli-ss-enums/utils/enum-data/status-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Active'
  }, {
    id: 2,
    name: 'InActive'
  }, {
    id: 3,
    name: 'Deleted'
  }, {
    id: 4,
    name: 'Submitted'
  }, {
    id: 5,
    name: 'Hidden'
  }, {
    id: 6,
    name: 'Rejected'
  }, {
    id: 7,
    name: 'Cancelled'
  }, {
    id: 8,
    name: 'Disqualified'
  }, {
    id: 9,
    name: 'Pending'
  }];
  _exports.default = _default;
});