define("ember-material-components-web/templates/components/mdc-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <input type="checkbox" onclick={{action "clicked"}} id={{this.input-id}} name={{this.name}} onchange={{action "inputChanged"}} checked={{this.checked}} class="mdc-checkbox__native-control">
  <div class="mdc-checkbox__background">
    <svg version="1.1"
      class="mdc-checkbox__checkmark"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24">
      <path class="mdc-checkbox__checkmark__path"
        fill="none"
        stroke="white"
        d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
    </svg>
    <div class="mdc-checkbox__mixedmark"></div>
  </div>
  
  */
  {
    "id": "YehXinin",
    "block": "[[[10,\"input\"],[15,\"onclick\",[28,[37,0],[[30,0],\"clicked\"],null]],[15,1,[30,0,[\"input-id\"]]],[15,3,[30,0,[\"name\"]]],[15,\"onchange\",[28,[37,0],[[30,0],\"inputChanged\"],null]],[15,\"checked\",[30,0,[\"checked\"]]],[14,0,\"mdc-checkbox__native-control\"],[14,4,\"checkbox\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"mdc-checkbox__background\"],[12],[1,\"\\n  \"],[10,\"svg\"],[14,\"version\",\"1.1\"],[14,0,\"mdc-checkbox__checkmark\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"viewBox\",\"0 0 24 24\"],[12],[1,\"\\n    \"],[10,\"path\"],[14,0,\"mdc-checkbox__checkmark__path\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"white\"],[14,\"d\",\"M1.73,12.91 8.1,19.28 22.79,4.59\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mdc-checkbox__mixedmark\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-checkbox.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});