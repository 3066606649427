define("ember-material-components-web/components/mdc-tab-bar/tab-link", ["exports", "@ember/legacy-built-in-components", "@ember/object", "ember-material-components-web/mixins/mdc-tab-component"], function (_exports, _legacyBuiltInComponents, _object, _mdcTabComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  /* eslint-disable ember/no-observers */
  var _default = _legacyBuiltInComponents.LinkComponent.extend(_mdcTabComponent.default, {
    //region Ember Hooks
    activeClass: 'mdc-tab--active',
    didInsertElement() {
      this._super(...arguments);
      if ((0, _object.get)(this, 'active')) {
        (0, _object.get)(this, 'scroll-into-view')(this);
      }
    },
    //endregion

    //region Observers
    activeStateChanged: (0, _object.observer)('active', function () {
      if ((0, _object.get)(this, 'active')) {
        (0, _object.get)(this, 'switch-to-tab')(this);
      }
    })
    //endregion
  });
  _exports.default = _default;
});