define("ember-material-components-web/components/mdc-grid-list/tiles/tile/primary/primary-content", ["exports", "@ember/component", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-grid-list/tiles/tile/primary/primary-content"], function (_exports, _component, _globalEventHandlers, _primaryContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Ember Hooks
    layout: _primaryContent.default,
    tagName: 'img',
    classNames: Object.freeze(['mdc-grid-tile__primary-content']),
    attributeBindings: Object.freeze(['alt', 'height', 'src', 'ismap', 'longdesc', 'referrerpolicy', 'sizes', 'srcset', 'usemap', 'width', ..._globalEventHandlers.default])
    //endregion
  });
  _exports.default = _default;
});