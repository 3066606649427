define("ember-cli-ss-enums/utils/enum-data/time-zones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    description: 'Dateline Standard Time',
    offset: -12,
    name: '(UTC-12:00) International Date Line West',
    displayName: 'Dateline Time'
  }, {
    id: 2,
    description: 'UTC-11',
    offset: -11,
    name: '(UTC-11:00) Coordinated Universal Time-11',
    displayName: 'UTC-11'
  }, {
    id: 3,
    description: 'Hawaiian Standard Time',
    offset: -10,
    name: '(UTC-10:00) Hawaii',
    displayName: 'Hawaiian Time'
  }, {
    id: 4,
    description: 'Alaskan Standard Time',
    offset: -9,
    name: '(UTC-09:00) Alaska',
    displayName: 'Alaskan Time'
  }, {
    id: 5,
    description: 'Pacific Standard Time (Mexico)',
    offset: -8,
    name: '(UTC-08:00) Baja California',
    displayName: 'Pacific Time (Mexico)'
  }, {
    id: 6,
    description: 'Pacific Standard Time',
    offset: -8,
    name: '(UTC-08:00) Pacific Time (US & Canada)',
    displayName: 'Pacific Time'
  }, {
    id: 7,
    description: 'US Mountain Standard Time',
    offset: -7,
    name: '(UTC-07:00) Arizona',
    displayName: 'US Mountain Time'
  }, {
    id: 8,
    description: 'Mountain Standard Time (Mexico)',
    offset: -7,
    name: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
    displayName: 'Mountain Time (Mexico)'
  }, {
    id: 9,
    description: 'Mountain Standard Time',
    offset: -7,
    name: '(UTC-07:00) Mountain Time (US & Canada)',
    displayName: 'Mountain Time'
  }, {
    id: 10,
    description: 'Central America Standard Time',
    offset: -6,
    name: '(UTC-06:00) Central America',
    displayName: 'Central America Time'
  }, {
    id: 11,
    description: 'Central Standard Time',
    offset: -6,
    name: '(UTC-06:00) Central Time (US & Canada)',
    displayName: 'Central Time'
  }, {
    id: 12,
    description: 'Central Standard Time (Mexico)',
    offset: -6,
    name: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
    displayName: 'Central Time (Mexico)'
  }, {
    id: 13,
    description: 'Canada Central Standard Time',
    offset: -6,
    name: '(UTC-06:00) Saskatchewan',
    displayName: 'Canada Central Time'
  }, {
    id: 14,
    description: 'SA Pacific Standard Time',
    offset: -5,
    name: '(UTC-05:00) Bogota, Lima, Quito',
    displayName: 'SA Pacific Time'
  }, {
    id: 15,
    description: 'Eastern Standard Time',
    offset: -5,
    name: '(UTC-05:00) Eastern Time (US & Canada)',
    displayName: 'Eastern Time'
  }, {
    id: 16,
    description: 'US Eastern Standard Time',
    offset: -5,
    name: '(UTC-05:00) Indiana (East)',
    displayName: 'US Eastern Time'
  }, {
    id: 17,
    description: 'Venezuela Standard Time',
    offset: -4,
    name: '(UTC-04:30) Caracas',
    displayName: 'Venezuela Time'
  }, {
    id: 18,
    description: 'Paraguay Standard Time',
    offset: -4,
    name: '(UTC-04:00) Asuncion',
    displayName: 'Paraguay Time'
  }, {
    id: 19,
    description: 'Atlantic Standard Time',
    offset: -4,
    name: '(UTC-04:00) Atlantic Time (Canada)',
    displayName: 'Atlantic Time'
  }, {
    id: 20,
    description: 'Central Brazilian Standard Time',
    offset: -4,
    name: '(UTC-04:00) Cuiaba',
    displayName: 'Central Brazilian Time'
  }, {
    id: 21,
    description: 'SA Western Standard Time',
    offset: -4,
    name: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
    displayName: 'SA Western Time'
  }, {
    id: 22,
    description: 'Pacific SA Standard Time',
    offset: -4,
    name: '(UTC-04:00) Santiago',
    displayName: 'Pacific SA Time'
  }, {
    id: 23,
    description: 'Newfoundland Standard Time',
    offset: -3,
    name: '(UTC-03:30) Newfoundland',
    displayName: 'Newfoundland Time'
  }, {
    id: 24,
    description: 'E. South America Standard Time',
    offset: -3,
    name: '(UTC-03:00) Brasilia',
    displayName: 'E. South America Time'
  }, {
    id: 25,
    description: 'Argentina Standard Time',
    offset: -3,
    name: '(UTC-03:00) Buenos Aires',
    displayName: 'Argentina Time'
  }, {
    id: 26,
    description: 'SA Eastern Standard Time',
    offset: -3,
    name: '(UTC-03:00) Cayenne, Fortaleza',
    displayName: 'SA Eastern Time'
  }, {
    id: 27,
    description: 'Greenland Standard Time',
    offset: -3,
    name: '(UTC-03:00) Greenland',
    displayName: 'Greenland Time'
  }, {
    id: 28,
    description: 'Montevideo Standard Time',
    offset: -3,
    name: '(UTC-03:00) Montevideo',
    displayName: 'Montevideo Time'
  }, {
    id: 29,
    description: 'Bahia Standard Time',
    offset: -3,
    name: '(UTC-03:00) Salvador',
    displayName: 'Bahia Time'
  }, {
    id: 30,
    description: 'UTC-02',
    offset: -2,
    name: '(UTC-02:00) Coordinated Universal Time-02',
    displayName: 'UTC-02'
  }, {
    id: 31,
    description: 'Mid-Atlantic Standard Time',
    offset: -2,
    name: '(UTC-02:00) Mid-Atlantic',
    displayName: 'Mid-Atlantic Time'
  }, {
    id: 32,
    description: 'Azores Standard Time',
    offset: -1,
    name: '(UTC-01:00) Azores',
    displayName: 'Azores Time'
  }, {
    id: 33,
    description: 'Cape Verde Standard Time',
    offset: -1,
    name: '(UTC-01:00) Cape Verde Is.',
    displayName: 'Cape Verde Time'
  }, {
    id: 34,
    description: 'Morocco Standard Time',
    offset: 0,
    name: '(UTC) Casablanca',
    displayName: 'Morocco Time'
  }, {
    id: 35,
    description: 'UTC',
    offset: 0,
    name: '(UTC) Coordinated Universal Time',
    displayName: 'UTC'
  }, {
    id: 36,
    description: 'GMT Standard Time',
    offset: 0,
    name: '(UTC) Dublin, Edinburgh, Lisbon, London',
    displayName: 'GMT Time'
  }, {
    id: 37,
    description: 'Greenwich Standard Time',
    offset: 0,
    name: '(UTC) Monrovia, Reykjavik',
    displayName: 'Greenwich Time'
  }, {
    id: 38,
    description: 'W. Europe Standard Time',
    offset: 1,
    name: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    displayName: 'W. Europe Time'
  }, {
    id: 39,
    description: 'Central Europe Standard Time',
    offset: 1,
    name: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    displayName: 'Central Europe Time'
  }, {
    id: 40,
    description: 'Romance Standard Time',
    offset: 1,
    name: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
    displayName: 'Romance Time'
  }, {
    id: 41,
    description: 'Central European Standard Time',
    offset: 1,
    name: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    displayName: 'Central European Time'
  }, {
    id: 42,
    description: 'W. Central Africa Standard Time',
    offset: 1,
    name: '(UTC+01:00) West Central Africa',
    displayName: 'W. Central Africa Time'
  }, {
    id: 43,
    description: 'Namibia Standard Time',
    offset: 1,
    name: '(UTC+01:00) Windhoek',
    displayName: 'Namibia Time'
  }, {
    id: 44,
    description: 'GTB Standard Time',
    offset: 2,
    name: '(UTC+02:00) Athens, Bucharest',
    displayName: 'GTB Time'
  }, {
    id: 45,
    description: 'Middle East Standard Time',
    offset: 2,
    name: '(UTC+02:00) Beirut',
    displayName: 'Middle East Time'
  }, {
    id: 46,
    description: 'Egypt Standard Time',
    offset: 2,
    name: '(UTC+02:00) Cairo',
    displayName: 'Egypt Time'
  }, {
    id: 47,
    description: 'Syria Standard Time',
    offset: 2,
    name: '(UTC+02:00) Damascus',
    displayName: 'Syria Time'
  }, {
    id: 48,
    description: 'E. Europe Standard Time',
    offset: 2,
    name: '(UTC+02:00) E. Europe',
    displayName: 'E. Europe Time'
  }, {
    id: 49,
    description: 'South Africa Standard Time',
    offset: 2,
    name: '(UTC+02:00) Harare, Pretoria',
    displayName: 'South Africa Time'
  }, {
    id: 50,
    description: 'FLE Standard Time',
    offset: 2,
    name: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    displayName: 'FLE Time'
  }, {
    id: 51,
    description: 'Turkey Standard Time',
    offset: 2,
    name: '(UTC+02:00) Istanbul',
    displayName: 'Turkey Time'
  }, {
    id: 52,
    description: 'Israel Standard Time',
    offset: 2,
    name: '(UTC+02:00) Jerusalem',
    displayName: 'Israel Time'
  }, {
    id: 53,
    description: 'Jordan Standard Time',
    offset: 3,
    name: '(UTC+03:00) Amman',
    displayName: 'Jordan Time'
  }, {
    id: 54,
    description: 'Arabic Standard Time',
    offset: 3,
    name: '(UTC+03:00) Baghdad',
    displayName: 'Arabic Time'
  }, {
    id: 55,
    description: 'Kaliningrad Standard Time',
    offset: 3,
    name: '(UTC+03:00) Kaliningrad, Minsk',
    displayName: 'Kaliningrad Time'
  }, {
    id: 56,
    description: 'Arab Standard Time',
    offset: 3,
    name: '(UTC+03:00) Kuwait, Riyadh',
    displayName: 'Arab Time'
  }, {
    id: 57,
    description: 'E. Africa Standard Time',
    offset: 3,
    name: '(UTC+03:00) Nairobi',
    displayName: 'E. Africa Time'
  }, {
    id: 58,
    description: 'Iran Standard Time',
    offset: 3,
    name: '(UTC+03:30) Tehran',
    displayName: 'Iran Time'
  }, {
    id: 59,
    description: 'Arabian Standard Time',
    offset: 4,
    name: '(UTC+04:00) Abu Dhabi, Muscat',
    displayName: 'Arabian Time'
  }, {
    id: 60,
    description: 'Azerbaijan Standard Time',
    offset: 4,
    name: '(UTC+04:00) Baku',
    displayName: 'Azerbaijan Time'
  }, {
    id: 61,
    description: 'Russian Standard Time',
    offset: 4,
    name: '(UTC+04:00) Moscow, St. Petersburg, Volgograd',
    displayName: 'Russian Time'
  }, {
    id: 62,
    description: 'Mauritius Standard Time',
    offset: 4,
    name: '(UTC+04:00) Port Louis',
    displayName: 'Mauritius Time'
  }, {
    id: 63,
    description: 'Georgian Standard Time',
    offset: 4,
    name: '(UTC+04:00) Tbilisi',
    displayName: 'Georgian Time'
  }, {
    id: 64,
    description: 'Caucasus Standard Time',
    offset: 4,
    name: '(UTC+04:00) Yerevan',
    displayName: 'Caucasus Time'
  }, {
    id: 65,
    description: 'Afghanistan Standard Time',
    offset: 4,
    name: '(UTC+04:30) Kabul',
    displayName: 'Afghanistan Time'
  }, {
    id: 66,
    description: 'Pakistan Standard Time',
    offset: 5,
    name: '(UTC+05:00) Islamabad, Karachi',
    displayName: 'Pakistan Time'
  }, {
    id: 67,
    description: 'West Asia Standard Time',
    offset: 5,
    name: '(UTC+05:00) Tashkent',
    displayName: 'West Asia Time'
  }, {
    id: 68,
    description: 'India Standard Time',
    offset: 5,
    name: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    displayName: 'India Time'
  }, {
    id: 69,
    description: 'Sri Lanka Standard Time',
    offset: 5,
    name: '(UTC+05:30) Sri Jayawardenepura',
    displayName: 'Sri Lanka Time'
  }, {
    id: 70,
    description: 'Nepal Standard Time',
    offset: 5,
    name: '(UTC+05:45) Kathmandu',
    displayName: 'Nepal Time'
  }, {
    id: 71,
    description: 'Central Asia Standard Time',
    offset: 6,
    name: '(UTC+06:00) Astana',
    displayName: 'Central Asia Time'
  }, {
    id: 72,
    description: 'Bangladesh Standard Time',
    offset: 6,
    name: '(UTC+06:00) Dhaka',
    displayName: 'Bangladesh Time'
  }, {
    id: 73,
    description: 'Ekaterinburg Standard Time',
    offset: 6,
    name: '(UTC+06:00) Ekaterinburg',
    displayName: 'Ekaterinburg Time'
  }, {
    id: 74,
    description: 'Myanmar Standard Time',
    offset: 6,
    name: '(UTC+06:30) Yangon (Rangoon)',
    displayName: 'Myanmar Time'
  }, {
    id: 75,
    description: 'SE Asia Standard Time',
    offset: 7,
    name: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
    displayName: 'SE Asia Time'
  }, {
    id: 76,
    description: 'N. Central Asia Standard Time',
    offset: 7,
    name: '(UTC+07:00) Novosibirsk',
    displayName: 'N. Central Asia Time'
  }, {
    id: 77,
    description: 'China Standard Time',
    offset: 8,
    name: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    displayName: 'China Time'
  }, {
    id: 78,
    description: 'North Asia Standard Time',
    offset: 8,
    name: '(UTC+08:00) Krasnoyarsk',
    displayName: 'North Asia Time'
  }, {
    id: 79,
    description: 'Singapore Standard Time',
    offset: 8,
    name: '(UTC+08:00) Kuala Lumpur, Singapore',
    displayName: 'Singapore Time'
  }, {
    id: 80,
    description: 'W. Australia Standard Time',
    offset: 8,
    name: '(UTC+08:00) Perth (Australia)',
    displayName: 'W. Australia Time'
  }, {
    id: 81,
    description: 'Taipei Standard Time',
    offset: 8,
    name: '(UTC+08:00) Taipei',
    displayName: 'Taipei Time'
  }, {
    id: 82,
    description: 'Ulaanbaatar Standard Time',
    offset: 8,
    name: '(UTC+08:00) Ulaanbaatar',
    displayName: 'Ulaanbaatar Time'
  }, {
    id: 83,
    description: 'North Asia East Standard Time',
    offset: 9,
    name: '(UTC+09:00) Irkutsk',
    displayName: 'North Asia East Time'
  }, {
    id: 84,
    description: 'Tokyo Standard Time',
    offset: 9,
    name: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    displayName: 'Tokyo Time'
  }, {
    id: 85,
    description: 'Korea Standard Time',
    offset: 9,
    name: '(UTC+09:00) Seoul',
    displayName: 'Korea Time'
  }, {
    id: 86,
    description: 'Cen. Australia Standard Time',
    offset: 9,
    name: '(UTC+09:30) Adelaide (Australia)',
    displayName: 'Cen. Australia Time'
  }, {
    id: 87,
    description: 'AUS Central Standard Time',
    offset: 9,
    name: '(UTC+09:30) Darwin',
    displayName: 'AUS Central Time'
  }, {
    id: 88,
    description: 'E. Australia Standard Time',
    offset: 10,
    name: '(UTC+10:00) Brisbane (Australia)',
    displayName: 'E. Australia Time'
  }, {
    id: 89,
    description: 'AUS Eastern Standard Time',
    offset: 10,
    name: '(UTC+10:00) Canberra, Melbourne, Sydney',
    displayName: 'AUS Eastern Time'
  }, {
    id: 90,
    description: 'West Pacific Standard Time',
    offset: 10,
    name: '(UTC+10:00) Guam, Port Moresby',
    displayName: 'West Pacific Time'
  }, {
    id: 91,
    description: 'Tasmania Standard Time',
    offset: 10,
    name: '(UTC+10:00) Hobart',
    displayName: 'Tasmania Time'
  }, {
    id: 92,
    description: 'Yakutsk Standard Time',
    offset: 10,
    name: '(UTC+10:00) Yakutsk',
    displayName: 'Yakutsk Time'
  }, {
    id: 93,
    description: 'Central Pacific Standard Time',
    offset: 11,
    name: '(UTC+11:00) Solomon Is., New Caledonia',
    displayName: 'Central Pacific Time'
  }, {
    id: 94,
    description: 'Vladivostok Standard Time',
    offset: 11,
    name: '(UTC+11:00) Vladivostok',
    displayName: 'Vladivostok Time'
  }, {
    id: 95,
    description: 'New Zealand Standard Time',
    offset: 12,
    name: '(UTC+12:00) Auckland, Wellington',
    displayName: 'New Zealand Time'
  }, {
    id: 96,
    description: 'UTC+12',
    offset: 12,
    name: '(UTC+12:00) Coordinated Universal Time+12',
    displayName: 'UTC+12'
  }, {
    id: 97,
    description: 'Fiji Standard Time',
    offset: 12,
    name: '(UTC+12:00) Fiji',
    displayName: 'Fiji Time'
  }, {
    id: 98,
    description: 'Magadan Standard Time',
    offset: 12,
    name: '(UTC+12:00) Magadan',
    displayName: 'Magadan Time'
  }, {
    id: 99,
    description: 'Kamchatka Standard Time',
    offset: 12,
    name: '(UTC+12:00) Petropavlovsk-Kamchatsky - Old',
    displayName: 'Kamchatka Time'
  }, {
    id: 100,
    description: 'Tonga Standard Time',
    offset: 13,
    name: "(UTC+13:00) Nuku'alofa",
    displayName: 'Tonga Time'
  }, {
    id: 101,
    description: 'Samoa Standard Time',
    offset: 13,
    name: '(UTC+13:00) Samoa',
    displayName: 'Samoa Time'
  }];
  _exports.default = _default;
});