define("ember-material-components-web/templates/components/mdc-list/item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    start-detail=(component "mdc-list/item/start-detail")
    text=(component "mdc-list/item/text")
    end-detail=(component "mdc-list/item/end-detail")
  )}}
  
  */
  {
    "id": "zJL02UWb",
    "block": "[[[18,1,[[28,[37,1],null,[[\"start-detail\",\"text\",\"end-detail\"],[[50,\"mdc-list/item/start-detail\",0,null,null],[50,\"mdc-list/item/text\",0,null,null],[50,\"mdc-list/item/end-detail\",0,null,null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-list/item.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});