define("ember-cli-ss-enums/utils/enum-data/entry-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 24,
    name: 'Photo Upload'
  }, {
    id: 26,
    name: 'Media Title'
  }, {
    id: 27,
    name: 'Media Caption'
  }, {
    id: 32,
    name: 'Website Url'
  }, {
    id: 33,
    name: 'Facebook Url'
  }, {
    id: 76,
    name: 'Twitter'
  }, {
    id: 80,
    name: 'Entry Source Type'
  }, {
    id: 87,
    name: 'Entry Source Media Type'
  }, {
    id: 91,
    name: 'Entry Source Image Thumb Url'
  }, {
    id: 92,
    name: 'Entry Source Image Url'
  }, {
    id: 94,
    name: 'Entry Source Video Low Res Url'
  }, {
    id: 102,
    name: 'Seed'
  }, {
    id: 104,
    name: 'Entry Source Video Url'
  }, {
    id: 105,
    name: 'Entry Source Video Preview Image Url'
  }, {
    id: 109,
    name: 'Entry Source Post Date Created'
  }, {
    id: 110,
    name: 'Entry Source Post Id'
  }, {
    id: 118,
    name: 'Entry Source Post Url'
  }, {
    id: 128,
    name: 'Entry Source Hashtag'
  }, {
    id: 129,
    name: 'Entry Source Username'
  }, {
    id: 131,
    name: 'Entry Phone Number'
  }, {
    id: 132,
    name: 'Media Release Consent'
  }, {
    id: 133,
    name: 'Video Entry Upload'
  }, {
    id: 134,
    name: 'Entry Address'
  }, {
    id: 429418,
    name: 'YouTube Channel'
  }, {
    id: 429419,
    name: 'Instagram Handle'
  }, {
    id: 429420,
    name: 'TikTok Handle'
  }, {
    id: 429421,
    name: 'SnapChat Handle'
  }, {
    id: 429422,
    name: 'LinkedIn Profile Url'
  }, {
    id: 429423,
    name: 'Vimeo Channel Url'
  }, {
    id: 474328,
    name: 'Automatically Add Entrants'
  }, {
    id: 475251,
    name: 'Logo'
  }, {
    id: 472297,
    name: 'Featured Entrant'
  }];
  _exports.default = _default;
});