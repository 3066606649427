define("ember-cli-ss-enums/utils/enum-data/message-version-feed-article-quantity-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'AllSinceLastSend'
  }, {
    id: 2,
    name: 'NumberOfArticlesBeforeSend'
  }, {
    id: 10,
    name: 'AllInNumberOfHoursBeforeSend'
  }, {
    id: 11,
    name: 'DayBeforeSend'
  }, {
    id: 12,
    name: 'DayOfSend'
  }];
  _exports.default = _default;
});