define("ember-cli-ss-enums/utils/enum-data/ss-product-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Contests'
  }, {
    id: 2,
    name: 'Ballots'
  }, {
    id: 3,
    name: 'Quizzes'
  }, {
    id: 4,
    name: 'Data'
  }, {
    id: 5,
    name: 'Messaging'
  }, {
    id: 6,
    name: 'myCapture Publisher'
  }, {
    id: 7,
    name: 'Community'
  }, {
    id: 8,
    name: 'Offers'
  }, {
    id: 9,
    name: 'Surveys'
  }, {
    id: 10,
    name: 'Custom Single Contest'
  }, {
    id: 11,
    name: 'National Contest: Auto Racing'
  }, {
    id: 12,
    name: 'National Contest: Golf'
  }, {
    id: 13,
    name: 'National Contest: College Basketball'
  }, {
    id: 14,
    name: 'National Contest: Pro Football'
  }, {
    id: 15,
    name: 'National Contest: Pro Football Knockout'
  }, {
    id: 16,
    name: 'National Contest: College Football'
  }, {
    id: 17,
    name: 'Community Galleries'
  }, {
    id: 18,
    name: 'Polls'
  }, {
    id: 19,
    name: 'Events'
  }, {
    id: 20,
    name: 'Ecommerce'
  }];
  _exports.default = _default;
});