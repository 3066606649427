define("ember-material-components-web/templates/components/mdc-grid-list/tiles/tile/secondary", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    icon=(component "mdc-grid-list/tiles/tile/secondary/icon")
    title=(component "mdc-grid-list/tiles/tile/secondary/title")
    support-text=(component "mdc-grid-list/tiles/tile/secondary/support-text")
  )}}
  
  */
  {
    "id": "zk9eyjAq",
    "block": "[[[18,1,[[28,[37,1],null,[[\"icon\",\"title\",\"support-text\"],[[50,\"mdc-grid-list/tiles/tile/secondary/icon\",0,null,null],[50,\"mdc-grid-list/tiles/tile/secondary/title\",0,null,null],[50,\"mdc-grid-list/tiles/tile/secondary/support-text\",0,null,null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-grid-list/tiles/tile/secondary.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});