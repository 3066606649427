define("ember-material-components-web/components/mdc-toolbar/title", ["exports", "@ember/object/computed", "@ember/component", "ember-material-components-web/templates/components/mdc-toolbar/title", "ember-material-components-web/utils/global-event-handlers"], function (_exports, _computed, _component, _title, _globalEventHandlers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Ember Hooks
    layout: _title.default,
    classNames: Object.freeze(['mdc-toolbar__title']),
    attributeBindings: Object.freeze(['style', ..._globalEventHandlers.default]),
    //endregion

    //region Computed Properties
    style: (0, _computed.readOnly)('title-style')
    //endregion
  });
  _exports.default = _default;
});