define("ember-cli-ss-enums/utils/enum-data/winner-method-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Most Points'
  }, {
    id: 2,
    name: 'Weighted Random'
  }, {
    id: 3,
    name: 'Manual'
  }];
  _exports.default = _default;
});