define("ember-material-components-web/templates/components/mdc-radio", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <input class="mdc-radio__native-control" onclick={{action "clicked"}} type="radio" id={{this.input-id}} name={{this.name}} onchange={{action "inputChanged"}} checked={{this.checked}}>
  <div class="mdc-radio__background">
    <div class="mdc-radio__outer-circle"></div>
    <div class="mdc-radio__inner-circle"></div>
  </div>
  
  */
  {
    "id": "w3vAu52c",
    "block": "[[[10,\"input\"],[14,0,\"mdc-radio__native-control\"],[15,\"onclick\",[28,[37,0],[[30,0],\"clicked\"],null]],[15,1,[30,0,[\"input-id\"]]],[15,3,[30,0,[\"name\"]]],[15,\"onchange\",[28,[37,0],[[30,0],\"inputChanged\"],null]],[15,\"checked\",[30,0,[\"checked\"]]],[14,4,\"radio\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"mdc-radio__background\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"mdc-radio__outer-circle\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"mdc-radio__inner-circle\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-radio.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});