define("ember-material-components-web/components/mdc-linear-progress", ["exports", "@ember/component", "@ember/runloop", "@ember/object", "ember-material-components-web/templates/components/mdc-linear-progress", "@material/linear-progress", "ember-material-components-web/mixins/mdc-component", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/utils/get-element-property", "ember-material-components-web/utils/style-computed"], function (_exports, _component, _runloop, _object, _mdcLinearProgress, _linearProgress, _mdcComponent, _globalEventHandlers, _getElementProperty, _styleComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */

  const {
    cssClasses,
    strings
  } = _linearProgress.MDCLinearProgressFoundation;
  var _default = _component.default.extend(_mdcComponent.MDCComponent, {
    //region Attributes
    /**
     * @type {Boolean}
     */
    closed: false,
    /**
     * @type {Boolean}
     */
    indeterminate: false,
    /**
     * @type {Boolean}
     */
    reversed: false,
    /**
     * @type {Boolean}
     */
    secondary: false,
    /**
     * @type {Number}
     * Must be a value between 0 and 1
     */
    progress: 1,
    //endregion

    //region Ember Hooks
    layout: _mdcLinearProgress.default,
    classNames: Object.freeze(['mdc-linear-progress']),
    classNameBindings: Object.freeze([`indeterminate:${cssClasses.INDETERMINATE_CLASS}`, `closed:${cssClasses.CLOSED_CLASS}`, `reversed:${cssClasses.REVERSED_CLASS}`, 'secondary:mdc-linear-progress--accent']),
    attributeBindings: Object.freeze(['role', ..._globalEventHandlers.default]),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'mdcPrimaryBarStyles', {});
      (0, _object.set)(this, 'mdcBufferStyles', {});
    },
    didReceiveAttrs() {
      this._super(...arguments);
      this.sync('progress');
    },
    //endregion

    //region Properties
    role: 'progressbar',
    /**
     * Key value pairs for CSS styles
     * @type {Object}
     */
    mdcPrimaryBarStyles: null,
    mdcBufferStyles: null,
    //endregion

    //region Computed Properties
    primaryBarStyles: (0, _styleComputed.default)('mdcPrimaryBarStyles'),
    bufferStyles: (0, _styleComputed.default)('mdcBufferStyles'),
    //endregion

    //region Methods
    createFoundation() {
      return new _linearProgress.MDCLinearProgressFoundation({
        hasClass: className => (0, _object.get)(this, 'mdcClasses').includes(className),
        addClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').addObject(className)),
        removeClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').removeObject(className)),
        getPrimaryBar: () => (0, _getElementProperty.default)(this, 'querySelector')(strings.PRIMARY_BAR_SELECTOR),
        getBuffer: () => (0, _getElementProperty.default)(this, 'querySelector')(strings.BUFFER_SELECTOR),
        setStyle: (el, property, value) => {
          let elementStyles;
          if (el.classList.contains(strings.PRIMARY_BAR_SELECTOR.slice(1))) {
            elementStyles = 'mdcPrimaryBarStyles';
          } else if (el.classList.contains(strings.BUFFER_SELECTOR.slice(1))) {
            elementStyles = 'mdcBufferStyles';
          }
          (0, _runloop.run)(() => this.setStyleFor(elementStyles, property, value));
        }
      });
    },
    afterFoundationCreation() {
      this.sync('progress');
    }
    //endregion
  });
  _exports.default = _default;
});