define("ember-cli-ss-enums/utils/enum-data/state-province", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 137,
    countryId: 14,
    name: 'Australian Capital Territory',
    abbreviation: 'ACT'
  }, {
    id: 138,
    countryId: 14,
    name: 'New South Wales',
    abbreviation: 'NSW'
  }, {
    id: 139,
    countryId: 14,
    name: 'Northern Territory',
    abbreviation: 'NT'
  }, {
    id: 140,
    countryId: 14,
    name: 'Queensland',
    abbreviation: 'QLD'
  }, {
    id: 141,
    countryId: 14,
    name: 'South Australia',
    abbreviation: 'SA'
  }, {
    id: 142,
    countryId: 14,
    name: 'Tasmania',
    abbreviation: 'TAS'
  }, {
    id: 143,
    countryId: 14,
    name: 'Victoria',
    abbreviation: 'VIC'
  }, {
    id: 144,
    countryId: 14,
    name: 'Western Australia',
    abbreviation: 'WA'
  }, {
    id: 223,
    countryId: 17,
    name: "Abaco",
    abbreviation: "ABA"
  }, {
    id: 224,
    countryId: 17,
    name: "Andros",
    abbreviation: "AND"
  }, {
    id: 225,
    countryId: 17,
    name: "Bimini Islands",
    abbreviation: "BIM"
  }, {
    id: 226,
    countryId: 17,
    name: "Cat Island",
    abbreviation: "CAT"
  }, {
    id: 227,
    countryId: 17,
    name: "Eleuthera",
    abbreviation: "ELE"
  }, {
    id: 228,
    countryId: 17,
    name: "Exuma & Cays",
    abbreviation: "EXU"
  }, {
    id: 229,
    countryId: 17,
    name: "Grand Bahama",
    abbreviation: "GBA"
  }, {
    id: 230,
    countryId: 17,
    name: "Harbour Island & Spanish Well",
    abbreviation: "HIS"
  }, {
    id: 231,
    countryId: 17,
    name: "Inagua",
    abbreviation: "INA"
  }, {
    id: 232,
    countryId: 17,
    name: "Long Island",
    abbreviation: "LIS"
  }, {
    id: 233,
    countryId: 17,
    name: "Mayaguana",
    abbreviation: "MAY"
  }, {
    id: 234,
    countryId: 17,
    name: "New Providence",
    abbreviation: "NPR"
  }, {
    id: 235,
    countryId: 17,
    name: "Ragged Islands",
    abbreviation: "RAG"
  }, {
    id: 389,
    countryId: 40,
    name: "Alberta",
    abbreviation: "AB"
  }, {
    id: 390,
    countryId: 40,
    name: "British Columbia",
    abbreviation: "BC"
  }, {
    id: 391,
    countryId: 40,
    name: "Manitoba",
    abbreviation: "MB"
  }, {
    id: 392,
    countryId: 40,
    name: "New Brunswick",
    abbreviation: "NB"
  }, {
    id: 393,
    countryId: 40,
    name: "Newfoundland and Labrador",
    abbreviation: "NL"
  }, {
    id: 394,
    countryId: 40,
    name: "Northwest Territories",
    abbreviation: "NT"
  }, {
    id: 395,
    countryId: 40,
    name: "Nova Scotia",
    abbreviation: "NS"
  }, {
    id: 396,
    countryId: 40,
    name: "Nunavut",
    abbreviation: "NU"
  }, {
    id: 397,
    countryId: 40,
    name: "Ontario",
    abbreviation: "ON"
  }, {
    id: 398,
    countryId: 40,
    name: "Prince Edward Island",
    abbreviation: "PE"
  }, {
    id: 399,
    countryId: 40,
    name: "Quebec",
    abbreviation: "QC"
  }, {
    id: 400,
    countryId: 40,
    name: "Saskatchewan",
    abbreviation: "SK"
  }, {
    id: 401,
    countryId: 40,
    name: "Yukon",
    abbreviation: "YT"
  }, {
    id: 899,
    countryId: 106,
    name: "Carlow",
    abbreviation: "CAR"
  }, {
    id: 900,
    countryId: 106,
    name: "Cavan",
    abbreviation: "CAV"
  }, {
    id: 901,
    countryId: 106,
    name: "Clare",
    abbreviation: "CLA"
  }, {
    id: 902,
    countryId: 106,
    name: "Cork",
    abbreviation: "COR"
  }, {
    id: 903,
    countryId: 106,
    name: "Donegal",
    abbreviation: "DON"
  }, {
    id: 904,
    countryId: 106,
    name: "Dublin",
    abbreviation: "DUB"
  }, {
    id: 905,
    countryId: 106,
    name: "Galway",
    abbreviation: "GAL"
  }, {
    id: 906,
    countryId: 106,
    name: "Kerry",
    abbreviation: "KRY"
  }, {
    id: 907,
    countryId: 106,
    name: "Kildare",
    abbreviation: "KID"
  }, {
    id: 908,
    countryId: 106,
    name: "Kilkenny",
    abbreviation: "KIK"
  }, {
    id: 909,
    countryId: 106,
    name: "Laois",
    abbreviation: "LAO"
  }, {
    id: 910,
    countryId: 106,
    name: "Leitrim",
    abbreviation: "LEI"
  }, {
    id: 911,
    countryId: 106,
    name: "Limerick",
    abbreviation: "LIM"
  }, {
    id: 912,
    countryId: 106,
    name: "Longford",
    abbreviation: "LON"
  }, {
    id: 913,
    countryId: 106,
    name: "Louth",
    abbreviation: "LOU"
  }, {
    id: 914,
    countryId: 106,
    name: "Mayo",
    abbreviation: "MAY"
  }, {
    id: 915,
    countryId: 106,
    name: "Meath",
    abbreviation: "MEA"
  }, {
    id: 916,
    countryId: 106,
    name: "Monaghan",
    abbreviation: "MON"
  }, {
    id: 917,
    countryId: 106,
    name: "Offaly",
    abbreviation: "OFF"
  }, {
    id: 918,
    countryId: 106,
    name: "Roscommon",
    abbreviation: "ROS"
  }, {
    id: 919,
    countryId: 106,
    name: "Sligo",
    abbreviation: "SLI"
  }, {
    id: 920,
    countryId: 106,
    name: "Tipperary",
    abbreviation: "TIP"
  }, {
    id: 921,
    countryId: 106,
    name: "Unknown",
    abbreviation: "UNK"
  }, {
    id: 922,
    countryId: 106,
    name: "Waterford",
    abbreviation: "WAT"
  }, {
    id: 923,
    countryId: 106,
    name: "Westmeath",
    abbreviation: "WES"
  }, {
    id: 924,
    countryId: 106,
    name: "Wexford",
    abbreviation: "WEX"
  }, {
    id: 925,
    countryId: 106,
    name: "Wicklow",
    abbreviation: "WIC"
  }, {
    id: 1073,
    countryId: 143,
    name: "Aguascalientes",
    abbreviation: "AGS"
  }, {
    id: 1074,
    countryId: 143,
    name: "Baja California",
    abbreviation: "BCN"
  }, {
    id: 1075,
    countryId: 143,
    name: "Baja California Sur",
    abbreviation: "BCS"
  }, {
    id: 1076,
    countryId: 143,
    name: "Campeche",
    abbreviation: "CAM"
  }, {
    id: 1077,
    countryId: 143,
    name: "Chiapas",
    abbreviation: "CHS"
  }, {
    id: 1078,
    countryId: 143,
    name: "Chihuahua",
    abbreviation: "CHI"
  }, {
    id: 1079,
    countryId: 143,
    name: "Coahuila",
    abbreviation: "COA"
  }, {
    id: 1080,
    countryId: 143,
    name: "Colima",
    abbreviation: "COL"
  }, {
    id: 1081,
    countryId: 143,
    name: "Distrito Federal",
    abbreviation: "DF"
  }, {
    id: 1082,
    countryId: 143,
    name: "Durango",
    abbreviation: "DGO"
  }, {
    id: 1083,
    countryId: 143,
    name: "Guanajuato",
    abbreviation: "GTO"
  }, {
    id: 1084,
    countryId: 143,
    name: "Guerrero",
    abbreviation: "GRO"
  }, {
    id: 1085,
    countryId: 143,
    name: "Hidalgo",
    abbreviation: "HGO"
  }, {
    id: 1086,
    countryId: 143,
    name: "Jalisco",
    abbreviation: "JAL"
  }, {
    id: 1087,
    countryId: 143,
    name: "Mexico",
    abbreviation: "MEX"
  }, {
    id: 1088,
    countryId: 143,
    name: "Michoacan de Ocampo",
    abbreviation: "MIC"
  }, {
    id: 1089,
    countryId: 143,
    name: "Morelos",
    abbreviation: "MOR"
  }, {
    id: 1090,
    countryId: 143,
    name: "Nayarit",
    abbreviation: "NAY"
  }, {
    id: 1091,
    countryId: 143,
    name: "Nuevo Leon",
    abbreviation: "NLN"
  }, {
    id: 1092,
    countryId: 143,
    name: "Oaxaca",
    abbreviation: "OAX"
  }, {
    id: 1093,
    countryId: 143,
    name: "Puebla",
    abbreviation: "PUE"
  }, {
    id: 1094,
    countryId: 143,
    name: "Queretaro de Arteaga",
    abbreviation: "QRO"
  }, {
    id: 1095,
    countryId: 143,
    name: "Quintana Roo",
    abbreviation: "QTR"
  }, {
    id: 1096,
    countryId: 143,
    name: "San Luis Potosi",
    abbreviation: "SLP"
  }, {
    id: 1097,
    countryId: 143,
    name: "Sinaloa",
    abbreviation: "SIN"
  }, {
    id: 1098,
    countryId: 143,
    name: "Sonora",
    abbreviation: "SON"
  }, {
    id: 1099,
    countryId: 143,
    name: "Tabasco",
    abbreviation: "TAB"
  }, {
    id: 1100,
    countryId: 143,
    name: "Tamaulipas",
    abbreviation: "TAM"
  }, {
    id: 1101,
    countryId: 143,
    name: "Tlaxcala",
    abbreviation: "TLA"
  }, {
    id: 1102,
    countryId: 143,
    name: "Veracruz-Llave",
    abbreviation: "VER"
  }, {
    id: 1103,
    countryId: 143,
    name: "Yucatan",
    abbreviation: "YUC"
  }, {
    id: 1104,
    countryId: 143,
    name: "Zacatecas",
    abbreviation: "ZAC"
  }, {
    id: 1153,
    countryId: 159,
    name: 'Chatham Islands',
    abbreviation: 'CI'
  }, {
    id: 1154,
    countryId: 159,
    name: 'North Island',
    abbreviation: 'NI'
  }, {
    id: 1155,
    countryId: 159,
    name: 'South Island',
    abbreviation: 'SI'
  }, {
    id: 1156,
    countryId: 159,
    name: 'Stewart Island',
    abbreviation: 'STI'
  }, {
    id: 1794,
    countryId: 234,
    name: "England",
    abbreviation: "ENG"
  }, {
    id: 1795,
    countryId: 234,
    name: "Northern Ireland",
    abbreviation: "NIR"
  }, {
    id: 179,
    countryId: 234,
    name: "Scotland",
    abbreviation: "SCT"
  }, {
    id: 1797,
    countryId: 234,
    name: "Wales",
    abbreviation: "WLS"
  }, {
    id: 1798,
    countryId: 236,
    name: "Alabama",
    abbreviation: "AL"
  }, {
    id: 1799,
    countryId: 236,
    name: "Alaska",
    abbreviation: "AK"
  }, {
    id: 1800,
    countryId: 236,
    name: "American Samoa",
    abbreviation: "AS"
  }, {
    id: 1801,
    countryId: 236,
    name: "Arizona",
    abbreviation: "AZ"
  }, {
    id: 1802,
    countryId: 236,
    name: "Arkansas",
    abbreviation: "AR"
  }, {
    id: 1803,
    countryId: 236,
    name: "California",
    abbreviation: "CA"
  }, {
    id: 1804,
    countryId: 236,
    name: "Colorado",
    abbreviation: "CO"
  }, {
    id: 1805,
    countryId: 236,
    name: "Connecticut",
    abbreviation: "CT"
  }, {
    id: 1806,
    countryId: 236,
    name: "Delaware",
    abbreviation: "DE"
  }, {
    id: 1807,
    countryId: 236,
    name: "District of Columbia",
    abbreviation: "DC"
  }, {
    id: 1808,
    countryId: 236,
    name: "Florida",
    abbreviation: "FL"
  }, {
    id: 1809,
    countryId: 236,
    name: "Georgia",
    abbreviation: "GA"
  }, {
    id: 1810,
    countryId: 236,
    name: "Guam",
    abbreviation: "GU"
  }, {
    id: 1811,
    countryId: 236,
    name: "Hawaii",
    abbreviation: "HI"
  }, {
    id: 1812,
    countryId: 236,
    name: "Idaho",
    abbreviation: "ID"
  }, {
    id: 1813,
    countryId: 236,
    name: "Illinois",
    abbreviation: "IL"
  }, {
    id: 1814,
    countryId: 236,
    name: "Indiana",
    abbreviation: "IN"
  }, {
    id: 1815,
    countryId: 236,
    name: "Iowa",
    abbreviation: "IA"
  }, {
    id: 1816,
    countryId: 236,
    name: "Kansas",
    abbreviation: "KS"
  }, {
    id: 1817,
    countryId: 236,
    name: "Kentucky",
    abbreviation: "KY"
  }, {
    id: 1818,
    countryId: 236,
    name: "Louisiana",
    abbreviation: "LA"
  }, {
    id: 1819,
    countryId: 236,
    name: "Maine",
    abbreviation: "ME"
  }, {
    id: 1820,
    countryId: 236,
    name: "Maryland",
    abbreviation: "MD"
  }, {
    id: 1821,
    countryId: 236,
    name: "Massachusetts",
    abbreviation: "MA"
  }, {
    id: 1822,
    countryId: 236,
    name: "Michigan",
    abbreviation: "MI"
  }, {
    id: 1823,
    countryId: 236,
    name: "Minnesota",
    abbreviation: "MN"
  }, {
    id: 1824,
    countryId: 236,
    name: "Mississippi",
    abbreviation: "MS"
  }, {
    id: 1825,
    countryId: 236,
    name: "Missouri",
    abbreviation: "MO"
  }, {
    id: 1826,
    countryId: 236,
    name: "Montana",
    abbreviation: "MT"
  }, {
    id: 1827,
    countryId: 236,
    name: "Nebraska",
    abbreviation: "NE"
  }, {
    id: 1828,
    countryId: 236,
    name: "Nevada",
    abbreviation: "NV"
  }, {
    id: 1829,
    countryId: 236,
    name: "New Hampshire",
    abbreviation: "NH"
  }, {
    id: 1830,
    countryId: 236,
    name: "New Jersey",
    abbreviation: "NJ"
  }, {
    id: 1831,
    countryId: 236,
    name: "New Mexico",
    abbreviation: "NM"
  }, {
    id: 1832,
    countryId: 236,
    name: "New York",
    abbreviation: "NY"
  }, {
    id: 1833,
    countryId: 236,
    name: "North Carolina",
    abbreviation: "NC"
  }, {
    id: 1834,
    countryId: 236,
    name: "North Dakota",
    abbreviation: "ND"
  }, {
    id: 1835,
    countryId: 236,
    name: "Northern Mariana Islands",
    abbreviation: "MP"
  }, {
    id: 1836,
    countryId: 236,
    name: "Ohio",
    abbreviation: "OH"
  }, {
    id: 1837,
    countryId: 236,
    name: "Oklahoma",
    abbreviation: "OK"
  }, {
    id: 1838,
    countryId: 236,
    name: "Oregon",
    abbreviation: "OR"
  }, {
    id: 1839,
    countryId: 236,
    name: "Pennsylvania",
    abbreviation: "PA"
  }, {
    id: 1840,
    countryId: 236,
    name: "Puerto Rico",
    abbreviation: "PR"
  }, {
    id: 1841,
    countryId: 236,
    name: "Rhode Island",
    abbreviation: "RI"
  }, {
    id: 1842,
    countryId: 236,
    name: "South Carolina",
    abbreviation: "SC"
  }, {
    id: 1843,
    countryId: 236,
    name: "South Dakota",
    abbreviation: "SD"
  }, {
    id: 1844,
    countryId: 236,
    name: "Tennessee",
    abbreviation: "TN"
  }, {
    id: 1845,
    countryId: 236,
    name: "Texas",
    abbreviation: "TX"
  }, {
    id: 1846,
    countryId: 236,
    name: "Utah",
    abbreviation: "UT"
  }, {
    id: 1847,
    countryId: 236,
    name: "Vermont",
    abbreviation: "VT"
  }, {
    id: 1848,
    countryId: 236,
    name: "Virgin Islands",
    abbreviation: "VI"
  }, {
    id: 1849,
    countryId: 236,
    name: "Virginia",
    abbreviation: "VA"
  }, {
    id: 1850,
    countryId: 236,
    name: "Washington",
    abbreviation: "WA"
  }, {
    id: 1851,
    countryId: 236,
    name: "West Virginia",
    abbreviation: "WV"
  }, {
    id: 1852,
    countryId: 236,
    name: "Wisconsin",
    abbreviation: "WI"
  }, {
    id: 1853,
    countryId: 236,
    name: "Wyoming",
    abbreviation: "WY"
  }];
  _exports.default = _default;
});