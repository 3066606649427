define("ember-cli-ss-enums/utils/enum-data/token-content-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'HtmlText',
    editorComponent: 'token-content-editor-for-html-text',
    displayOrder: 5,
    materialIconKey: 'code'
  }, {
    id: 2,
    name: 'PlainText',
    editorComponent: 'token-content-editor-for-plain-text',
    displayOrder: 4,
    materialIconKey: 'text_format'
  }, {
    id: 3,
    name: 'LinkableText',
    editorComponent: 'token-content-editor-for-linkable-text',
    displayOrder: 7,
    materialIconKey: 'link'
  }, {
    id: 4,
    name: 'Image',
    editorComponent: 'token-content-editor-for-image',
    displayOrder: 2,
    materialIconKey: 'image'
  }, {
    id: 5,
    name: 'LinkableImage',
    editorComponent: 'token-content-editor-for-linkable-image',
    displayOrder: 1,
    materialIconKey: 'image'
  }, {
    id: 6,
    name: 'Code',
    editorComponent: 'token-content-editor-for-code',
    displayOrder: 6,
    materialIconKey: 'code'
  }, {
    id: 7,
    name: 'Color',
    editorComponent: 'token-content-editor-for-color',
    displayOrder: 3,
    materialIconKey: 'color_lens'
  }, {
    id: 8,
    name: 'IconImage',
    editorComponent: 'token-content-editor-for-icon-image',
    displayOrder: 8,
    materialIconKey: 'image'
  }, {
    id: 9,
    name: 'Email',
    editorComponent: 'token-content-editor-for-email-share',
    displayOrder: 9,
    materialIconKey: 'code'
  }, {
    id: 10,
    name: 'Facebook',
    editorComponent: 'token-content-editor-for-facebook-share',
    displayOrder: 10
  }, {
    id: 11,
    name: 'Twitter',
    editorComponent: 'token-content-editor-for-twitter-share',
    displayOrder: 11
  }, {
    id: 12,
    name: 'Pinterest',
    editorComponent: 'token-content-editor-for-pinterest-share',
    displayOrder: 12
  }, {
    id: 13,
    name: 'ImageWithoutAltText',
    editorComponent: 'token-content-editor-for-image-without-alt-text',
    displayOrder: 13,
    materialIconKey: 'image'
  }, {
    id: 14,
    name: 'LinkableImageWithoutAltText',
    editorComponent: 'token-content-editor-for-linkable-image-without-alt-text',
    displayOrder: 14,
    materialIconKey: 'image'
  }, {
    id: 15,
    name: 'Item',
    editorComponent: 'token-content-editor-for-item',
    displayOrder: 15
  }, {
    id: 16,
    name: 'GoogleFont',
    editorComponent: 'token-content-editor-for-google-font',
    displayOrder: 16
  }, {
    id: 17,
    name: 'TextOrRedirectUrl',
    editorComponent: 'token-content-editor-for-text-or-redirect-url',
    displayOrder: 17
  }, {
    id: 18,
    name: 'Field',
    editorComponent: 'token-content-editor-for-field',
    displayOrder: 18
  }, {
    id: 19,
    name: 'RawHtml',
    editorComponent: 'token-content-editor-for-raw-html',
    displayOrder: 19,
    materialIconKey: 'code'
  }, {
    id: 20,
    name: 'GalleryLayout',
    editorComponent: 'token-content-editor-for-gallery-layout',
    displayOrder: 20
  }];
  _exports.default = _default;
});