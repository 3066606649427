define("ember-material-components-web/templates/components/mdc-tab-bar/tab", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    icon=(component "mdc-tab-bar/tab/icon")
    icon-text=(component "mdc-tab-bar/tab/icon-text")
  )}}
  
  */
  {
    "id": "octasS5d",
    "block": "[[[18,1,[[28,[37,1],null,[[\"icon\",\"icon-text\"],[[50,\"mdc-tab-bar/tab/icon\",0,null,null],[50,\"mdc-tab-bar/tab/icon-text\",0,null,null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-tab-bar/tab.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});