define("ember-cli-ss-enums/utils/enum-data/dynamic-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [
  // Payment Form Tokens
  {
    name: 'Order.ConfirmationCode',
    type: 'Order'
  }, {
    name: 'Order.LineItems',
    type: 'Order'
  }, {
    name: 'Order.PaymentMethod',
    type: 'Order'
  }, {
    name: 'Order.PurchaseDate',
    type: 'Order'
  }, {
    name: 'Order.TotalPrice',
    type: 'Order'
  }, {
    name: 'Product.Name',
    type: 'Product'
  }, {
    name: 'Product.Price',
    type: 'Product'
  },
  // Event Signup Tokens
  {
    name: 'Event.EndDate',
    type: 'Event'
  }, {
    name: 'Event.Name',
    type: 'Event'
  }, {
    name: 'Event.SignupEndDate',
    type: 'Event'
  }, {
    name: 'Event.SignupStartDate',
    type: 'Event'
  }, {
    name: 'Event.StartDate',
    type: 'Event'
  },
  // Message Version Tokens
  {
    name: 'MessageVersion.Id',
    type: 'MessageVersion'
  }, {
    name: 'MessageVersion.SendDate',
    type: 'MessageVersion'
  }, {
    name: 'MessageVersion.Subject',
    type: 'MessageVersion'
  }, {
    name: 'MessageVersion.Url',
    type: 'MessageVersion'
  }, {
    name: 'MessageVersionInstance.Id',
    type: 'MessageVersion'
  },
  // Organization Tokens
  {
    name: 'Organization.Address',
    type: 'Organization'
  }, {
    name: 'Organization.Id',
    type: 'Organization'
  }, {
    name: 'Organization.MarketingText',
    type: 'Organization'
  }, {
    name: 'Organization.Name',
    type: 'Organization'
  }, {
    name: 'Organization.NameEncoded',
    type: 'Organization'
  }, {
    name: 'Organization.Timezone',
    type: 'Organization'
  }, {
    name: 'Organization.Url',
    type: 'Organization'
  },
  // Promotion Tokens
  {
    name: 'Promotion.CurrentRoundEndDate',
    type: 'Promotion'
  }, {
    name: 'Promotion.CurrentRoundNumber',
    type: 'Promotion'
  }, {
    name: 'Promotion.EntryEndDate',
    type: 'Promotion'
  }, {
    name: 'Promotion.EntryFrequency',
    type: 'Promotion'
  }, {
    name: 'Promotion.EntryStartDate',
    type: 'Promotion'
  }, {
    name: 'Promotion.Name',
    type: 'Promotion'
  }, {
    name: 'Promotion.NameEncoded',
    type: 'Promotion'
  }, {
    name: 'Promotion.SelectionEndDate',
    type: 'Promotion'
  }, {
    name: 'Promotion.SelectionFrequency',
    type: 'Promotion'
  }, {
    name: 'Promotion.SelectionStartDate',
    type: 'Promotion'
  }, {
    name: 'Promotion.SupportInfo',
    type: 'Promotion'
  }, {
    name: 'Promotion.Url',
    type: 'Promotion'
  }, {
    name: 'Promotion.UrlEncoded',
    type: 'Promotion'
  }, {
    name: 'Promotion.UserReferralUrl',
    type: 'Promotion'
  }, {
    name: 'Promotion.UserReferralUrlEncoded',
    type: 'Promotion'
  },
  // System Tokens
  {
    name: 'System.BatchQueueId',
    type: 'System'
  }, {
    name: 'System.OptOutText',
    type: 'System'
  }, {
    name: 'System.Random1',
    type: 'System'
  }, {
    name: 'System.Random2',
    type: 'System'
  }, {
    name: 'System.Random3',
    type: 'System'
  }, {
    name: 'System.Random4',
    type: 'System'
  }, {
    name: 'System.Random5',
    type: 'System'
  }, {
    name: 'System.Random6',
    type: 'System'
  }, {
    name: 'System.Random7',
    type: 'System'
  }, {
    name: 'System.Random8',
    type: 'System'
  }, {
    name: 'System.Random9',
    type: 'System'
  }, {
    name: 'System.Random10',
    type: 'System'
  }, {
    name: 'System.ReadTracking',
    type: 'System'
  }, {
    name: 'System.SmsStopMessage',
    type: 'System'
  }, {
    name: 'System.Stop',
    type: 'System'
  }];
  _exports.default = _default;
});