define("ember-material-components-web/components/mdc-menu/item", ["exports", "@ember/component", "@ember/object", "ember-material-components-web/templates/components/mdc-menu/item", "ember-material-components-web/utils/global-event-handlers"], function (_exports, _component, _object, _item, _globalEventHandlers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @typedef {Ember.Component} MDCMenuItemComponent
   */
  var _default = _component.default.extend({
    //region Attributes
    /**
     * @type {MDCMenuComponent}
     */
    menu: null,
    /**
     * Pass an action to call when the item is selected.
     * @type {?Function}
     * @param {Number} index
     */
    selected: x => x,
    //endregion

    //region Ember Hooks
    didInsertElement() {
      this._super(...arguments);
      this.registerWithMenu();
    },
    willDestroyElement() {
      this._super(...arguments);
      this.unregisterWithMenu();
    },
    layout: _item.default,
    tagName: 'li',
    classNames: Object.freeze(['mdc-list-item']),
    attributeBindings: Object.freeze(['role', 'tabindex', 'aria-disabled', 'style', ..._globalEventHandlers.default]),
    //endregion

    //region Properties
    role: 'menuitem',
    tabindex: 0,
    //endregion

    //region Methods
    notifySelected(index) {
      (0, _object.get)(this, 'selected')(index);
    },
    /**
     * @returns {MDCMenuComponent}
     */
    assertMenu() {
      const menu = (0, _object.get)(this, 'menu');
      if (!menu) {
        throw new Error(`Missing 'menu' attribute from ${this.toString()}`);
      }
      return menu;
    },
    registerWithMenu() {
      this.assertMenu().registerItem(this);
    },
    unregisterWithMenu() {
      this.assertMenu().unregisterItem(this);
    },
    /**
     * @public
     * @returns {Object} with `top` and `height`
     */
    getYParams() {
      const {
        offsetTop: top,
        offsetHeight: height
      } = (0, _object.get)(this, 'element');
      return {
        top,
        height
      };
    },
    /**
     * @public
     * @param {String} value
     */
    setTransitionDelay(value) {
      this.element.style.transitionDelay = value;
    }
    //endregion
  });
  _exports.default = _default;
});