define("ember-material-components-web/components/mdc-layout-grid/inner", ["exports", "@ember/component", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-layout-grid/inner"], function (_exports, _component, _globalEventHandlers, _inner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Ember Hooks
    classNames: Object.freeze(['mdc-layout-grid__inner']),
    layout: _inner.default,
    attributeBindings: Object.freeze([..._globalEventHandlers.default])
    //endregion
  });
  _exports.default = _default;
});