define("ember-cli-ss-enums/utils/enum-data/media-size-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Small'
  }, {
    id: 2,
    name: 'Medium'
  }, {
    id: 3,
    name: 'Large'
  }];
  _exports.default = _default;
});