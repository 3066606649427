define("ember-material-components-web/components/mdc-layout-grid/inner/cell", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-layout-grid/inner/cell"], function (_exports, _computed, _component, _object, _globalEventHandlers, _cell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Attributes
    /**
     * @type {Number}
     */
    order: null,
    /**
     * Must be one of `top`,`middle`, or `bottom`
     * @type {String}
     */
    align: 'center',
    /**
     * @type {Number}
     */
    span: null,
    /**
     * @type {Number}
     */
    'span-desktop': null,
    /**
     * @type {Number}
     */
    'span-tablet': null,
    /**
     * @type {Number}
     */
    'span-phone': null,
    //endregion

    //region Ember Hooks
    classNames: Object.freeze(['mdc-layout-grid__cell']),
    classNameBindings: Object.freeze(['orderClass', 'isAlignTop:mdc-layout-grid__cell--align-top', 'isAlignMiddle:mdc-layout-grid__cell--align-middle', 'isAlignBottom:mdc-layout-grid__cell--align-bottom', 'spanClass', 'spanDesktopClass', 'spanTabletClass', 'spanPhoneClass']),
    attributeBindings: Object.freeze([..._globalEventHandlers.default]),
    layout: _cell.default,
    //endregion

    //region Computed Properties
    orderClass: (0, _object.computed)('order', function () {
      const order = (0, _object.get)(this, 'order');
      return parseInt(order) ? `mdc-layout-grid__cell--order-${order}` : null;
    }),
    spanClass: (0, _object.computed)('span', function () {
      const span = (0, _object.get)(this, 'span');
      return parseInt(span) ? `mdc-layout-grid__cell--span-${span}` : null;
    }),
    spanDesktopClass: (0, _object.computed)('span-desktop', function () {
      const span = (0, _object.get)(this, 'span-desktop');
      return parseInt(span) ? `mdc-layout-grid__cell--span-${span}-desktop` : null;
    }),
    spanTabletClass: (0, _object.computed)('span-tablet', function () {
      const span = (0, _object.get)(this, 'span-tablet');
      return parseInt(span) ? `mdc-layout-grid__cell--span-${span}-tablet` : null;
    }),
    spanPhoneClass: (0, _object.computed)('span-phone', function () {
      const span = (0, _object.get)(this, 'span-phone');
      return parseInt(span) ? `mdc-layout-grid__cell--span-${span}-phone` : null;
    }),
    isAlignTop: (0, _computed.equal)('align', 'top'),
    isAlignMiddle: (0, _computed.equal)('align', 'middle'),
    isAlignBottom: (0, _computed.equal)('align', 'bottom')
    //endregion
  });
  _exports.default = _default;
});