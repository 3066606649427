define("ember-material-components-web/templates/components/mdc-menu", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="mdc-simple-menu__items mdc-list" role="menu" aria-hidden="true">
    {{yield (hash
      item=(component "mdc-menu/item" style=this.itemStyle menu=this)
    )}}
  </ul>
  
  */
  {
    "id": "YbzCwUzy",
    "block": "[[[10,\"ul\"],[14,0,\"mdc-simple-menu__items mdc-list\"],[14,\"role\",\"menu\"],[14,\"aria-hidden\",\"true\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,1],null,[[\"item\"],[[50,\"mdc-menu/item\",0,null,[[\"style\",\"menu\"],[[30,0,[\"itemStyle\"]],[30,0]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-menu.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});