define("ember-material-components-web/components/mdc-list-group/divider", ["exports", "ember-material-components-web/components/mdc-list/divider", "ember-material-components-web/templates/components/mdc-list-group/divider"], function (_exports, _divider, _divider2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _divider.default.extend({
    //region Ember Hooks
    layout: _divider2.default,
    tagName: 'hr'
    //endregion
  });
  _exports.default = _default;
});