define("ember-material-components-web/components/mdc-toolbar/row", ["exports", "@ember/component", "@ember/object", "ember-material-components-web/templates/components/mdc-toolbar/row"], function (_exports, _component, _object, _row) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Ember Hooks
    layout: _row.default,
    classNames: Object.freeze(['mdc-toolbar__row']),
    attributeBindings: Object.freeze(['style']),
    didInsertElement() {
      this._super(...arguments);
      (0, _object.get)(this, 'register-row')(this);
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _object.get)(this, 'deregister-row')(this);
    },
    //endregion

    //region Computed Properties
    style: (0, _object.computed)('isFirstRow', 'first-row-style', function () {
      if ((0, _object.get)(this, 'isFirstRow')) {
        return (0, _object.get)(this, 'first-row-style');
      }
      return null;
    }),
    isFirstRow: (0, _object.computed)('first-row', function () {
      return (0, _object.get)(this, 'first-row') === this;
    })
    //endregion
  });
  _exports.default = _default;
});