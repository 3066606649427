define("ember-cli-ss-enums/utils/enum-data/sales-people", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 3,
    name: 'Ben Beaver'
  }, {
    id: 4,
    name: 'Nick Certa'
  }, {
    id: 5,
    name: 'Stephanie Carey'
  }, {
    id: 6,
    name: 'Maria Behr'
  }, {
    id: 7,
    name: 'Greg Dunn'
  }, {
    id: 15,
    name: 'Carl Savage'
  }, {
    id: 18,
    name: 'Ruth Presslaff'
  }, {
    id: 19,
    name: 'Ryan Kulage'
  }, {
    id: 20,
    name: 'Tom McLaughlin'
  }];
  _exports.default = _default;
});