define("ember-material-components-web/templates/components/mdc-fab", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="mdc-fab__icon">
    {{yield}}
  </span>
  
  */
  {
    "id": "AjrPy+VW",
    "block": "[[[10,1],[14,0,\"mdc-fab__icon\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-fab.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});