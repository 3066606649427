define("ember-cli-ss-enums/utils/enum-data/form-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Registration'
  }, {
    id: 2,
    name: 'EntrySubmission'
  }, {
    id: 3,
    name: 'Feedback'
  }, {
    id: 4,
    name: 'Survey'
  }, {
    id: 5,
    name: 'Login'
  }, {
    id: 6,
    name: 'SocialGates'
  }, {
    id: 7,
    name: 'AdditionalRegistrationFieldsForm'
  }, {
    id: 8,
    name: 'AdditionalEntryFieldsForm'
  }, {
    id: 9,
    name: 'ExtraChances'
  }, {
    id: 10,
    name: 'EmailOptinPreferences'
  }];
  _exports.default = _default;
});