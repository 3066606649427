define("@ss/helpers/helpers/starts-with", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.startsWithHelper = startsWithHelper;
  function startsWithHelper(_ref) {
    let [string, prefix] = _ref;
    return typeof string === 'string' && string.startsWith(prefix);
  }
  var _default = (0, _helper.helper)(startsWithHelper);
  _exports.default = _default;
});