define("ember-cli-ss-enums/utils/enum-data/promotion-type-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'contest',
    publicName: 'Contests'
  }, {
    id: 2,
    name: 'interactiveContent',
    publicName: 'Interactive Content'
  }, {
    id: 3,
    name: 'event',
    publicName: 'Events'
  }, {
    id: 4,
    name: 'ecommerce',
    publicName: 'Ecommerce'
  }, {
    id: 5,
    name: 'audience',
    publicName: 'Audiences'
  }, {
    id: 6,
    name: 'email',
    publicName: 'Email'
  }];
  _exports.default = _default;
});