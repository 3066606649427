define("ember-cli-ss-enums/utils/enum-data/message-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'PasswordResetEmail'
  }, {
    id: 2,
    name: 'RegistrationConfirmation'
  }, {
    id: 3,
    name: 'Feedback'
  }, {
    id: 4,
    name: 'ThankYouEmail'
  }, {
    id: 5,
    name: 'InvitationEmail'
  }];
  _exports.default = _default;
});