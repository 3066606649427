define("ember-material-components-web/components/mdc-textfield", ["exports", "@ember/object/computed", "@ember/string", "@ember/array", "@ember/component", "@ember/object", "@ember/utils", "ember-material-components-web/templates/components/mdc-textfield", "ember-material-components-web/mixins/mdc-component", "ember-material-components-web/utils/get-element-property", "@material/textfield", "@material/ripple"], function (_exports, _computed, _string, _array, _component, _object, _utils, _mdcTextfield, _mdcComponent, _getElementProperty, _textfield, _ripple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */

  const MATCHES = _ripple.util.getMatchesProperty(HTMLElement.prototype);
  const {
    cssClasses
  } = _textfield.MDCTextfieldFoundation;
  var _default = _component.default.extend(_mdcComponent.MDCComponent, {
    //region Attributes
    /**
     * This property is considered read-only by the component, and will not be
     * updated by user action. Please see `onchange` to handle user actions.
     * @type {String}
     */
    value: '',
    /**
     * @type {Boolean}
     */
    disabled: false,
    /**
     * @type {Boolean}
     */
    readonly: false,
    /**
     * Only used if `multiline` is false.
     * @type {String}
     */
    type: 'text',
    /**
     * Render as a box instead of a bare textfield
     * @type {Boolean}
     */
    box: false,
    /**
     * This will be called when the user indicates they want to change the value
     * of the input. If you want to simulate two-way binding, you can use the
     * input like this:
     *
     * {{mdc-textfield value=myValue onchange=(action (mut myValue))
     *
     * @type {Function}
     * @param {Boolean} checked
     */
    onchange: x => x,
    /**
     * @type {Function}
     * @param {jQuery.Event}
     */
    onfocus: x => x,
    /**
     * @type {Function}
     * @param {jQuery.Event}
     */
    onblur: x => x,
    /**
     * @type {Function}
     * @param {jQuery.Event}
     */
    oninput: x => x,
    /**
     * @type {Function}
     * @param {jQuery.Event}
     */
    onkeydown: x => x,
    /**
     * @type {Boolean}
     */
    valid: true,
    /**
     * @type {Boolean}
     */
    'bad-input': false,
    /**
     * @type {?String}
     */
    helptext: null,
    /**
     * @type {?String}
     */
    placeholder: null,
    /**
     * @type {Boolean}
     */
    'helptext-persistent': false,
    /**
     * @type {Boolean}
     */
    'helptext-validation-msg': false,
    /**
     * @type {Boolean}
     */
    fullwidth: false,
    /**
     * @type {Boolean}
     */
    multiline: false,
    /**
     * Only if `multiline` is `true`.
     * @type {?Number}
     */
    rows: null,
    /**
     * Only if `multiline` is `true`.
     * @type {?Number}
     */
    cols: null,
    //endregion

    //region Ember Hooks
    layout: _mdcTextfield.default,
    init() {
      ['labelClasses', 'helpTextAttrs', 'inputFocusHandlers', 'inputBlurHandlers', 'inputInputHandlers', 'inputKeydownHandlers'].forEach(prop => (0, _object.set)(this, prop, (0, _array.A)([])));
      this._super(...arguments);
    },
    //endregion

    //region Properties
    /**
     * @type {String[]}
     */
    labelClasses: null,
    /**
     * An array of name/value pairs that should be HTML attributes of the help text element.
     * @type {String[][]}
     */
    helpTextAttrs: null,
    /**
     * @type {EventListener[]}
     */
    inputFocusHandlers: null,
    /**
     * @type {EventListener[]}
     */
    inputBlurHandlers: null,
    /**
     * @type {EventListener[]}
     */
    inputInputHandlers: null,
    /**
     * @type {EventListener[]}
     */
    inputKeydownHandlers: null,
    /**
     * @type {Object}
     */
    CLASS_NAMES: cssClasses,
    rippleOptions() {
      const fallbackHasMatches = () => ({
        [MATCHES]: () => false
      });
      return {
        isSurfaceActive: () => (0, _getElementProperty.default)(this, 'querySelector', fallbackHasMatches)('input, textarea')[MATCHES](':active')
      };
    },
    //endregion

    //region Computed Properties
    isFocused: (0, _object.computed)('CLASS_NAMES.FOCUSED', 'mdcClassNames', function () {
      const mdcClassNames = (0, _object.get)(this, 'mdcClassNames').split(' ');
      const focusedClassName = (0, _object.get)(this, 'CLASS_NAMES.FOCUSED');
      return mdcClassNames.includes(focusedClassName);
    }),
    /**
     * @type {String}
     */
    ripple: (0, _computed.bool)('box'),
    labelClassnames: (0, _object.computed)('value', 'labelClasses.[]', function () {
      const classnames = (0, _array.A)([]);
      if ((0, _object.get)(this, 'value')) {
        classnames.addObject(cssClasses.LABEL_FLOAT_ABOVE);
      }
      return classnames.concat((0, _object.get)(this, 'labelClasses')).join(' ');
    }),
    helptextClassnames: (0, _object.computed)('helptext-persistent', 'helptext-validation-msg', function () {
      const classnames = (0, _array.A)([]);
      if ((0, _object.get)(this, 'helptext-persistent')) {
        classnames.addObject(cssClasses.HELPTEXT_PERSISTENT);
      }
      if ((0, _object.get)(this, 'helptext-validation-msg')) {
        classnames.addObject(cssClasses.HELPTEXT_VALIDATION_MSG);
      }
      return classnames.join(' ');
    }),
    //endregion

    //region Methods
    existingHelpTextAttr(name) {
      return (0, _object.get)(this, 'helpTextAttrs').find(attr => attr[0] === name);
    },
    createFoundation() {
      const component = this;
      return new _textfield.MDCTextfieldFoundation({
        addClass(className) {
          return (0, _mdcComponent.addClass)(className, component);
        },
        removeClass(className) {
          return (0, _mdcComponent.removeClass)(className, component);
        },
        addClassToLabel(className) {
          (0, _object.get)(component, 'labelClasses').addObject(className);
        },
        removeClassFromLabel(className) {
          (0, _object.get)(component, 'labelClasses').removeObject(className);
        },
        helptextHasClass(className) {
          return (0, _object.get)(component, 'helptextClassnames').split(' ').includes(className);
        },
        setHelptextAttr(name, value) {
          const existing = component.existingHelpTextAttr(name);
          const attrs = (0, _object.get)(component, 'helpTextAttrs');
          if (existing) {
            // Here we do a removeObject instead of simply changing the value,
            // because Ember does not support computed property dependent keys
            // watching changes to arrays within arrays.
            attrs.removeObject(existing);
          }
          attrs.addObject([name, value]);
        },
        removeHelptextAttr(name) {
          const existing = component.existingHelpTextAttr(name);
          if (existing) {
            (0, _object.get)(component, 'helpTextAttrs').removeObject(existing);
          }
        },
        registerInputFocusHandler(handler) {
          (0, _object.get)(component, 'inputFocusHandlers').addObject(handler);
        },
        deregisterInputFocusHandler(handler) {
          (0, _object.get)(component, 'inputFocusHandlers').removeObject(handler);
        },
        registerInputBlurHandler(handler) {
          (0, _object.get)(component, 'inputBlurHandlers').addObject(handler);
        },
        deregisterInputBlurHandler(handler) {
          (0, _object.get)(component, 'inputBlurHandlers').removeObject(handler);
        },
        registerInputInputHandler(handler) {
          (0, _object.get)(component, 'inputInputHandlers').addObject(handler);
        },
        deregisterInputInputHandler(handler) {
          (0, _object.get)(component, 'inputInputHandlers').removeObject(handler);
        },
        registerInputKeydownHandler(handler) {
          (0, _object.get)(component, 'inputKeydownHandlers').addObject(handler);
        },
        deregisterInputKeydownHandler(handler) {
          (0, _object.get)(component, 'inputKeydownHandlers').removeObject(handler);
        },
        getNativeInput() {
          return (0, _getElementProperty.default)(component, 'querySelector', () => null)('input, textarea');
        }
      });
    },
    //endregion
    //region Actions
    actions: {
      handle(type, ev) {
        const eventHandler = (0, _object.get)(this, `on${type}`.toLowerCase());
        if ((0, _utils.isPresent)(eventHandler)) {
          eventHandler(ev);
        }
        (0, _object.get)(this, (0, _string.camelize)(`input ${type} handlers`)).forEach(handler => handler(ev));
      },
      handleInput(ev) {
        this.send('handle', 'input', ev);
        (0, _object.get)(this, 'onchange')(ev.target.value);
      }
    }
    //endregion
  });
  _exports.default = _default;
});