define("ember-material-components-web/components/mdc-tab-bar", ["exports", "@ember/object/computed", "@ember/runloop", "@ember/array", "@ember/component", "@ember/object", "ember-material-components-web/templates/components/mdc-tab-bar", "@material/tabs", "ember-material-components-web/mixins/mdc-component", "ember-material-components-web/utils/get-element-property", "ember-material-components-web/utils/get-component-property", "ember-material-components-web/utils/style-computed"], function (_exports, _computed, _runloop, _array, _component, _object, _mdcTabBar, _tabs, _mdcComponent, _getElementProperty, _getComponentProperty, _styleComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  /* eslint-disable ember/no-observers */

  const {
    strings
  } = _tabs.MDCTabBarFoundation;
  var _default = _component.default.extend(_mdcComponent.MDCComponent, {
    //region Attributes
    /**
     * @type {Boolean}
     */
    links: true,
    /**
     * If present, must be one of `only` or `with-text`
     * @type {?String}
     */
    icons: null,
    /**
     * Pass as true for white text on a darker background
     * @type {Boolean}
     */
    dark: false,
    /**
     * @type {String}
     */
    'additional-indicator-classes': '',
    /**
     * @type {Function}
     * @param {Object} evtData
     */
    onchange: x => x,
    /**
     * @type {Function}
     */
    'register-tab-bar': x => x,
    /**
     * @type {Function}
     */
    'deregister-tab-bar': x => x,
    //endregion

    //region Ember Hooks
    layout: _mdcTabBar.default,
    classNames: Object.freeze(['mdc-tab-bar']),
    classNameBindings: Object.freeze(['isIconsOnly:mdc-tab-bar--icon-tab-bar', 'isIconsWithText:mdc-tab-bar--icons-with-text', 'mdcClassNames', 'dark:mdc-theme--dark']),
    attributeBindings: Object.freeze(['style']),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'tabs', (0, _array.A)([]));
      (0, _object.set)(this, 'mdcIndicatorStyles', {});
    },
    didInsertElement() {
      this._super(...arguments);
      (0, _object.get)(this, 'register-tab-bar')(this);
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _object.get)(this, 'deregister-tab-bar')();
    },
    //endregion

    //region Properties
    /**
     * @type {Ember.Component[]}
     */
    tabs: null,
    /**
     * Key value pairs for CSS styles
     * @type {Object}
     */
    mdcIndicatorStyles: null,
    //endregion

    //region Computed Properties
    indicatorStyle: (0, _styleComputed.default)('mdcIndicatorStyles'),
    isIconsOnly: (0, _computed.equal)('icons', 'only'),
    isIconsWithText: (0, _computed.equal)('icons', 'with-text'),
    //endregion

    //region Observers
    tabsChanged: (0, _object.observer)('tabs.@each.foundation', function () {
      // When a tab is first rendered, its computed measurements are zero. It relies on the tab bar to tell it to find
      // its correct measurements. When the tabs swap out however, they don't know to go find their measurements. So
      // we must trigger the tab bar to inform its new tabs of their measurements.
      const {
        tabs,
        foundation
      } = (0, _object.getProperties)(this, 'tabs', 'foundation');
      tabs.forEach(tab => tab.measureSelf());
      // then we need to reset the indicator styles
      if (foundation) {
        foundation.layout();
      }
    }),
    //endregion

    //region Method
    createFoundation() {
      return new _tabs.MDCTabBarFoundation({
        addClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').addObject(className)),
        removeClass: className => (0, _runloop.next)(this, function () {
          (0, _object.get)(this, 'mdcClasses').removeObject(className);
        }),
        //use non-arrow function for `next` since we are passing in the context
        bindOnMDCTabSelectedEvent: () => null,
        // no-op because this is bound with Ember actions
        unbindOnMDCTabSelectedEvent: () => null,
        // no-op because this is bound with Ember actions
        registerResizeHandler: handler => window.addEventListener('resize', handler),
        deregisterResizeHandler: handler => window.removeEventListener('resize', handler),
        getOffsetWidth: () => (0, _getElementProperty.default)(this, 'offsetWidth', 0),
        setStyleForIndicator: (propertyName, value) => (0, _runloop.run)(() => this.setStyleFor('mdcIndicatorStyles', propertyName, value)),
        getOffsetWidthForIndicator: () => (0, _getElementProperty.default)(this, 'querySelector', () => ({
          offsetWidth: 0
        }))(strings.INDICATOR_SELECTOR).offsetWidth,
        notifyChange: evtData => (0, _object.get)(this, 'onchange')(evtData),
        // TODO
        getNumberOfTabs: () => (0, _object.get)(this, 'tabs.length'),
        isTabActiveAtIndex: index => this.isTabActiveAtIndex(index),
        setTabActiveAtIndex: (index, isActive) => (0, _runloop.run)(() => this.setTabActiveAtIndex(index, isActive)),
        isDefaultPreventedOnClickForTabAtIndex: index => (0, _object.get)(this.tabAt(index), 'preventDefaultOnClick'),
        setPreventDefaultOnClickForTabAtIndex: (index, preventDefaultOnClick) => (0, _runloop.run)(() => (0, _object.set)(this.tabAt(index), 'preventDefaultOnClick', preventDefaultOnClick)),
        measureTabAtIndex: index => this.tabAt(index).measureSelf(),
        getComputedWidthForTabAtIndex: index => (0, _getComponentProperty.default)(this.tabAt(index), 'computedWidth', 0),
        getComputedLeftForTabAtIndex: index => (0, _getComponentProperty.default)(this.tabAt(index), 'computedLeft', 0)
      });
    },
    tabAt(index) {
      return (0, _object.get)(this, 'tabs').objectAt(index);
    },
    setTabActiveAtIndex(index, isActive) {
      if ((0, _object.get)(this, 'links')) {
        if (this.tabAt(index) && isActive) {
          this.tabAt(index)._invoke({
            stopPropagation() {},
            preventDefault() {}
          }); // TODO: Probably shouldn't be calling private APIs or stubbing events
          if ((0, _object.get)(this, 'scroll-active-tab-into-view')) {
            (0, _object.get)(this, 'scroll-active-tab-into-view')(index);
          }
        }
      } else {
        (0, _object.get)(this.tabAt(index), 'become-active')(isActive);
      }
    },
    isTabActiveAtIndex(index) {
      return !!(0, _object.get)(this.tabAt(index), 'active');
    },
    //endregion

    //region Actions
    actions: {
      tabSelected(_ref, shouldNotifyChange) {
        let {
          tab
        } = _ref;
        const {
          tabs,
          foundation
        } = (0, _object.getProperties)(this, 'tabs', 'foundation');
        const index = tabs.indexOf(tab);
        (0, _runloop.run)(() => foundation && foundation.switchToTabAtIndex(index, shouldNotifyChange));
      },
      registerTab(tab) {
        (0, _object.get)(this, 'tabs').addObject(tab);
      },
      deregisterTab(tab) {
        (0, _object.get)(this, 'tabs').removeObject(tab);
      },
      switchToTab(tab) {
        (0, _runloop.next)(() => (0, _object.get)(this, 'tabs.length') ? this.foundation?.switchToTabAtIndex((0, _object.get)(this, 'tabs').indexOf(tab), true) : null);
      },
      scrollTabIntoView(tab) {
        if ((0, _object.get)(this, 'scroll-active-tab-into-view')) {
          (0, _runloop.next)(() => (0, _object.get)(this, 'tabs.length') ? (0, _object.get)(this, 'scroll-active-tab-into-view')((0, _object.get)(this, 'tabs').indexOf(tab)) : null);
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});