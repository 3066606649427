define("ember-cli-ss-enums/utils/enum-data/ugc-voting-entry-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: "An unlimited number of times",
    nameWithCategories: "An unlimited number of times",
    entryIntervalTypeId: 2,
    entriesAllowedNumber: 999
  }, {
    id: 2,
    name: "One time only",
    nameWithCategories: "Once per Category",
    entryIntervalTypeId: 1,
    entriesAllowedNumber: 1
  }, {
    id: 3,
    name: "Once per Hour",
    nameWithCategories: "Once per Hour per Category",
    entryIntervalTypeId: 2,
    entriesAllowedNumber: 1
  }, {
    id: 4,
    name: "Once per Day",
    nameWithCategories: "Once per Day per Category",
    entryIntervalTypeId: 3,
    entriesAllowedNumber: 1
  }, {
    id: 5,
    name: "Once per Week",
    nameWithCategories: "Once per Week per Category",
    entryIntervalTypeId: 4,
    entriesAllowedNumber: 1
  }];
  _exports.default = _default;
});