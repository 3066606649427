define("ember-material-components-web/components/mdc-card/media-item", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-card/media-item"], function (_exports, _computed, _component, _object, _globalEventHandlers, _mediaItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MDC_1x_PIXELS = 80;
  var _default = _component.default.extend({
    //region Attributes
    /**
     * Must be one of `1`, `1.5`, `2`, or `3`.
     * @type {Number}
     */
    size: 1,
    /**
     * @type {String}
     */
    src: null,
    /**
     * @type {String}
     */
    alt: null,
    /**
     * @type {?Number}
     */
    width: null,
    //endregion

    //region Ember Hooks
    layout: _mediaItem.default,
    tagName: 'img',
    attributeBindings: Object.freeze(['src', 'alt', 'width', 'height', ..._globalEventHandlers.default]),
    classNames: Object.freeze(['mdc-card__media-item']),
    classNameBindings: Object.freeze(['isOne:mdc-card__media-item--1x', 'isOneDotFive:mdc-card__media-item--1dot5x', 'isTwo:mdc-card__media-item--2x', 'isThree:mdc-card__media-item--3x']),
    //endregion

    //region Computed Properties
    isOne: (0, _computed.equal)('size', 1),
    isOneDotFive: (0, _computed.equal)('size', 1.5),
    isTwo: (0, _computed.equal)('size', 2),
    isThree: (0, _computed.equal)('size', 3),
    height: (0, _object.computed)('size', function () {
      return MDC_1x_PIXELS * (0, _object.get)(this, 'size');
    })
    //endregion
  });
  _exports.default = _default;
});