define("ember-material-components-web/components/mdc-textfield/textarea", ["exports", "@ember/component", "@ember/object", "ember-material-components-web/templates/components/mdc-textfield/textarea"], function (_exports, _component, _object, _textarea) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Attributes
    /**
     * @type {Function}
     * @param {jQuery.Event}
     */
    onfocus: x => x,
    /**
     * @type {Function}
     * @param {jQuery.Event}
     */
    onblur: x => x,
    /**
     * @type {Function}
     * @param {jQuery.Event}
     */
    oninput: x => x,
    /**
     * @type {Function}
     * @param {jQuery.Event}
     */
    onkeydown: x => x,
    //endregion

    //region Ember Hooks
    layout: _textarea.default,
    tagName: 'textarea',
    attributeBindings: Object.freeze(['required', 'disabled', 'rows', 'cols', 'name', 'selectionEnd', 'selectionStart', 'wrap', 'lang', 'dir', 'value']),
    classNames: Object.freeze(['mdc-textfield__input']),
    focusIn(ev) {
      (0, _object.get)(this, 'onfocus')(ev);
    },
    focusOut(ev) {
      (0, _object.get)(this, 'onblur')(ev);
    },
    input(ev) {
      (0, _object.get)(this, 'oninput')(ev);
    },
    keyDown(ev) {
      (0, _object.get)(this, 'onkeydown')(ev);
    }
    //endregion
  });
  _exports.default = _default;
});