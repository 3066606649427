define("ember-cli-ss-enums/utils/enum-data/message-campaign-presets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'SingleEmail'
  }, {
    id: 2,
    name: 'Newsletter'
  }, {
    id: 3,
    name: 'Birthday'
  }, {
    id: 4,
    name: 'DripCampaign'
  }, {
    id: 5,
    name: 'Welcome'
  }, {
    id: 6,
    name: 'Invite'
  }, {
    id: 7,
    name: 'ThankYou'
  }, {
    id: 10,
    name: 'WeddingAnniversary'
  }, {
    id: 12,
    name: 'Reminder'
  }, {
    id: 13,
    name: 'Receipt'
  }, {
    id: 14,
    name: 'DoubleOptinConfirmation'
  }];
  _exports.default = _default;
});