define("ember-material-components-web/components/mdc-card/primary/title", ["exports", "@ember/component", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-card/primary/title"], function (_exports, _component, _globalEventHandlers, _title) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Attributes
    /**
     * @type {Boolean}
     */
    large: false,
    //endregion

    //region Ember Hooks
    layout: _title.default,
    classNames: Object.freeze(['mdc-card__title']),
    classNameBindings: Object.freeze(['large:mdc-card__title--large']),
    attributeBindings: Object.freeze([..._globalEventHandlers.default])
    //endregion
  });
  _exports.default = _default;
});