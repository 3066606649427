define("ember-cli-ss-enums/utils/enum-data/template-element-type-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    name: 'Infographics',
    icon: 'ssIcon-bar-chart',
    sortOrder: 2
  }, {
    name: 'Sales Message',
    icon: 'ssIcon-comment',
    sortOrder: 3
  }, {
    name: 'Images and Color',
    icon: 'ssIcon-picture',
    sortOrder: 1
  }, {
    name: 'Templates',
    icon: 'ssIcon-th',
    sortOrder: 3
  }];
  _exports.default = _default;
});