define("ember-cli-ss-enums/utils/enum-data/country", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 14,
    name: 'Australia',
    twoLetterCode: 'AU',
    threeLetterCode: 'AUS'
  }, {
    id: 17,
    name: 'Bahamas',
    twoLetterCode: 'BS',
    threeLetterCode: 'BHS'
  }, {
    id: 40,
    name: 'Canada',
    twoLetterCode: 'CA',
    threeLetterCode: 'CAN'
  }, {
    id: 106,
    name: 'Ireland',
    twoLetterCode: 'IE',
    threeLetterCode: 'IRL'
  }, {
    id: 143,
    name: 'Mexico',
    twoLetterCode: 'MX',
    threeLetterCode: 'MEX'
  }, {
    id: 159,
    name: 'New Zealand',
    twoLetterCode: 'NZ',
    threeLetterCode: 'NZL'
  }, {
    id: 234,
    name: 'United Kingdom',
    twoLetterCode: 'GB',
    threeLetterCode: 'GBR'
  }, {
    id: 236,
    name: 'United States of America',
    twoLetterCode: 'US',
    threeLetterCode: 'USA'
  }];
  _exports.default = _default;
});