define("ember-material-components-web/components/mdc-toolbar", ["exports", "@ember/object/computed", "@ember/array", "@ember/component", "@ember/object", "@ember/runloop", "ember-material-components-web/templates/components/mdc-toolbar", "@material/toolbar", "ember-material-components-web/mixins/mdc-component", "ember-material-components-web/utils/get-element-property", "ember-material-components-web/utils/style-computed"], function (_exports, _computed, _array, _component, _object, _runloop, _mdcToolbar, _toolbar, _mdcComponent, _getElementProperty, _styleComputed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */

  const {
    cssClasses,
    strings
  } = _toolbar.MDCToolbarFoundation;
  var _default = _component.default.extend(_mdcComponent.MDCComponent, {
    //region Attributes
    /**
     * @type {Boolean}
     */
    fixed: false,
    /**
     * @type {Boolean}
     */
    waterfall: false,
    /**
     * @type {Boolean}
     */
    flexible: false,
    /**
     * @type {Boolean}
     */
    'fixed-lastrow-only': false,
    /**
     * @type {Function<Object>}
     */
    onchange: x => x,
    //endregion

    //region Ember Hooks
    layout: _mdcToolbar.default,
    classNames: Object.freeze(['mdc-toolbar']),
    classNameBindings: Object.freeze([`fixed:${cssClasses.FIXED}`, 'waterfall:mdc-toolbar--waterfall', `flexible:${cssClasses.TOOLBAR_ROW_FLEXIBLE}`, `fixed-lastrow-only:${cssClasses.FIXED_LASTROW}`]),
    init() {
      this._super(...arguments);
      (0, _object.set)(this, 'mdcTitleStyles', {});
      (0, _object.set)(this, 'mdcFirstRowStyles', {});
      (0, _object.set)(this, 'rows', (0, _array.A)([]));
    },
    attributeBindings: Object.freeze(['style']),
    //endregion

    //region Properties
    /**
     * @type {Ember.Component[]}
     */
    rows: null,
    /**
     * Key value pairs for CSS styles
     * @type {Object}
     */
    mdcTitleStyles: null,
    /**
     * Key value pairs for CSS styles
     * @type {Object}
     */
    mdcFirstRowStyles: null,
    //endregion

    //region Computed Properties
    titleStyle: (0, _styleComputed.default)('mdcTitleStyles'),
    firstRowStyle: (0, _styleComputed.default)('mdcFirstRowStyles'),
    firstRow: (0, _computed.readOnly)('rows.firstObject'),
    //endregion

    //region Methods
    createFoundation() {
      return new _toolbar.MDCToolbarFoundation({
        hasClass: className => (0, _object.get)(this, 'element').classList.contains(className),
        addClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').addObject(className)),
        removeClass: className => (0, _runloop.run)(() => (0, _object.get)(this, 'mdcClasses').removeObject(className)),
        registerScrollHandler: handler => window.addEventListener('scroll', handler, _toolbar.util.applyPassive()),
        deregisterScrollHandler: handler => window.removeEventListener('scroll', handler, _toolbar.util.applyPassive()),
        registerResizeHandler: handler => window.addEventListener('resize', handler),
        deregisterResizeHandler: handler => window.removeEventListener('resize', handler),
        getViewportWidth: () => window.innerWidth,
        getViewportScrollY: () => window.pageYOffset,
        getOffsetHeight: () => (0, _getElementProperty.default)(this, 'offsetHeight', 0),
        getFirstRowElementOffsetHeight: () => (0, _getElementProperty.default)(this, 'querySelector', () => ({
          offsetHeight: 0
        }))(strings.FIRST_ROW_SELECTOR).offsetHeight,
        notifyChange: evtData => (0, _runloop.run)(() => (0, _object.get)(this, 'onchange')(evtData)),
        setStyle: (property, value) => (0, _runloop.run)(() => this.setStyleFor('mdcStyles', property, value)),
        setStyleForTitleElement: (property, value) => (0, _runloop.run)(() => this.setStyleFor('mdcTitleStyles', property, value)),
        setStyleForFlexibleRowElement: (property, value) => (0, _runloop.run)(() => this.setStyleFor('mdcFirstRowStyles', property, value)),
        setStyleForFixedAdjustElement: () => null // no-op
      });
    },

    //endregion

    //region Actions
    actions: {
      /**
       * @param {Ember.Component} row
       */
      registerRow(row) {
        (0, _object.get)(this, 'rows').addObject(row);
      },
      /**
       * @param {Ember.Component} row
       */
      deregisterRow(row) {
        (0, _object.get)(this, 'rows').removeObject(row);
      }
    }
    //endregion
  });
  _exports.default = _default;
});