define("ember-material-components-web/components/mdc-toolbar/section", ["exports", "@ember/object/computed", "@ember/component", "ember-material-components-web/templates/components/mdc-toolbar/section"], function (_exports, _computed, _component, _section) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Attributes
    /**
     * Must be one of `start`,`center`, or `end`
     * @type {String}
     */
    align: 'center',
    /**
     * @type {Boolean}
     */
    'shrink-to-fit': false,
    //endregion

    //region Ember Hooks
    layout: _section.default,
    classNames: Object.freeze(['mdc-toolbar__section']),
    classNameBindings: Object.freeze(['isAlignStart:mdc-toolbar__section--align-start', 'isAlignEnd:mdc-toolbar__section--align-end', 'shrink-to-fit:mdc-toolbar__section--shrink-to-fit']),
    //endregion

    //region Computed Properties
    isAlignStart: (0, _computed.equal)('align', 'start'),
    isAlignEnd: (0, _computed.equal)('align', 'end')
    //endregion
  });
  _exports.default = _default;
});