define("ember-material-components-web/components/mdc-list/item/text", ["exports", "@ember/component", "ember-material-components-web/utils/global-event-handlers", "ember-material-components-web/templates/components/mdc-list/item/text"], function (_exports, _component, _globalEventHandlers, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    //region Ember Hooks
    layout: _text.default,
    tagName: 'span',
    classNames: Object.freeze(['mdc-list-item__text']),
    attributeBindings: Object.freeze([..._globalEventHandlers.default])
    //endregion
  });
  _exports.default = _default;
});