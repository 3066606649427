define("ember-cli-ss-enums/utils/enum-data/referred-entity-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: This duplicates data from entity-type.js
  var _default = [{
    id: 80,
    name: 'Account'
  }, {
    id: 8,
    name: 'Entry'
  }];
  _exports.default = _default;
});