define("ember-material-components-web/templates/components/mdc-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    primary=(component "mdc-card/primary")
    media=(component "mdc-card/media")
    actions=(component "mdc-card/actions")
    supporting-text=(component "mdc-card/supporting-text")
    media-item=(component "mdc-card/media-item")
  )}}
  
  */
  {
    "id": "RVnT6PPO",
    "block": "[[[18,1,[[28,[37,1],null,[[\"primary\",\"media\",\"actions\",\"supporting-text\",\"media-item\"],[[50,\"mdc-card/primary\",0,null,null],[50,\"mdc-card/media\",0,null,null],[50,\"mdc-card/actions\",0,null,null],[50,\"mdc-card/supporting-text\",0,null,null],[50,\"mdc-card/media-item\",0,null,null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-card.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});