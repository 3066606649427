define("ember-material-components-web/templates/components/mdc-switch", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    <input type="checkbox" onclick={{action "clicked"}} id={{this.input-id}} onchange={{action "inputChanged"}} class="mdc-switch__native-control" disabled={{this.disabled}} checked={{this.checked}} />
  <div class="mdc-switch__background">
    <div class="mdc-switch__knob"></div>
  </div>
  
  */
  {
    "id": "CchJ2+bI",
    "block": "[[[10,\"input\"],[15,\"onclick\",[28,[37,0],[[30,0],\"clicked\"],null]],[15,1,[30,0,[\"input-id\"]]],[15,\"onchange\",[28,[37,0],[[30,0],\"inputChanged\"],null]],[14,0,\"mdc-switch__native-control\"],[15,\"disabled\",[30,0,[\"disabled\"]]],[15,\"checked\",[30,0,[\"checked\"]]],[14,4,\"checkbox\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"mdc-switch__background\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"mdc-switch__knob\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-switch.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});