define("ember-cli-ss-enums/utils/enum-data/matchup-classification-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Business',
    iconClass: 'ssIcon-building',
    iconKey: 'business'
  }, {
    id: 2,
    name: 'Place',
    iconClass: 'ssIcon-map-marker',
    iconKey: 'room'
  }, {
    id: 3,
    name: 'Person',
    iconClass: 'ssIcon-user',
    iconKey: 'person'
  }, {
    id: 4,
    name: 'Event',
    iconClass: 'ssIcon-calendar-empty',
    iconKey: 'calendar_today'
  }, {
    id: 5,
    name: 'Product',
    iconClass: 'ssIcon-shopping-cart',
    iconKey: 'shopping_cart'
  }, {
    id: 6,
    name: 'Creative Work',
    iconClass: 'ssIcon-music',
    iconKey: 'music_note'
  }, {
    id: 7,
    name: 'Other',
    iconClass: 'ssIcon-list-ul',
    iconKey: 'list'
  }];
  _exports.default = _default;
});