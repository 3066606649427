define("ember-cli-ss-enums/utils/enum-data/audience-filter-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'InterestTag'
  }, {
    id: 2,
    name: 'Age'
  }, {
    id: 3,
    name: 'Gender'
  }, {
    id: 4,
    name: 'Optin'
  }, {
    id: 5,
    name: 'Field'
  }, {
    id: 6,
    name: 'LocationDistancePostalCode'
  }, {
    id: 7,
    name: 'PromotionParticipation'
  }, {
    id: 8,
    name: 'PurchaseBehavior'
  }, {
    id: 9,
    name: 'ImportFile'
  }, {
    id: 10,
    name: 'MessageInteraction'
  }, {
    id: 11,
    name: 'AudienceMember'
  }, {
    id: 13,
    name: 'StateProvince'
  }, {
    id: 14,
    name: 'PostalCode'
  }];
  _exports.default = _default;
});