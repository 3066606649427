define("ember-cli-ss-enums/utils/enum-data/operator-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: 'NotSpecified'
  }, {
    id: 1,
    name: 'And'
  }, {
    id: 2,
    name: 'Or'
  }];
  _exports.default = _default;
});