define("ember-cli-ss-enums/utils/enum-data/article-content-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: 'Off'
  }, {
    id: 1,
    name: 'Article Preview'
  }, {
    id: 2,
    name: 'Full Article'
  }];
  _exports.default = _default;
});