define("ember-cli-ss-enums/enums-decorator", ["exports", "ember-cli-ss-enums/utils/enum-data", "ember-cli-ss-enums/utils/enum-data/ss-product-type", "@ember/object", "@ember/string", "@ember/utils"], function (_exports, _enumData, _ssProductType, _object, _string, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getModelProperty = getModelProperty;
  _exports.getPhrase = getPhrase;
  _exports.makeBooleanProperties = makeBooleanProperties;
  _exports.productType = productType;
  function productType(target) {
    _ssProductType.default.forEach(_ref => {
      let {
        name
      } = _ref;
      (0, _object.defineProperty)(target.prototype, (0, _string.camelize)(`has ${name}`), (0, _object.computed)('organizationSsProducts.@each.ssProductName', {
        get() {
          if (this[`_${name}`] !== undefined) {
            return this[`_${name}`];
          }
          return !(0, _utils.isEmpty)(this.organizationSsProducts.filterBy('ssProductName', name));
        },
        set(_key, value) {
          this[`_${name}`] = value;
          return value;
        }
      }));
    });
  }
  function makeBooleanProperties(enumName) {
    let extract = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'name';
    let modelProperty = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    return target => {
      _enumData.default[enumName].forEach(function (enumType) {
        const phrase = getPhrase(enumType, extract);
        const computedModelProperty = getModelProperty(enumName, modelProperty);
        (0, _object.defineProperty)(target.prototype, phrase, (0, _object.computed)(computedModelProperty, function () {
          // We do not care if we compare strings and numbers
          return (0, _object.get)(this, computedModelProperty) == enumType.id;
        }));
      });
    };
  }
  function getModelProperty(enumName, modelProperty) {
    if (!modelProperty) {
      modelProperty = (0, _string.camelize)(enumName.toLowerCase()) + 'Id';
    }
    return modelProperty;
  }
  function getPhrase(enumType, extract) {
    return `is${enumType[extract].replace(/\s/g, '')}`;
  }
});