define("ember-cli-ss-enums/utils/enum-data/message-sending-status-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: 'Incomplete'
  }, {
    id: 1,
    name: 'Scheduled'
  }, {
    id: 2,
    name: 'SentAndScheduled'
  }, {
    id: 3,
    name: 'TestSend'
  }, {
    id: 4,
    name: 'Sending'
  }, {
    id: 5,
    name: 'Sent'
  }];
  _exports.default = _default;
});