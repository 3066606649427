define("ember-material-components-web/templates/components/mdc-list-group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    divider=(component "mdc-list-group/divider")
    subheader=(component "mdc-list-group/subheader")
  )}}
  
  */
  {
    "id": "eaivR7Qc",
    "block": "[[[18,1,[[28,[37,1],null,[[\"divider\",\"subheader\"],[[50,\"mdc-list-group/divider\",0,null,null],[50,\"mdc-list-group/subheader\",0,null,null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-material-components-web/templates/components/mdc-list-group.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});