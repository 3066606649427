define("ember-cli-ss-enums/utils/enum-data/payment-processor-status-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 0,
    name: 'Initiated'
  }, {
    id: 10,
    name: 'Disconnected'
  }, {
    id: 20,
    name: 'ConnectedButInvalid'
  }, {
    id: 100,
    name: 'ConnectedAndValid'
  }];
  _exports.default = _default;
});